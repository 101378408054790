import { useFeatureParametersQuery } from "./queries.generated"

export function useLockersystemFeatures (props: {
    systemId: string
}){
	const {data, error, loading, refetch} = useFeatureParametersQuery({
		variables: {
			lockersystemId: props.systemId,
		},
	})

	return data
}