import React from "react"
import { View } from "react-native"
import { useTranslation } from "react-i18next"
import MyText from "../../components/MyText"
import { JoinLockersystem } from "../Settings/JoinLockersystemScreen"

interface Props {
}

export function GettingStartedInfo(props: Props) {
	const { t } = useTranslation()

	return (
		<View style={{ margin: 10 }}>
			<MyText type="h1" text={t("Welcome!")} />
			<MyText text={t("Not in any system info")} style={{ marginBottom: 20 }} />

			<JoinLockersystem style={{ marginTop: 20, }} />
		</View>
	)
}
