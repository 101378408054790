import { useApolloClient } from "@apollo/client"
import { RouteProp } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import { ErrorMessage } from "pusatec-react-native"
import React from "react"
import Loading from "../../components/Loading"
import LockerAction from "../../components/LockerAction"
import { goBack, MainStackParamList } from "../../MainNavigation"
import { useInterruptDeliveryCodeQuery } from "./queries.generated"

interface Props {
    navigation: StackNavigationProp<MainStackParamList, "InterruptDelivery">
    route: RouteProp<MainStackParamList, "InterruptDelivery">
}

export function InterruptDeliveryScreen(props: Props) {
	const { navigation, route } = props
	const { params: { deliveryId } } = route
	const apolloClient = useApolloClient()

	const { data, error, loading } = useInterruptDeliveryCodeQuery({
		variables: { deliveryId },
		pollInterval: 2000,
	})
	const delivery = data?.delivery

	if (loading) return <Loading />
	if (error || !delivery) return <ErrorMessage extra={error} />

	return (
		<LockerAction
			code={delivery.interruptCode!}
			useCodeBefore={delivery.useInterruptCodeBefore!}
			codeUsedAt={delivery.interruptCodeUsedAt!}
			onReportIssue={() => {
				navigation.push("ReportIssue", {
					situation: "DeliveryPickup",
					deliveryId: delivery.id,
				})
			}}
			onComplete={() => {
				goBack(navigation)
				apolloClient.refetchQueries({
					include: ["AdminView"]
				})
			}}
			onCancel={() => goBack(navigation)}
		/>
	)
}