import React, { useState } from "react"
import { DateTime } from "../utils/types"
import Panel from "./layout/Panel"
import MyText from "./MyText"
import { View } from "react-native"
import { useTranslation } from "react-i18next"
import ConfirmLockerAction from "./data-transmission/ConfirmLockerAction"
import DebugConfirming from "./data-transmission/DebugConfirming"
import { Text, TouchButton } from "pusatec-react-native"
import MainScrollPanel from "./layout/MainScrollPanel"
import Base from "./layout/Base"
import Icon from "react-native-vector-icons/AntDesign"
import { BackIcon } from "./BackIcon"

interface Props {
	code: string
	useCodeBefore: DateTime | null
	codeUsedAt: DateTime | null
	onReportIssue?(): void
	onComplete(): void
	onCancel(): void
}

export default function (props: Props) {
	const {
		code,
		useCodeBefore,
		codeUsedAt,
		onReportIssue,
		onComplete,
		onCancel,
	} = props

	const { t } = useTranslation()

	const [expired, setExpired] = useState(false)

	return (
		<>
			<MainScrollPanel>
				<Base style={{ paddingBottom: 20 }}>
					{codeUsedAt ? (
						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Text text={t("Code has been used")} style={{ textAlign: "center" }} />
							<MyText text={t("Remember to close the locker door")} />
						</View>
					) : expired ? (
						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Text text={t("The code has been expired")} style={{ textAlign: "center" }} />
						</View>
					) : (
						<ConfirmLockerAction
							code={code}
							useCodeBefore={useCodeBefore}
							onCodeExpired={() => setExpired(true)}
						/>
					)}
				</Base>
			</MainScrollPanel>
			<Panel style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", alignContent: "flex-end" }}>

				{!codeUsedAt && (
					<TouchButton
						type="secondary"
						text={t("Cancel")}
						icon={BackIcon}
						onPress={onCancel}
					/>
				)}
				<View style={{ flexGrow: 1 }}>
					{!codeUsedAt && !expired && (
						<DebugConfirming code={code} onConfirm={onComplete} />
					)}
					{onReportIssue && (
						<TouchButton
							type="secondary"
							text={t("Report issue")}
							onPress={onReportIssue}
						/>
					)}
					{codeUsedAt && (
						<TouchButton
							type="primary"
							text={t("Ready")}
							onPress={onComplete}
						/>
					)}
				</View>
			</Panel>
		</>
	)
}
