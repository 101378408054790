import React, { useEffect, useState } from "react"
import { StackNavigationProp } from "@react-navigation/stack"
import { BackButton, MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/native"
import {
	useViewDeliveryQuery,
	usePickupDeliveryMutation,
	useUpdateDeliveryMutation,
} from "./queries.generated"
import { useDurationToDeadline } from "../../utils/Duration"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import Base from "../../components/layout/Base"
import Panel from "../../components/layout/Panel"
import { Image, Platform, View } from "react-native"
import { useTranslation } from "react-i18next"
import { Button, ErrorMessage, Form, Notification, Text, TouchButton, useTheme } from "pusatec-react-native"
import { IoLocationOutline, IoTimerOutline } from "react-icons/io5"
import Loading from "../../components/Loading"
import { useActiveViewerQuery } from "../ActiveItems/queries.generated"
import { LocalDatetime } from "../../components/local-datetime"
import { parseRichHtml } from "../../utils/richtext"
import { FormatRichHtml } from "../../components/FormatRichHtml"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "ViewDelivery">
	route: RouteProp<MainStackParamList, "ViewDelivery">
}

export default function ViewDeliveryScreen(props: Props) {
	const { navigation, route } = props
	const { deliveryId } = route.params
	const { t } = useTranslation()
	const { colors } = useTheme()

	const { data: viewerData } = useActiveViewerQuery()

	const [pickupBefore, setPickupBefore] = useState("")

	const { data, loading, error, refetch } = useViewDeliveryQuery({
		variables: { deliveryId },
	})
	const delivery = data?.delivery

	useEffect(() => {
		setPickupBefore(delivery?.pickupBefore)
	}, [delivery])

	const [pickupDelivery, pickupDeliveryM] = usePickupDeliveryMutation({
		onCompleted: (_data) => {
			const deliveryId = _data?.result?.delivery?.id

			// Mutation fails then if we throw error here
			// ui will not receive correct error
			if (!deliveryId) {
				return
			}

			navigation.push("PickupDelivery", {
				deliveryId: deliveryId,
			})
		},
	})

	const [updateDelivery] = useUpdateDeliveryMutation()

	const pickupTimeLeft = useDurationToDeadline(
		data?.delivery?.pickupBefore
			? new Date(data?.delivery?.pickupBefore)
			: null
	)

	const yourDelivery = delivery?.userWhoDelivered?.id === viewerData?.viewer?.user?.id
	return (
		<>
			<MainScrollPanel onRefresh={refetch} refreshing={loading}>
				<Base>

					<View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
						<Image
							style={{
								width: 30,
								height: 30,
								marginRight: 15,
							}}
							resizeMode="contain"
							source={require("../../../assets/icons/679902-ecommerce/svg/086-trolley.svg")}
						/>
						<Text
							style={{ color: "rgba(0,0,0,0.7)" }}
							text={t("Incoming delivery")}
						/>
					</View>

					{error && <ErrorMessage extra={error} />}

					{loading
						? <Loading />
						: <>
							<Text
								type="strong"
								text={t("Sent by {{user}}", { user: delivery?.userWhoDelivered?.name })}
							/>
							<Text text = {t("user email {{email}} ", { email: delivery?.userWhoDelivered?.gmail })} />
							{delivery?.description
								? <FormatRichHtml html={delivery?.description} max={200} />
								: null}

							<View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
								<IoLocationOutline style={{ marginRight: 10 }} size={18} color="#333" />

								<Text
									text={t("Pickup at {{system}}, {{group}}", {
										system: (delivery?.locker?.lockersystem?.name ?? ""),
										group: (delivery?.locker?.lockerGroup?.name ?? ""),
									})}
								/>
							</View>

							<View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
								<IoTimerOutline style={{ marginRight: 10 }} size={18} color="#333" />
								<View style={{ flex: 1 }}>
									<Text text={t("Sent on {{time}}", {
										time: new Date(delivery?.createdAt ?? 0).toLocaleString(),
									})} />

									{yourDelivery ?
										<>
											<Text text={t("Pickup before")} />
											<LocalDatetime
												style={{
													width: 200,
													height: "25px"
												}}
												datetime={pickupBefore}
												min={new Date()}
												onChange={setPickupBefore}
											/>
											{pickupBefore &&
										<Button text={t("Empty")}
											onPress={() => {
												if (!delivery) {
													return
												}

												setPickupBefore("")

												updateDelivery({
													variables: {
														input: {
															deliveryId: delivery?.id,
															pickupBefore: null
														}
													}
												})
											}} />}

											{(pickupBefore && pickupBefore !== delivery?.pickupBefore) &&
										<Button text={t("Update")} onPress={() => {
											if (!delivery) {
												return
											}

											updateDelivery({
												variables: {
													input: {
														deliveryId: delivery?.id,
														pickupBefore: pickupBefore
													}
												}
											})
										}} />}
										</>
									
										: delivery?.pickupBefore && <Text text={t("Pick up before {{deadline}}", {
											deadline: new Date(delivery?.pickupBefore ?? 0).toLocaleString()
										})} />}

									{!delivery?.pickupBefore
										? null
										: pickupTimeLeft.overtime ? (
											<Text
												bold
												text={t("Pickup time has run out")}
												style={{ color: colors.error }}
											/>
										) : (
											<Text
												text={t("{{time}} left to pickup", {
													time: pickupTimeLeft.duration?.format(t),
												})}
											/>
										)}
								</View>
							</View>
							{pickupTimeLeft.overtime
								? <Notification
									type="warning"
									title={t("Out of time")}
									text={t("pick_time_run_out_warning")}
									containerStyle={{ marginTop: 20 }}
								/>
								: null}
						</>}
				</Base>
			</MainScrollPanel>
			<Panel>
				{pickupDeliveryM.error && <ErrorMessage extra={pickupDeliveryM.error} />}

				<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
					{Platform.OS !== "android" && (
						<BackButton />
					)}
					<TouchButton
						type="primary"
						text={t("Pickup now")}
						loading={pickupDeliveryM.loading}
						disabled={!yourDelivery && pickupTimeLeft.overtime}
						onPress={async () => {
							await pickupDelivery({
								variables: { deliveryId: delivery!.id },
							})
						}}
					/>
				</View>
			</Panel>
		</>
	)
}
