import { useApolloClient } from "@apollo/client"
import { RouteProp } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import { ErrorMessage } from "pusatec-react-native"
import React from "react"
import { StyleSheet } from "react-native"
import Loading from "../../components/Loading"
import LockerAction from "../../components/LockerAction"
import { goBack, MainStackParamList } from "../../MainNavigation"
import { useRemoveItemCodeQuery } from "./queries.generated"

interface Props {
    navigation: StackNavigationProp<MainStackParamList, "RemoveItemFromLocker">
    route: RouteProp<MainStackParamList, "RemoveItemFromLocker">
}

export function RemoveItemFromLockerScreen(props: Props) {
	const { navigation, route } = props
	const { params: { itemId } } = route
	const apolloClient = useApolloClient()

	const { data, error, loading } = useRemoveItemCodeQuery({
		variables: { itemId },
		pollInterval: 2000,
	})
	const item = data?.item

	if (loading) return <Loading />
	if (error || !item) return <ErrorMessage extra={error} />

	return (
		<LockerAction
			code={item.removeCode!}
			useCodeBefore={item.useRemoveCodeBefore!}
			codeUsedAt={item.removeCodeUsedAt!}
			onComplete={() => {
				goBack(navigation)
				apolloClient.refetchQueries({
					include: ["AdminView"]
				})
			}}
			onCancel={() => goBack(navigation)}
		/>
	)
}

const styles = StyleSheet.create({
})
