import React from "react"
import { StackNavigationProp } from "@react-navigation/stack"
import { goBack, goToRoot, MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/native"
import useBackHandler from "../../utils/useBackHandler"
import Loading from "../../components/Loading"
import LockerAction from "../../components/LockerAction"
import { useTranslation } from "react-i18next"
import { useRentingQuery, useCancelRentingMutation } from "./queries.generated"
import { ErrorMessage, Text, TouchButton } from "pusatec-react-native"
import { RentingState } from "../../doorhub-types"
import { View } from "react-native"
import Panel from "../../components/layout/Panel"
import { BackIcon } from "../../components/BackIcon"
import Base from "../../components/layout/Base"
import MainScrollPanel from "../../components/layout/MainScrollPanel"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "RentItem">
	route: RouteProp<MainStackParamList, "RentItem">
}

export default function RentItemScreen(props: Props) {
	const { route, navigation } = props
	const { rentingId } = route.params
	const { t } = useTranslation()

	const { data, loading, error } = useRentingQuery({
		variables: { rentingId, },
		pollInterval: 2000,
	})
	const renting = data?.renting

	// call cancelRenting on back press, if code is not used
	const [cancelRenting] = useCancelRentingMutation()

	useBackHandler(() => {
		if (data?.renting?.codeUsedAt) {
			// tslint:disable-next-line: no-floating-promises
			cancelRenting({
				variables: {
					rentingId,
				},
			})
		}
	}, [data])

	if (error) return <ErrorMessage extra={error} />
	if (loading || !renting) return <Loading />

	if (renting.state === RentingState.NeedsPermit) {
		return (
			<>
				<MainScrollPanel>
					<Base>
						<View>
							<Text
								text={t("You need to wait for admin's approval to use this item. Message has been sent to admin.")}
							/>
						</View>
					</Base>
				</MainScrollPanel>
				<Panel style={{
					flexDirection: "row",
					justifyContent: "space-between",
					flexWrap: "wrap",
					alignContent: "flex-end"
				}}>
					<TouchButton
						type="secondary"
						text={t("Okay")}
						onPress={() => {
							props.navigation.push("ActiveItems")
						}}
					/>
				</Panel>
			</>
		)
	}

	return (
		<LockerAction
			code={renting.code}
			useCodeBefore={renting.useCodeBefore ?? null}
			codeUsedAt={renting.codeUsedAt ?? null}
			onReportIssue={() => {
				navigation.push("ReportIssue", {
					situation: "RentingStart",
					rentingId: renting.id,
				})
			}}
			onComplete={() => goToRoot(navigation)}
			onCancel={() => goBack(navigation)}
		/>
	)
}
