import React from "react"
import { View } from "react-native"
import MyText from "./MyText"
import { BuildConfiguration } from "../services/platform-info/build-configuration"

interface Props { }

export default function (props: Props) {
	const { } = props

	return (
		<View style={{ alignItems: "center", margin: 20 }}>
			<MyText type="small" text={`${BuildConfiguration.APPLICATION_ID} - ${BuildConfiguration.ENV}`} />
			<MyText
				type="small"
				text={`v${BuildConfiguration.VERSION_NAME}-${BuildConfiguration.VERSION_CODE}${BuildConfiguration.VERSION_HASH ? ("-" + BuildConfiguration.VERSION_HASH) : ""}`}
			/>
			<MyText type="small" text={BuildConfiguration.DOORHUB_HOST} />
		</View>
	)
}
