import React, { Fragment } from "react"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import { Image, TouchableHighlight, View } from "react-native"
import { AdminViewQuery } from "./queries.generated"
import { Text } from "pusatec-react-native"
import { StackNavigationProp } from "@react-navigation/stack"
import { MainStackParamList } from "../../MainNavigation"
import { useNavigation } from "@react-navigation/core"
import Loading from "../../components/Loading"
import { AdminToolbar } from "./admin_toolbar"
import { HeaderBar } from "../../components/Headerbar"

type LockerGroup = NonNullable<AdminViewQuery["lockersystem"]>["lockerGroups"][0]
type LockerUnit = LockerGroup["lockerUnits"][number]

export type ShowDoormapProps = {
	loading: boolean
	lockerGroups: LockerGroup[]
}

type LockerRectangleProps = {
	id: string
	hasRenting?: boolean
	hasDelivery?: boolean
	returnItemBefore?: string
	hasItem?: boolean
	terminal?: boolean
	name: string
	width: number
	height: number
}

const LockerRectangle = (props: LockerRectangleProps) => {
	const navigation = useNavigation<StackNavigationProp<MainStackParamList, "AdminNavigation">>()

	const rentingLate = props.returnItemBefore ?
		new Date(props.returnItemBefore) < new Date() :
		false

	let backgroundColor = "#E3EBEC"

	if (rentingLate) {
		backgroundColor = "#ff4d4d"
	} else if (props.hasRenting || props.hasDelivery) {
		backgroundColor = "#FFED8F"
	} else if (props.hasItem) {
		backgroundColor = "#9ddc9d"
	}

	let imgwidth = 16
	let imgheight = 16

	return (
		<TouchableHighlight disabled={props.terminal} onPress={() => {
			navigation.push("ViewLocker", {
				lockerId: props.id
			})
		}} style={{
			padding: "2px",
			minWidth: props.width,
			height: props.height,
		}}>
			<View style={{
				backgroundColor: backgroundColor,
				borderWidth: 1,
				borderColor: "black",
				borderRadius: 3,
				padding: 3,
				paddingLeft: 10,
				paddingRight: 10,
				height: "100%"
			}}>
				<View style={{
					width: 0,
					height: 0,
					alignContent: "center",
				}}>
					{props.hasRenting &&
						<Image
							style={{
								width: imgwidth,
								height: imgheight,
							}}
							resizeMode="contain"
							source={require("../../../assets/icons/679902-ecommerce/svg/080-box-1.svg")}
						/>}
					{props.hasDelivery &&
						<Image
							style={{
								width: imgwidth,
								height: imgheight,
							}}
							resizeMode="contain"
							source={require("../../../assets/icons/679902-ecommerce/svg/086-trolley.svg")}
						/>}
					{props.terminal &&
						<Image
							style={{
								width: 70,
								height: 35,

							}}
							resizeMode="contain"
							source={require("../../../assets/Terminal.png")}
						/>}
				</View>
				<View style={{
					justifyContent: "center",
					alignItems: "center",
					flex: 1,

				}}>
					<Text
						style={{
							alignContent: "center",
							fontSize: 20,

							fontWeight: "bold",
							textAlignVertical: "center",
						}}
						text={!props.terminal ? props.name : ""}

					/>
				</View >
			</View>
		</TouchableHighlight>
	)
}

const unitWidth = 200
const maxHeight = 500
const defaultHeight = 45
const defaultWidth = 70

const ShowLockerUnit = (props: {
	lockerUnit: LockerUnit
}) => {
	props.lockerUnit.lockerNodes

	let totalHeight = 0

	for (const node of props.lockerUnit.lockerNodes) {
		if (node.leftUpLocker) {
			totalHeight += node.leftUpLocker.height || 0
		}

		if (node.leftDownLocker) {
			totalHeight += node.leftDownLocker.height || 0
		}
	}

	return (
		<View style={{ height: maxHeight, marginRight: "5px" }}>
			{props.lockerUnit.lockerNodes.map(lockerNode => {
				let nodeHeight = 0

				if (lockerNode.leftUpLocker) {
					nodeHeight += lockerNode.leftUpLocker.height || 0
				}

				if (lockerNode.leftDownLocker) {
					nodeHeight += lockerNode.leftDownLocker.height || 0
				}

				const leftUpLocker = lockerNode.leftUpLocker
				const rightUpLocker = lockerNode.rightUpLocker
				const leftDownLocker = lockerNode.leftDownLocker
				const rightDownLocker = lockerNode.rightDownLocker

				return (
					<View key={lockerNode.id}>
						<View style={{ display: "flex", flexDirection: "row" }}>
							{leftUpLocker && <LockerRectangle
								id={leftUpLocker?.id}
								name={leftUpLocker?.name ? leftUpLocker.name.toString() : ""}
								height={leftUpLocker.height ? leftUpLocker.height / totalHeight * maxHeight : defaultHeight}
								width={unitWidth / 2}
								terminal={leftUpLocker.terminal ?? false}
								hasDelivery={leftUpLocker.delivery != null}
								hasRenting={leftUpLocker.item?.activeRenting != null}
								hasItem={leftUpLocker.item != null}
								returnItemBefore={leftUpLocker.item?.activeRenting?.returnItemBefore}
							/>}
							{rightUpLocker && <LockerRectangle
								id={rightUpLocker?.id}
								name={rightUpLocker?.name ? rightUpLocker.name.toString() : ""}
								height={rightUpLocker.height ? rightUpLocker.height / totalHeight * maxHeight : defaultHeight}
								width={unitWidth / 2}
								terminal={rightUpLocker.terminal ?? false}
								hasDelivery={rightUpLocker.delivery != null}
								hasRenting={rightUpLocker.item?.activeRenting != null}
								hasItem={rightUpLocker.item != null}
								returnItemBefore={rightUpLocker.item?.activeRenting?.returnItemBefore}
							/>}
						</View>
						<View style={{ display: "flex", flexDirection: "row" }}>
							{leftDownLocker && <LockerRectangle
								id={leftDownLocker?.id}
								name={leftDownLocker?.name ? leftDownLocker.name.toString() : ""}
								height={leftDownLocker?.height ? leftDownLocker.height / totalHeight * maxHeight : defaultHeight}
								width={unitWidth / 2}
								terminal={leftDownLocker.terminal ?? false}
								hasDelivery={leftDownLocker.delivery != null}
								hasRenting={leftDownLocker.item?.activeRenting != null}
								hasItem={leftDownLocker.item != null}
								returnItemBefore={leftDownLocker.item?.activeRenting?.returnItemBefore}
							/>}
							{rightDownLocker && <LockerRectangle
								id={rightDownLocker?.id}
								name={rightDownLocker?.name ? rightDownLocker.name.toString() : ""}
								height={rightDownLocker?.height ? rightDownLocker.height / totalHeight * maxHeight : defaultHeight}
								width={unitWidth / 2}
								terminal={rightDownLocker.terminal ?? false}
								hasDelivery={rightDownLocker.delivery != null}
								hasRenting={rightDownLocker.item?.activeRenting != null}
								hasItem={rightDownLocker.item != null}
								returnItemBefore={rightDownLocker.item?.activeRenting?.returnItemBefore}
							/>}
						</View>
					</View>
				)
			})}
		</View>
	)
}

export const ShowDoormap = (props: ShowDoormapProps) => {
	if (props.loading) {
		return <Loading />
	}
	return (
		<View style={{ flex: 1, }}>
			<MainScrollPanel>
				{props.lockerGroups.map(p => (
					<Fragment key={p.id}>
						<HeaderBar text={p.name ?? ""} />
						<View style={{
							flexDirection: "row",
							overflow: "auto"
						}}>
							{p.lockerUnits.map(lockerUnit => {
								return (
									<ShowLockerUnit
										key={lockerUnit.id}
										lockerUnit={lockerUnit} />
								)
							})}
						</View>
						<View style={{
							flexDirection: "row",
							flexWrap: "wrap",
							justifyContent: "center",
						}}>
							{p.lockerUnits.length === 0 && p.lockers.map(locker => (
								<LockerRectangle
									key={locker.id}
									height={defaultHeight}
									width={defaultWidth}
									id={locker.id}
									name={locker.name ? locker.name.toString() : ""}
									terminal={locker.terminal ?? false}
									hasDelivery={locker.delivery !== null}
									hasRenting={locker.item?.activeRenting != null}
									hasItem={locker.item != null}
									returnItemBefore={locker.item?.activeRenting?.returnItemBefore}
								/>
							))}
						</View>
					</Fragment>
				))}
			</MainScrollPanel>
			<AdminToolbar />
		</View>
	)
}