import React, { useEffect, useRef, useState } from "react"

const greenThreshold = 220
const triggerThreshold = 500

export const TinderCard = (props: {
    children?: any
    onSwipeLeft?: () => void
    onSwipeRight?: () => void
    leftColor?: string
    rightColor?: string
    disabled?: boolean
    seleted?: boolean
}) =>
{
	const disabled = props.disabled
	const startX = useRef(0)
	const disablequery = useRef(false)
	const dragging = useRef(false)
	const deltaX = useRef(0)
	const baseRef = useRef<any>(null)
	const alreadySwiped = useRef(false)
	const alredyVirated = useRef(false)
	const [background_color, set_background_color] = useState("white")

	const phycis = useRef({
		vel: 0,
		acc: 0,
	})

	useEffect(() => {
		let stopped = false
		const handler = () => {
			if (!dragging.current) {
				if (deltaX.current > triggerThreshold) {
					if (props.onSwipeRight && disablequery.current === false) {
						props.onSwipeRight()
						disablequery.current = true
					}
				} else if (deltaX.current < -triggerThreshold) {
					if (props.onSwipeLeft && disablequery.current === false) {
						props.onSwipeLeft()
						disablequery.current = true
					}
				} else if (deltaX.current > greenThreshold) {
					deltaX.current += 30
				} else if (deltaX.current < -greenThreshold) {
					deltaX.current -= 30
				} else {
					const dist = Math.abs(deltaX.current)
					if (dist > 0) {
						const k = 0.05
						const damping = 0.9
						if (deltaX.current > 0) {
							phycis.current.acc = -k * dist
						} else {
							phycis.current.acc = k * dist
						}
						phycis.current.vel = (phycis.current.vel + phycis.current.acc) * damping
						deltaX.current += phycis.current.vel

						if (Math.abs(dist) < 1 && Math.abs(phycis.current.vel) < 1) {
							phycis.current.vel = 0
							phycis.current.acc = 0
							deltaX.current = 0
						}
					}
				}
			}

			if (!alreadySwiped.current) {
				if (deltaX.current > 200 && props.onSwipeRight && !dragging.current) {
					alreadySwiped.current = true
				}

				if (deltaX.current < -200 && props.onSwipeLeft && !dragging.current ) {
					alreadySwiped.current = true
				}

				if (deltaX.current > greenThreshold) {
					set_background_color("green")
					if (alredyVirated.current === false) {
						navigator.vibrate(10)
						alredyVirated.current = true
					}
				}

				if (deltaX.current < 197 && deltaX.current > -197 )
				{
					alredyVirated.current = false
				}

				if (deltaX.current < -greenThreshold) {
					set_background_color("red")
					if (alredyVirated.current === false) {
						navigator.vibrate(10)
						alredyVirated.current = true
					}
				}
				if (deltaX.current < 25 && deltaX.current > -25) {
					set_background_color("white")
				}

				if (deltaX.current > 25 && deltaX.current < 200) {
					set_background_color("#CCFFCC")
				}

				if (deltaX.current < -25 && deltaX.current > -200) {
					set_background_color("#FFCCCC")
				}
			}

			if(deltaX.current && !disabled) {
				baseRef.current.style.translate = deltaX.current + "px"
			}

			if (stopped) {
				return
			}

			window.requestAnimationFrame(handler)
		}
		window.requestAnimationFrame(handler)
		return () => {
			stopped = true
		}
	}, [])

	if (disabled) {
		return (
			<div style={{ position: "relative",}}>
				{props.children}
			</div>
		)
	}

	return (
		<div style={{ position: "relative", backgroundColor: background_color, marginLeft: -5 }}
			onTouchStart={() => dragging.current = true}
			ref={baseRef}
			onTouchStartCapture={(event) => startX.current = event.touches[0].clientX}
			onTouchMove={(event) => {
				deltaX.current = event.touches[0].clientX - startX.current
				if(deltaX.current) {
					baseRef.current.style.translate = deltaX.current + "px"
				}
			}}
			onTouchEndCapture={() => {
				dragging.current = false
			}}
		>

			{props.children}
		</div>
	)
}