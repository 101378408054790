import * as Types from '../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchItemGroupsQueryVariables = Types.Exact<{
  opt?: Types.InputMaybe<Types.SearchItemGroupsOptions>;
}>;


export type SearchItemGroupsQuery = { __typename?: 'Query', itemGroups: { __typename?: 'ItemGroupsConnection', itemGroups: Array<{ __typename?: 'ItemGroup', id: string, name: string, lockersystemId: string }> } };

export type CreateItemGroupMutationVariables = Types.Exact<{
  input: Types.CreateItemGroup;
}>;


export type CreateItemGroupMutation = { __typename?: 'Mutation', createItemGroup: { __typename?: 'CreateItemGroupResponse', itemGroup?: { __typename?: 'ItemGroup', id: string, name: string, lockersystemId: string } | null } };

export type DeleteItemGroupMutationVariables = Types.Exact<{
  input: Types.DeleteItemGroup;
}>;


export type DeleteItemGroupMutation = { __typename?: 'Mutation', deleteItemGroup: { __typename?: 'DeleteItemGroupResponse', itemGroupId?: string | null } };

export type FeatureParametersQueryVariables = Types.Exact<{
  lockersystemId: Types.Scalars['ID'];
}>;


export type FeatureParametersQuery = { __typename?: 'Query', lockersystem?: { __typename?: 'Lockersystem', id: string, rentingNonRegistered?: boolean | null, deliveryNonRegistered?: boolean | null, showWhoBorrows?: boolean | null } | null };


export const SearchItemGroupsDocument = gql`
    query searchItemGroups($opt: SearchItemGroupsOptions) {
  itemGroups(opt: $opt) {
    itemGroups {
      id
      name
      lockersystemId
    }
  }
}
    `;

/**
 * __useSearchItemGroupsQuery__
 *
 * To run a query within a React component, call `useSearchItemGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchItemGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchItemGroupsQuery({
 *   variables: {
 *      opt: // value for 'opt'
 *   },
 * });
 */
export function useSearchItemGroupsQuery(baseOptions?: Apollo.QueryHookOptions<SearchItemGroupsQuery, SearchItemGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchItemGroupsQuery, SearchItemGroupsQueryVariables>(SearchItemGroupsDocument, options);
      }
export function useSearchItemGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchItemGroupsQuery, SearchItemGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchItemGroupsQuery, SearchItemGroupsQueryVariables>(SearchItemGroupsDocument, options);
        }
export type SearchItemGroupsQueryHookResult = ReturnType<typeof useSearchItemGroupsQuery>;
export type SearchItemGroupsLazyQueryHookResult = ReturnType<typeof useSearchItemGroupsLazyQuery>;
export type SearchItemGroupsQueryResult = Apollo.QueryResult<SearchItemGroupsQuery, SearchItemGroupsQueryVariables>;
export const CreateItemGroupDocument = gql`
    mutation createItemGroup($input: CreateItemGroup!) {
  createItemGroup(input: $input) {
    itemGroup {
      id
      name
      lockersystemId
    }
  }
}
    `;
export type CreateItemGroupMutationFn = Apollo.MutationFunction<CreateItemGroupMutation, CreateItemGroupMutationVariables>;

/**
 * __useCreateItemGroupMutation__
 *
 * To run a mutation, you first call `useCreateItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemGroupMutation, { data, loading, error }] = useCreateItemGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemGroupMutation, CreateItemGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemGroupMutation, CreateItemGroupMutationVariables>(CreateItemGroupDocument, options);
      }
export type CreateItemGroupMutationHookResult = ReturnType<typeof useCreateItemGroupMutation>;
export type CreateItemGroupMutationResult = Apollo.MutationResult<CreateItemGroupMutation>;
export type CreateItemGroupMutationOptions = Apollo.BaseMutationOptions<CreateItemGroupMutation, CreateItemGroupMutationVariables>;
export const DeleteItemGroupDocument = gql`
    mutation deleteItemGroup($input: DeleteItemGroup!) {
  deleteItemGroup(input: $input) {
    itemGroupId
  }
}
    `;
export type DeleteItemGroupMutationFn = Apollo.MutationFunction<DeleteItemGroupMutation, DeleteItemGroupMutationVariables>;

/**
 * __useDeleteItemGroupMutation__
 *
 * To run a mutation, you first call `useDeleteItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemGroupMutation, { data, loading, error }] = useDeleteItemGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteItemGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemGroupMutation, DeleteItemGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemGroupMutation, DeleteItemGroupMutationVariables>(DeleteItemGroupDocument, options);
      }
export type DeleteItemGroupMutationHookResult = ReturnType<typeof useDeleteItemGroupMutation>;
export type DeleteItemGroupMutationResult = Apollo.MutationResult<DeleteItemGroupMutation>;
export type DeleteItemGroupMutationOptions = Apollo.BaseMutationOptions<DeleteItemGroupMutation, DeleteItemGroupMutationVariables>;
export const FeatureParametersDocument = gql`
    query featureParameters($lockersystemId: ID!) {
  lockersystem(lockersystemId: $lockersystemId) {
    id
    rentingNonRegistered
    deliveryNonRegistered
    showWhoBorrows
  }
}
    `;

/**
 * __useFeatureParametersQuery__
 *
 * To run a query within a React component, call `useFeatureParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureParametersQuery({
 *   variables: {
 *      lockersystemId: // value for 'lockersystemId'
 *   },
 * });
 */
export function useFeatureParametersQuery(baseOptions: Apollo.QueryHookOptions<FeatureParametersQuery, FeatureParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureParametersQuery, FeatureParametersQueryVariables>(FeatureParametersDocument, options);
      }
export function useFeatureParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureParametersQuery, FeatureParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureParametersQuery, FeatureParametersQueryVariables>(FeatureParametersDocument, options);
        }
export type FeatureParametersQueryHookResult = ReturnType<typeof useFeatureParametersQuery>;
export type FeatureParametersLazyQueryHookResult = ReturnType<typeof useFeatureParametersLazyQuery>;
export type FeatureParametersQueryResult = Apollo.QueryResult<FeatureParametersQuery, FeatureParametersQueryVariables>;