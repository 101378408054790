import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RentingQueryVariables = Types.Exact<{
  rentingId: Types.Scalars['ID'];
}>;


export type RentingQuery = { __typename?: 'Query', renting?: { __typename?: 'Renting', id: string, code: string, useCodeBefore?: any | null, codeUsedAt?: any | null, state: Types.RentingState } | null };

export type CancelRentingMutationVariables = Types.Exact<{
  rentingId: Types.Scalars['ID'];
}>;


export type CancelRentingMutation = { __typename?: 'Mutation', result: { __typename?: 'CancelRentingResponse', reting?: { __typename?: 'Renting', id: string, cancelledAt?: any | null } | null } };


export const RentingDocument = gql`
    query Renting($rentingId: ID!) {
  renting(rentingId: $rentingId) {
    id
    code
    useCodeBefore
    codeUsedAt
    state
  }
}
    `;

/**
 * __useRentingQuery__
 *
 * To run a query within a React component, call `useRentingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentingQuery({
 *   variables: {
 *      rentingId: // value for 'rentingId'
 *   },
 * });
 */
export function useRentingQuery(baseOptions: Apollo.QueryHookOptions<RentingQuery, RentingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RentingQuery, RentingQueryVariables>(RentingDocument, options);
      }
export function useRentingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RentingQuery, RentingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RentingQuery, RentingQueryVariables>(RentingDocument, options);
        }
export type RentingQueryHookResult = ReturnType<typeof useRentingQuery>;
export type RentingLazyQueryHookResult = ReturnType<typeof useRentingLazyQuery>;
export type RentingQueryResult = Apollo.QueryResult<RentingQuery, RentingQueryVariables>;
export const CancelRentingDocument = gql`
    mutation CancelRenting($rentingId: ID!) {
  result: cancelRenting(input: {rentingId: $rentingId}) {
    reting {
      id
      cancelledAt
    }
  }
}
    `;
export type CancelRentingMutationFn = Apollo.MutationFunction<CancelRentingMutation, CancelRentingMutationVariables>;

/**
 * __useCancelRentingMutation__
 *
 * To run a mutation, you first call `useCancelRentingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRentingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRentingMutation, { data, loading, error }] = useCancelRentingMutation({
 *   variables: {
 *      rentingId: // value for 'rentingId'
 *   },
 * });
 */
export function useCancelRentingMutation(baseOptions?: Apollo.MutationHookOptions<CancelRentingMutation, CancelRentingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRentingMutation, CancelRentingMutationVariables>(CancelRentingDocument, options);
      }
export type CancelRentingMutationHookResult = ReturnType<typeof useCancelRentingMutation>;
export type CancelRentingMutationResult = Apollo.MutationResult<CancelRentingMutation>;
export type CancelRentingMutationOptions = Apollo.BaseMutationOptions<CancelRentingMutation, CancelRentingMutationVariables>;