import React, { useMemo } from "react"
import { BrowseItemsQuery, useBrowseItemsQuery } from "./queries.generated"
import Panel from "../../components/layout/Panel"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import ItemLink from "./ItemLink"
import { Platform, View } from "react-native"
import { StackNavigationProp } from "@react-navigation/stack"
import { BackButton, MainStackParamList } from "../../MainNavigation"
import { useTranslation } from "react-i18next"
import { RouteProp } from "@react-navigation/native"
import { ErrorMessage, Text, } from "pusatec-react-native"
import { theme } from "../../theme"
import { SearchBar } from "react-native-elements"
import { useDebouncedState } from "pusatec-react-utility"
import Base from "../../components/layout/Base"
import { HeaderBar } from "../../components/Headerbar"

type Item = NonNullable<BrowseItemsQuery["items"]>["items"][0]

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "BrowseItems">
	route: RouteProp<MainStackParamList, "BrowseItems">
}

export default function BrowseItemsScreen(props: Props) {
	const { route, navigation } = props
	const { systemId } = route.params
	const { t } = useTranslation()

	const [searchWord, searchWordd, setSearchWord] = useDebouncedState("", 500)

	const { data, loading, error, refetch } = useBrowseItemsQuery({
		variables: {
			opt: {
				lockersystemId: systemId,
				searchWord: searchWordd,
				onlyShowRentable: true
			}
		},
	})
	const groups = useMemo(() => {
		if (!data?.items.items) {
			return []
		}

		const groupsMap: {
			[key: string]: Item[]
		} = {}

		const add = (key: string, item: Item) => {
			let group = groupsMap[key]

			if (!group) {
				group = []
				groupsMap[key] = group
			}

			group.push(item)
		}

		for (const item of data?.items.items) {
			if (!item.available) {
				add("inuse", item)

				continue
			}

			if (!item.itemGroup?.name) {
				add("others", item)

				continue
			}

			add(item.itemGroup?.name, item)
		}

		const groups = Object.entries(groupsMap)

		groups.sort(([a], [b]) => {
			if (a === "inuse") {
				return 1
			} else if (b === "inuse") {
				return -1
			} else if (a < b) {
				return -1
			} else if (a > b) {
				return 1
			} else {
				return 0
			}
		})

		for (const [,items] of groups) {
			items.sort((a, b) => {
				if (!a.name || !b.name) return 0
				if (a.name < b.name) return -1
				if (a.name > b.name) return 1
				return 0	
			})
		}

		return groups
	}, [data?.items.items])

	return (
		<>
			<Panel style={{ paddingLeft: 20, }}>
				<Text
					style={{ color: "rgba(0,0,0,0.7)", textAlign: "center", fontSize: 30 }}
					text={t("Rentable items")}
				/>
			</Panel>
			<SearchBar
				lightTheme
				inputStyle={theme.Input.inputStyle}
				errorStyle={theme.Input.errorMessageStyle}
				labelStyle={theme.Input.labelStyle}
				containerStyle={theme.Input.containerStyle}
				inputContainerStyle={theme.Input.inputContainerStyle}
				value={searchWord}
				onChangeText={text => setSearchWord(text)}
			/>
			<MainScrollPanel refreshing={loading} onRefresh={() => refetch()}>
				{error && <ErrorMessage extra={error} />}
				{groups.map(([group, items]) => {
					return (
						<View>
							<HeaderBar text={t(group)}></HeaderBar>
							{items.map(item => {
								return (
									
									<ItemLink
										key={item.id}
										item={item}
										onPress={() => {
											navigation.push("ViewItem", {
												itemId: item.id,
											})
										}}
										available={item.available ?? false}
									/>
								)
							})}
						</View>
					)
				})}
			</MainScrollPanel>

			{Platform.OS !== "android" && (
				<Panel>
					<BackButton />
				</Panel>
			)}
		</>
	)
}

const ItemGroupRow = (props: {
	itemGroupName: string
	items: Item[]
	onItemClicked?: (item: Item) => void
}) => {
	const [opened, setOpened] = React.useState(false)

	return (
		<View>
			<Base selected={opened} onPress={() => {
				setOpened(!opened)
			}}>
				<Text text={props.itemGroupName.toUpperCase()} bold />
			</Base>
			{opened &&
			<View style={{
				marginLeft: 40,
			}}>
				{props.items.map((item) => {
					return (
						<ItemLink
							key={item.id}
							item={item}
							onPress={() => {
								if (props.onItemClicked) {
									props.onItemClicked(item)
								}
							}}
							available={item.available ?? false}
						/>
					)
				})}
			</View>}
		</View>
	)
}