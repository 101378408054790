import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewItemQueryVariables = Types.Exact<{
  itemId: Types.Scalars['ID'];
}>;


export type ViewItemQuery = { __typename?: 'Query', item?: { __typename?: 'Item', id: string, name?: string | null, imageUrl?: string | null, available?: boolean | null, rentingTime?: number | null, description?: string | null, needsPermit?: boolean | null, lockersystem?: { __typename?: 'Lockersystem', id: string } | null, activeRenting?: { __typename?: 'Renting', id: string, returnItemBefore?: any | null, user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null } | null } | null, locker?: { __typename?: 'Locker', id: string, name: number, lockersystem?: { __typename?: 'Lockersystem', id: string, name?: string | null } | null, lockerGroup?: { __typename?: 'LockerGroup', id: string, name: string } | null } | null } | null };

export type RentItemMutationVariables = Types.Exact<{
  itemId: Types.Scalars['ID'];
}>;


export type RentItemMutation = { __typename?: 'Mutation', result: { __typename?: 'RentItemResponse', item: { __typename?: 'Item', id: string, available?: boolean | null }, renting: { __typename?: 'Renting', id: string } } };


export const ViewItemDocument = gql`
    query ViewItem($itemId: ID!) {
  item(itemId: $itemId) {
    id
    name
    imageUrl
    available
    rentingTime
    description
    needsPermit
    lockersystem {
      id
    }
    activeRenting {
      id
      returnItemBefore
      user {
        id
        name
        email
      }
    }
    locker {
      id
      name
      lockersystem {
        id
        name
      }
      lockerGroup {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useViewItemQuery__
 *
 * To run a query within a React component, call `useViewItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewItemQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useViewItemQuery(baseOptions: Apollo.QueryHookOptions<ViewItemQuery, ViewItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewItemQuery, ViewItemQueryVariables>(ViewItemDocument, options);
      }
export function useViewItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewItemQuery, ViewItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewItemQuery, ViewItemQueryVariables>(ViewItemDocument, options);
        }
export type ViewItemQueryHookResult = ReturnType<typeof useViewItemQuery>;
export type ViewItemLazyQueryHookResult = ReturnType<typeof useViewItemLazyQuery>;
export type ViewItemQueryResult = Apollo.QueryResult<ViewItemQuery, ViewItemQueryVariables>;
export const RentItemDocument = gql`
    mutation RentItem($itemId: ID!) {
  result: rentItem(input: {itemId: $itemId}) {
    item {
      id
      available
    }
    renting {
      id
    }
  }
}
    `;
export type RentItemMutationFn = Apollo.MutationFunction<RentItemMutation, RentItemMutationVariables>;

/**
 * __useRentItemMutation__
 *
 * To run a mutation, you first call `useRentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rentItemMutation, { data, loading, error }] = useRentItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRentItemMutation(baseOptions?: Apollo.MutationHookOptions<RentItemMutation, RentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RentItemMutation, RentItemMutationVariables>(RentItemDocument, options);
      }
export type RentItemMutationHookResult = ReturnType<typeof useRentItemMutation>;
export type RentItemMutationResult = Apollo.MutationResult<RentItemMutation>;
export type RentItemMutationOptions = Apollo.BaseMutationOptions<RentItemMutation, RentItemMutationVariables>;