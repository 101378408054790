import React from "react"
import WizardStep from "../WizardStep"
import { WizardState } from "../use-wizard"
import { useTranslation } from "react-i18next"
import { useDeliveryCodeQuery } from "./queries.generated"
import Loading from "../../../components/Loading"
import LockerAction from "../../../components/LockerAction"
import { ErrorMessage } from "pusatec-react-native"

interface Props {
	wizardState: WizardState
	deliveryId: string
	onCreateIssue: () => void
	onCancel(): void
	onComplete(): void
}

export default function PlaceDeliveryStep(props: Props) {
	const {
		wizardState,
		deliveryId,
		onCreateIssue,
		onCancel,
		onComplete,
	} = props

	const { t } = useTranslation()

	const { data, loading, error } = useDeliveryCodeQuery({
		variables: { deliveryId },
		pollInterval: 2000,
	})
	const delivery = data?.delivery

	if (loading) return <Loading />
	if (error || !delivery) return <ErrorMessage extra={error} />

	return (
		<WizardStep
			title={t("")}
			wizardState={wizardState}
			scroll
		>
			<div style={{
				fontFamily: 'Nunito, Mada-Regular, sans-serif',
				fontSize: 25,
				textAlign: 'center'
			}}>
				{t("Place delivery into locker")}
			</div>					
			<LockerAction
				code={delivery.deliveryCode!}
				useCodeBefore={delivery.useDeliveryCodeBefore}
				codeUsedAt={delivery.deliveryCodeUsedAt}
				onReportIssue={onCreateIssue}
				onComplete={onComplete}
				onCancel={onCancel}
			/>
		</WizardStep>
	)
}
