import React, { useEffect, useState } from "react"
import { TouchableHighlight, ViewStyle, Animated, StyleProp } from "react-native"
import { colors } from "../../theme"

interface Props {
	children: any
	onPress?(): void
	padding?: number
	selected?: boolean
	disabled?: boolean
	style?: StyleProp<ViewStyle>
}

export default function Base(props: Props) {
	const { children, onPress, padding, selected, disabled, style } = props
	const [selectedAnimation] = useState(new Animated.Value(0))

	useEffect(() => {
		Animated.timing(selectedAnimation, {
			toValue: selected ? 1 : 0,
			duration: 100,
			useNativeDriver: false,
		}).start()
	}, [selected])

	let content = (
		<Animated.View
			style={[{
				margin: 3,
				padding: padding || 20,
				borderRadius: 5,
				borderLeftColor: selectedAnimation.interpolate({
					inputRange: [0, 1],
					outputRange: [colors.dark, colors.accent],
				}),
				backgroundColor: disabled ? colors.disabled : colors.light,
				borderLeftWidth: selectedAnimation.interpolate({
					inputRange: [0, 1],
					outputRange: [0, 8],
				}),
				elevation: 1,
				shadowColor: "rgba(0,0,0,0.05)",
				shadowOffset: {
					height: 1,
					width: 1,
				},
				shadowRadius: 3,
			}, style]}
		>
			{children}
		</Animated.View>
	)

	if (onPress)
		return (
			<TouchableHighlight
				onPress={onPress}
				activeOpacity={0.8}
				underlayColor="rgba(0,0,0,0)"
			>
				{content}
			</TouchableHighlight>
		)

	return content
}
