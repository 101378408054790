import React from "react"
import { View, TextStyle, Platform } from "react-native"

export const colors = {
	primary: "#294671",
	secondary: "#ffce7c",
	accent: "#294671",
	accentLight: "#7ea5de",
	dark: "#999999",
	light: "#FFF",
	disabled: "#EEE",
	error: "#a11603",
}

export const theme = {
	colors,
	Input: {
		containerStyle: {
			padding: 12,
			borderWidth: 0,
			borderBottomWidth: 0,
			borderColor: "rgba(0, 0, 0, 0)",
			backgroundColor: "rgba(0,0,0,0)",
		},

		labelStyle: {
			color: colors.accent,
			fontSize: 17,
			fontFamily: "Mada-Medium, sans-serif",
			fontWeight: "normal",
			marginBottom: 2,
		} as TextStyle,

		inputContainerStyle: {
			padding: 0,
			margin: 0,
			borderWidth: 0,
			borderColor: "rgba(0,0,0,0)",
			backgroundColor: "rgba(0,0,0,0)",
		},

		inputStyle: {
			padding: 6,
			color: "#222",
			borderWidth: 1,
			borderColor: "rgba(0,0,0,0.2)",
			borderRadius: 3,
			fontSize: 16,
			backgroundColor: "#FFF",
			fontFamily: "Inter, sans-serif"
		},

		errorMessageStyle: {
			color: colors.error,
			fontSize: 14,
			marginTop: 0,
		} as TextStyle,
	},
	SearchBar: {
		containerStyle: {
			backgroundColor: colors.light,
			padding: 0,
			margin: 0,
		},
		inputContainerStyle: {
			backgroundColor: "rgba(0,0,0,0)",
			padding: 6,
		},
		inputStyle: {
			color: "black",
			padding: 6,
		},
	},
}

export default function withTheme(Comp: any) {
	return (props: any) => {
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: "#EAEAEA",
					justifyContent: "flex-start",
					alignItems: "stretch",
					paddingTop: Platform.OS === "ios" ? 20 : 0,
				}}
			>
				<Comp {...props} />
			</View>
		)
	}
}
