import { Text } from "pusatec-react-native"
import React from "react"
import Base from "../../../components/layout/Base"
import MyText from "../../../components/MyText"
import { SelectUserQuery } from "./queries.generated"

interface Props {
	user: SelectUserQuery["users"]["users"][0]
	selected?: boolean
	onPress(): void
}

export default function UserLink(props: Props) {
	const { user, selected, onPress } = props
	return (
		<Base selected={selected} onPress={onPress}>
			<Text text={user.name || user.userName} bold />
			<Text text={user.gmail} />
		</Base>
	)
}
