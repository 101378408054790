import React from "react"
import { BrowseSystemsQuery } from "./queries.generated"
import Base from "../../components/layout/Base"
import MyText from "../../components/MyText"
import { Lockersystem } from "../../doorhub-types"
import { Text } from "pusatec-react-native"

interface Props {
	lockersystem: Pick<Lockersystem, "name" | "id">
	onPress(): void
}

export default function LockersystemLink(props: Props) {
	const { lockersystem, onPress } = props

	return (
		<Base
			key={lockersystem.id}
			onPress={() => onPress()}
		>
			<Text text={lockersystem.name?.toUpperCase()} bold />
		</Base>
	)
}
