import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GoogleSigninMutationVariables = Types.Exact<{
  idToken: Types.Scalars['String'];
  deviceModel: Types.Scalars['String'];
  os: Types.PhoneOs;
  versionName?: Types.InputMaybe<Types.Scalars['String']>;
  versionCode?: Types.InputMaybe<Types.Scalars['Int']>;
  applicationId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GoogleSigninMutation = { __typename?: 'Mutation', result: { __typename?: 'RegisterPhoneWithGoogleTokenResponse', token: string } };

export type SamlSigninMutationVariables = Types.Exact<{
  samlAuthCode: Types.Scalars['String'];
  deviceModel: Types.Scalars['String'];
  os: Types.PhoneOs;
  versionName?: Types.InputMaybe<Types.Scalars['String']>;
  versionCode?: Types.InputMaybe<Types.Scalars['Int']>;
  applicationId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SamlSigninMutation = { __typename?: 'Mutation', result: { __typename?: 'RegisterPhoneWithSamlAuthCodeResponse', token: string } };


export const GoogleSigninDocument = gql`
    mutation GoogleSignin($idToken: String!, $deviceModel: String!, $os: PhoneOs!, $versionName: String, $versionCode: Int, $applicationId: String) {
  result: registerPhoneWithGoogleIdToken(
    googleIdToken: $idToken
    model: $deviceModel
    os: $os
    versionName: $versionName
    versionCode: $versionCode
    applicationId: $applicationId
  ) {
    token
  }
}
    `;
export type GoogleSigninMutationFn = Apollo.MutationFunction<GoogleSigninMutation, GoogleSigninMutationVariables>;

/**
 * __useGoogleSigninMutation__
 *
 * To run a mutation, you first call `useGoogleSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSigninMutation, { data, loading, error }] = useGoogleSigninMutation({
 *   variables: {
 *      idToken: // value for 'idToken'
 *      deviceModel: // value for 'deviceModel'
 *      os: // value for 'os'
 *      versionName: // value for 'versionName'
 *      versionCode: // value for 'versionCode'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGoogleSigninMutation(baseOptions?: Apollo.MutationHookOptions<GoogleSigninMutation, GoogleSigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleSigninMutation, GoogleSigninMutationVariables>(GoogleSigninDocument, options);
      }
export type GoogleSigninMutationHookResult = ReturnType<typeof useGoogleSigninMutation>;
export type GoogleSigninMutationResult = Apollo.MutationResult<GoogleSigninMutation>;
export type GoogleSigninMutationOptions = Apollo.BaseMutationOptions<GoogleSigninMutation, GoogleSigninMutationVariables>;
export const SamlSigninDocument = gql`
    mutation SamlSignin($samlAuthCode: String!, $deviceModel: String!, $os: PhoneOs!, $versionName: String, $versionCode: Int, $applicationId: String) {
  result: registerPhoneWithSamlAuthCode(
    samlAuthCode: $samlAuthCode
    model: $deviceModel
    os: $os
    versionName: $versionName
    versionCode: $versionCode
    applicationId: $applicationId
  ) {
    token
  }
}
    `;
export type SamlSigninMutationFn = Apollo.MutationFunction<SamlSigninMutation, SamlSigninMutationVariables>;

/**
 * __useSamlSigninMutation__
 *
 * To run a mutation, you first call `useSamlSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSamlSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [samlSigninMutation, { data, loading, error }] = useSamlSigninMutation({
 *   variables: {
 *      samlAuthCode: // value for 'samlAuthCode'
 *      deviceModel: // value for 'deviceModel'
 *      os: // value for 'os'
 *      versionName: // value for 'versionName'
 *      versionCode: // value for 'versionCode'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useSamlSigninMutation(baseOptions?: Apollo.MutationHookOptions<SamlSigninMutation, SamlSigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SamlSigninMutation, SamlSigninMutationVariables>(SamlSigninDocument, options);
      }
export type SamlSigninMutationHookResult = ReturnType<typeof useSamlSigninMutation>;
export type SamlSigninMutationResult = Apollo.MutationResult<SamlSigninMutation>;
export type SamlSigninMutationOptions = Apollo.BaseMutationOptions<SamlSigninMutation, SamlSigninMutationVariables>;