import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewDeliveryQueryVariables = Types.Exact<{
  deliveryId: Types.Scalars['ID'];
}>;


export type ViewDeliveryQuery = { __typename?: 'Query', delivery?: { __typename?: 'Delivery', id: string, pickupBefore?: any | null, usePickupCodeBefore?: any | null, description?: string | null, imageUrl?: string | null, createdAt?: any | null, userWhoDelivered?: { __typename?: 'User', id: string, name?: string | null, gmail?: string | null } | null, locker?: { __typename?: 'Locker', name: number, lockerGroup?: { __typename?: 'LockerGroup', id: string, name: string } | null, lockersystem?: { __typename?: 'Lockersystem', id: string, name?: string | null } | null } | null } | null };

export type PickupDeliveryMutationVariables = Types.Exact<{
  deliveryId: Types.Scalars['ID'];
}>;


export type PickupDeliveryMutation = { __typename?: 'Mutation', result: { __typename?: 'PickupDeliveredItemResponse', code: string, delivery: { __typename?: 'Delivery', id: string, pickupCode?: string | null, pickupCodeUsedAt?: any | null, usePickupCodeBefore?: any | null }, locker: { __typename?: 'Locker', id: string, lockerGroupId?: string | null, lockersystemId?: string | null, delivery?: { __typename?: 'Delivery', id: string } | null } } };

export type UpdateDeliveryMutationVariables = Types.Exact<{
  input: Types.UpdateDelivery;
}>;


export type UpdateDeliveryMutation = { __typename?: 'Mutation', updateDelivery: { __typename?: 'UpdateDeliveryResponse', delivery?: { __typename?: 'Delivery', id: string, pickupBefore?: any | null } | null } };


export const ViewDeliveryDocument = gql`
    query ViewDelivery($deliveryId: ID!) {
  delivery(deliveryId: $deliveryId) {
    id
    pickupBefore
    usePickupCodeBefore
    description
    imageUrl
    createdAt
    userWhoDelivered {
      id
      name
      gmail
    }
    locker {
      name
      lockerGroup {
        id
        name
      }
      lockersystem {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useViewDeliveryQuery__
 *
 * To run a query within a React component, call `useViewDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDeliveryQuery({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useViewDeliveryQuery(baseOptions: Apollo.QueryHookOptions<ViewDeliveryQuery, ViewDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewDeliveryQuery, ViewDeliveryQueryVariables>(ViewDeliveryDocument, options);
      }
export function useViewDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewDeliveryQuery, ViewDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewDeliveryQuery, ViewDeliveryQueryVariables>(ViewDeliveryDocument, options);
        }
export type ViewDeliveryQueryHookResult = ReturnType<typeof useViewDeliveryQuery>;
export type ViewDeliveryLazyQueryHookResult = ReturnType<typeof useViewDeliveryLazyQuery>;
export type ViewDeliveryQueryResult = Apollo.QueryResult<ViewDeliveryQuery, ViewDeliveryQueryVariables>;
export const PickupDeliveryDocument = gql`
    mutation PickupDelivery($deliveryId: ID!) {
  result: pickupDeliveredItem(input: {deliveryId: $deliveryId}) {
    delivery {
      id
      pickupCode
      pickupCodeUsedAt
      usePickupCodeBefore
    }
    code
    locker {
      id
      lockerGroupId
      lockersystemId
      delivery {
        id
      }
    }
  }
}
    `;
export type PickupDeliveryMutationFn = Apollo.MutationFunction<PickupDeliveryMutation, PickupDeliveryMutationVariables>;

/**
 * __usePickupDeliveryMutation__
 *
 * To run a mutation, you first call `usePickupDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickupDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickupDeliveryMutation, { data, loading, error }] = usePickupDeliveryMutation({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function usePickupDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<PickupDeliveryMutation, PickupDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PickupDeliveryMutation, PickupDeliveryMutationVariables>(PickupDeliveryDocument, options);
      }
export type PickupDeliveryMutationHookResult = ReturnType<typeof usePickupDeliveryMutation>;
export type PickupDeliveryMutationResult = Apollo.MutationResult<PickupDeliveryMutation>;
export type PickupDeliveryMutationOptions = Apollo.BaseMutationOptions<PickupDeliveryMutation, PickupDeliveryMutationVariables>;
export const UpdateDeliveryDocument = gql`
    mutation updateDelivery($input: UpdateDelivery!) {
  updateDelivery(input: $input) {
    delivery {
      id
      pickupBefore
    }
  }
}
    `;
export type UpdateDeliveryMutationFn = Apollo.MutationFunction<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>;

/**
 * __useUpdateDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryMutation, { data, loading, error }] = useUpdateDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>(UpdateDeliveryDocument, options);
      }
export type UpdateDeliveryMutationHookResult = ReturnType<typeof useUpdateDeliveryMutation>;
export type UpdateDeliveryMutationResult = Apollo.MutationResult<UpdateDeliveryMutation>;
export type UpdateDeliveryMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>;