import { Fragment } from "react"
import { RichComponent, parseRichHtml } from "../utils/richtext"
import "./RichTextEditor.css"

const extractText = (richText: RichComponent): string => {
	let text = ""

	for (const child of richText.children ?? []) {
		if (child.type === "text") {
			text += child.text
		} else {
			text += extractText(child)
		}
	}

	return text
}

const FormatTree = (props: {
	richText: RichComponent
}) => {
	const richText = props.richText
	if (richText.type === "text") {
		return <Fragment>{richText.text}</Fragment>
	}
	if (richText.type === "h1") {
		return <h1 className="editor-heading-h1">
			{richText.children?.map((a, i) => <FormatTree key={i} richText={a}  />)}
		</h1>
	}
	if (richText.type === "h2") {
		return <h2 className="editor-heading-h2" >
			{richText.children?.map((a, i) => <FormatTree key={i}  richText={a} />)}
		</h2>
	}
	if (richText.type === "p") {
		return <p className="editor-paragraph" >
			{richText.children?.map((a, i) => <FormatTree key={i}  richText={a}   />)}
		</p>
	}
	if (richText.type === "b") {
		return <b>
			{richText.children?.map((a, i) => <FormatTree key={i}  richText={a} />)}
		</b>
	}
	if (richText.type === "u") {
		return <u>
			{richText.children?.map((a, i) => <FormatTree key={i} richText={a} />)}
		</u>
	}
	if (richText.type === "i") {
		return <i>
			{richText.children?.map((a, i) => <FormatTree key={i} richText={a} />)}
		</i>
	}
	if (richText.type === "a") {
		return <a href={extractText(richText)}>
			{richText.children?.map((a, i) => <FormatTree key={i}  richText={a} />)}
		</a>
	}

	return null
}

export const FormatRichHtml = (props: {
	html?: string | null
	max ?: number
}) => {
	if (!props.html){
		return <Fragment></Fragment>
	}
	const root = parseRichHtml(props.html)
	if (root.type === "root") {
		return <Fragment>
			{root.children?.slice(0, props.max).map((a, i) => <FormatTree key={i}  richText={a} />)}
		</Fragment>
	}

	throw new Error("This should not happen")
}