import React from "react"
import MyText from "../../components/MyText"
import { View, Image } from "react-native"
import Base from "../../components/layout/Base"
import useDuration, { useDurationToDeadline } from "../../utils/Duration"
import { useTranslation } from "react-i18next"
import { ActiveViewerQuery } from "./queries.generated"
import { Text, useTheme } from "pusatec-react-native"
import { RentingState } from "../../doorhub-types"

type Renting = NonNullable<NonNullable<ActiveViewerQuery["viewer"]>["user"]>["activeRentings"]["activeRentings"][0]

interface Props {
	renting: Renting
	onPress(): void
}

const ActiveRentingTime = (props: {
	returnItemBefore: Date
}) => {
	const { t } = useTranslation()
	const { colors } = useTheme()
	const rentTimeLeft = useDurationToDeadline(
		new Date(props.returnItemBefore)
	)

	if (!rentTimeLeft.duration) {
		return null
	}

	return rentTimeLeft.overtime ? (
		<Text
			bold
			text={t("{{time}} overtime! Return as soon as possible!", {
				time: rentTimeLeft.duration.format(t),
			})}
			style={{ color: colors.error }}
		/>
	) : (
		<Text
			text={t("{{time}} renting time left", {
				time: rentTimeLeft.duration.format(t),
			})}
		/>
	)		
}

const UseCodeBefore = (props: {
	useCodeBefore: Date
}) => {
	const { t } = useTranslation()

	const timeleft = useDurationToDeadline(
		props.useCodeBefore ? new Date(props.useCodeBefore) : undefined,
		1000
	)

	if (!timeleft.duration) {
		return null
	}

	return (
		<Text
			text={t("{{time}} until expiration", {
				time: timeleft.duration.format(t),
			})}
		/>
	)
}

const RentingTime = (props: {
	renting: Renting
}) => {
	if (props.renting.state === RentingState.NeedsPermit ||
		props.renting.state === RentingState.WaitsPickup) {
		return <UseCodeBefore useCodeBefore={props.renting.useCodeBefore} />
	}

	if (props.renting.state === RentingState.WaitsReturn) {
		return <UseCodeBefore useCodeBefore={props.renting.useReturnCodeBefore} />
	}

	if (props.renting.state === RentingState.Active) {
		return <ActiveRentingTime returnItemBefore={props.renting.returnItemBefore} />
	}

	return null
}

export default function RentingLink(props: Props) {
	const { renting, onPress } = props

	return (
		<Base onPress={onPress} style={{ padding: 20 }}>
			<View style={{
				flexDirection: "row",
				justifyContent:
					"space-between"
			}}>
				<Image
					style={{
						width: 48,
						height: 48,
						alignSelf: "center",
						marginRight: 20,
					}}
					resizeMode="contain"
					source={require("../../../assets/icons/679902-ecommerce/svg/080-box-1.svg")}
				/>
				<View style={{ flex: 1, justifyContent: "space-between" }}>
					<Text text={renting.item?.name?.toUpperCase()} bold />
					<RentingTime renting={renting} />
				</View>
			</View>
		</Base >
	)
}
