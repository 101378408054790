import React, {useState } from "react"
import {AdminViewQuery} from "../Admin/queries.generated"
import { RentingState } from "../../doorhub-types"
import { MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/core"
import {ManageRentings } from "../Admin/ManageRentings"

type Renting = NonNullable<AdminViewQuery["lockersystem"]>["activeRentings"]["rentings"][0]

export function ManageRentingsExample(props: {
    route: RouteProp<MainStackParamList, "SelectLockerStepExample">
    rentings: Renting[]

}) {
	const fakeAdminViewQuery = {
		__typename: "Query",

		activeRentings: {
			__typename: "LockersystemActiveRentingsConnection",
			rentings: [
				{
					__typename: "Renting",
					id: "renting1",
					returnItemBefore: "2023-10-30T12:00:00Z",
					state: "NeedsPermit",
					user: {
						__typename: "User",
						id: "user1",
						gmail: "user1@gmail.com",
						name: "User 1",
					},
					item: {
						__typename: "Item",
						name: "Item 1",
					},
					locker: {
						__typename: "Locker",
						id: "locker1",
						name: 101,
						lockerGroup: {
							__typename: "LockerGroup",
							id: "group1",
							name: "Group 1",
						},
					},
				},
				{
					__typename: "Renting",
					id: "renting2",
					returnItemBefore: "2023-10-30T12:00:00Z",
					state: "NeedsPermit",
					user: {
						__typename: "User",
						id: "user2",
						gmail: "user2@gmail.com",
						name: "User 2",
					},
					item: {
						__typename: "Item",
						name: "Item 2",
					},
					locker: {
						__typename: "Locker",
						id: "locker2",
						name: 102,
						lockerGroup: {
							__typename: "LockerGroup",
							id: "group2",
							name: "Group 2",
						},
					},
				},
				{
					__typename: "Renting",
					id: "renting3",
					returnItemBefore: "2023-10-30T12:00:00Z",
					state: "NeedsPermit",
					user: {
						__typename: "User",
						id: "user3",
						gmail: "user3@gmail.com",
						name: "User 3",
					},
					item: {
						__typename: "Item",
						name: "Item 3",
					},
					locker: {
						__typename: "Locker",
						id: "locker3",
						name: 103,
						lockerGroup: {
							__typename: "LockerGroup",
							id: "group3",
							name: "Group 3",
						},
					},
				}
			],
		},
	}
	const systemId = props.route?.params?.systemId

	const [adminQueryState, setAdminQueryState] = useState(fakeAdminViewQuery)

	const givePermit = (rentingId: string) => {
		const rentings = adminQueryState.activeRentings.rentings
		const renting = rentings.find(r => r.id === rentingId)
		if (!renting) {
			return
		}
		renting.state = RentingState.WaitsPickup
		setAdminQueryState({
			...adminQueryState,
			activeRentings: {
				...adminQueryState.activeRentings,
				rentings: [...rentings]
			}
		})
	}

	const declinePermit = (rentingId: string) => {
		const rentings = adminQueryState.activeRentings.rentings
		const renting = rentings.find(r => r.id === rentingId)
		if (!renting) {
			return
		}
		renting.state = RentingState.Finished
		setAdminQueryState({
			...adminQueryState,
			activeRentings: {
				...adminQueryState.activeRentings,
				rentings: [...rentings]
			}
		})
	}

	return (
		<ManageRentings
			interruptRenting={() => {}}
			systemId={systemId}
			rentings={adminQueryState.activeRentings.rentings}
			onRefetch={() => {}}
			loading={false}
			givePermit={givePermit}
			declinePermit={declinePermit}
			updateRenting={() => {}}
		/>
	)
}
