import { Text, useTheme } from "pusatec-react-native"
import React from "react"
import {View, Image, TouchableHighlight } from "react-native"

interface Props {
	lockerNumber: number
	available: boolean
	selected?: boolean
	height?: number
	width?: number
	onSelect(): void
	terminal: boolean
}

export default function LockerButton(props: Props) {
	const { available, selected, onSelect } = props
	const {colors } = useTheme()

	let backgroundColor = "#F8F8F8"

	if(!available) {
		backgroundColor = "#dddddd"
	}

	return (
		<TouchableHighlight  disabled={!available || props.terminal} onPress={() => {
			onSelect()	
		}} style={{
			padding: "2px",
			minWidth: props.width,
			height: props.height,
		}}>
			<View style={{
				backgroundColor: selected ? colors.primary : backgroundColor,
				borderWidth: 1,
				borderColor: "black",
				borderRadius: 3,
				padding: 3,
				paddingLeft: 10,
				paddingRight: 10,
				height: "100%"
			}}>
				<View style={{
					width: 0,
					height: 0,
					alignContent: "center",
				}}>
					{props.terminal &&
                        <Image
                        	style={{
                        		width: 70,
                        		height: 35,

                        	}}
                        	resizeMode="contain"
                        	source={require("../../../assets/Terminal.png")}
                        />}						
				</View>
				<View style={{
					justifyContent: "center",
					alignItems: "center",
					flex: 1,

				}}>
					<Text
						style={{
							alignContent: "center",
							fontSize: 20,

							fontWeight: "bold",
							textAlignVertical: "center",
						}}
						text={!props.terminal ? props.lockerNumber + "" : ""}

					/>					
				</View >
			</View>
		</TouchableHighlight>
	)
}