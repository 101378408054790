import { RouteProp } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import { ErrorMessage, Notification, Text } from "pusatec-react-native"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Panel from "../../../components/layout/Panel"
import Loading from "../../../components/Loading"
import { goBack, MainStackParamList } from "../../../MainNavigation"
import { usePlaceItemToLockerMutation } from "../../Admin/queries.generated"
import { useViewItemQuery } from "../../ViewItem/queries.generated"
import SelectLockerStep, { LockerSelection } from "../common-steps/SelectLockerStep"
import PlaceRentableItemStep from "./PlaceRentableItemStep"
import useWizard from "../use-wizard"

interface Props {
    navigation: StackNavigationProp<MainStackParamList, "PlaceItemToLocker">
    route: RouteProp<MainStackParamList, "PlaceItemToLocker">
}

export function PlaceItemToLocker(props: Props) {
	const { route, navigation } = props
	const { t } = useTranslation()

	const itemId = route.params.itemId
	if (!itemId) throw Error("Route param itemId is missing")

	const { data, loading, error } = useViewItemQuery({
		variables: { itemId }
	})

	const [placeItem, placeItemM] = usePlaceItemToLockerMutation()

	const wizardState = useWizard(2)
	const { activeStepIndex, nextStep, prevStep } = wizardState

	const [locker, setLocker] = useState<LockerSelection | null>(null)

	if (loading) return <Loading />
	if (error || !data?.item?.lockersystem?.id) {
		return <ErrorMessage message={t("Fetching item failed")} extra={error} />
	}

	if (placeItemM.loading) return <Loading />
	if (placeItemM.error) {
		return <ErrorMessage extra={placeItemM.error} />
	}

	if (activeStepIndex === 0) {
		return <>
			{route.params.itemWasJustCreated
				? <Panel>
					<Notification
						type="info"
						title={t("Item was created")}
						text={t("You can now place the item to a locker")}
						// style={{ marginTop: 20, textAlign: 'center', fontSize: 18 }}
					/>
				</Panel>
				: null}
			<SelectLockerStep
				itemWasJustCreated={route.params.itemWasJustCreated}
				wizardState={wizardState}
				locker={locker}
				lockersystemId={data.item.lockersystem.id}
				onCancel={() => goBack(navigation)}
				onChange={setLocker}
				onComplete={async () => {
					await placeItem({
						variables: {
							itemId: itemId,
							lockerId: locker!.id,
						},
					})
					nextStep()
				}}
			/>
		</>
	} else if (activeStepIndex === 1) {
		return <PlaceRentableItemStep
			wizardState={wizardState}
			itemId={itemId}
			onComplete={() => goBack(navigation)}
			onCancel={prevStep}
		/>
	}
	return null
}
