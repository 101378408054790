import React from "react"
import { View, StyleProp, ViewStyle, ScrollView, } from "react-native"
import { Button, useTheme } from "pusatec-react-native"

interface Props {
    pageIndex?: number
    pages: string[]
    onChangePage: (pageIndex: number) => void
    style?: StyleProp<ViewStyle>
}

export function AdminTabs(props: Props) {
	const { pageIndex, pages, onChangePage, style } = props
	const { colors, fonts } = useTheme()

	return (
		<View style={[{
			flexDirection: "row",
			marginLeft: 5,
			marginRight: 5,
		}, style]}>
			<ScrollView
				horizontal={true}>
				{pages.map((pageText, _pageIndex) => {
					const selected = pageIndex === _pageIndex

					return <Button
						key={pageText}
						text={pageText}
						containerStyle={{
							backgroundColor: selected
								? colors.primary
								: "#EEE",
						}}
						textStyle={{
							// backgroundColor: colors.primary,
							color: selected ? "white" : "black",
							fontSize: fonts.normal.fontSize * 0.9,
							fontFamily: "Nunito, sans-serif",
							fontWeight: "bold",
						}}
						onPress={() => onChangePage(_pageIndex)}
					/>
				})}
			</ScrollView>
		</View>
	)
}
