import React from "react"
import WizardStep from "../WizardStep"
import { WizardState } from "../use-wizard"
import { Form, TouchButton } from "pusatec-react-native"
import { useTranslation } from "react-i18next"
import MainScrollPanel from "../../../components/layout/MainScrollPanel"
import Panel from "../../../components/layout/Panel"
import Base from "../../../components/layout/Base"
import { useAllowRentingMutation } from "../../Admin/queries.generated"
import { MainStackParamList } from "../../../MainNavigation"
import { StackNavigationProp } from "@react-navigation/stack"

interface Props {
    navigation: StackNavigationProp<MainStackParamList, "AllowRenting">
    wizardState: WizardState
    userId?: string
    itemId: string
    systemId: string
    userGmail: string
}

export const AllowRentingStep = (props: Props) => {
	const { t } = useTranslation()
	const [allowRenting, {loading}] = useAllowRentingMutation()

	return (
		<WizardStep
			title={t("Set last return datetime")}
			wizardState={props.wizardState}
		>
			<Form
				onSubmit={(val) => {
					allowRenting({
						variables: {
							input: {
								itemId: props.itemId,
								email: props.userGmail,
								rentingUserId: props.userId,
								returnItemBefore: val.returnItemBefore ?
									new Date(val.returnItemBefore).toISOString() :
									undefined
							}
						}
					}).then(() => {
						props.navigation.replace("AdminNavigation", {
							systemId: props.systemId
						})
					})
				}}
				style={{ flex: 1 }}
			>
				<MainScrollPanel>
					<Base>
						<Form.DatetimeField name="returnItemBefore" />
					</Base>
				</MainScrollPanel>
				<Panel style={{ flexDirection: "row", justifyContent: "space-between" }}>
					<TouchButton
						text="Back"
						onPress={() => {
							props.wizardState.prevStep()
						}}
					/>
					<Form.Submit
						type="TouchButton"
						loading={loading}
						text={t("Give renting permission")}
					/>
				</Panel>
			</Form>
		</WizardStep>
	)
}