import React, { useEffect, useState } from "react"
import { View, Platform } from "react-native"
import { useTranslation } from "react-i18next"
import MyText from "../../components/MyText"
import { StackNavigationProp } from "@react-navigation/stack"
import { MainStackParamList } from "../../MainNavigation"
import { AdminViewQuery, useAdminViewQuery, useDeclinePermitMutation, useGivePermitMutation, useUpdateRentingMutation } from "./queries.generated"
import { RouteProp } from "@react-navigation/core"
import { useActiveViewerQuery } from "../ActiveItems/queries.generated"
import { ManageItems } from "./ManageItems"
import { ManageDeliveries } from "./ManageDeliveries"
import { ManageRentings } from "./ManageRentings"
import { ErrorMessage, Form, Loading, TouchButton } from "pusatec-react-native"
import { AdminTabs } from "./AdminTabs"
import { ManageItemGroups } from "./ManageItemGroups"
import { ShowDoormap } from "./ShowDoormap"
import ManageTranslations from "./ManageTranslations"

interface Props {
    navigation: StackNavigationProp<MainStackParamList, "AdminNavigation">
    route: RouteProp<MainStackParamList, "AdminNavigation">
}

export function AdminNavigation(props: Props) {
	const { navigation, route } = props
	const { params } = route
	const { t } = useTranslation()

	const { data, error, loading } = useActiveViewerQuery()

	const adminSystems = data?.viewer?.user?.adminSystems?.adminSystems ?? []

	useEffect(() => {
		if (params && params.systemId == null && adminSystems.length > 0) {
			navigation.setParams({
				systemId: adminSystems[0].id
			})
		}
	}, [adminSystems.length])

	const pageIndex = params?.pageIndex ? parseInt(params.pageIndex as any) : 0

	if (loading) return <Loading />
	if (error) return <ErrorMessage />
	if (!adminSystems || adminSystems.length <= 0) throw Error("Invalid state, In AdminNavigation wihtout any admin systems")

	return (<>
		<View style={{ flex: 1 }}>
			{adminSystems.length > 1 && <Form style={{
				flexDirection: "row",
				alignItems: "center",
				alignSelf: "stretch",
				padding: 10,
				marginBottom: 0,
			}}
			onChange={values => {
				navigation.setParams({
					systemId: values.lockersystemId,
				})
			}}
			>
				<MyText text={t("Administrator mode")} />
				<Form.SelectField
					name="lockersystemId"
					initialValue={params?.systemId ?? adminSystems[0].id}
					hideError
					style={{ flex: 1, marginLeft: 10 }}
					options={adminSystems.map(s => {
						return {
							label: s.name ?? "<name missing>",
							value: s.id,
						}
					})}
				/>
			</Form>}

			{params?.systemId != null
				? <Content lockersystemId={params.systemId} pageIndex={pageIndex} onPageIndexChange={inx => {
					navigation.setParams({
						pageIndex: inx
					})
				}} />
				: null}

		</View>
	</>
	)
}

function Content(props: {
	lockersystemId: string
	pageIndex: number
	onPageIndexChange: (pageIndex: number) => void
}) {
	const { data, error, loading, refetch } = useAdminViewQuery({
		skip: props.lockersystemId == null,
		variables: { systemId: props.lockersystemId }
	})

	const { t } = useTranslation()

	const pages = [
		`${t("Items")} (${data?.lockersystem?.items?.items.length ?? 0})`,
		`${t("Deliveries")} (${data?.lockersystem?.activeDeliveries?.deliveries.length ?? 0})`,
		`${t("Rentings")} (${data?.lockersystem?.activeRentings?.rentings.length ?? 0})`,
		`${t("Item groups")} (${data?.lockersystem?.itemGroups?.length ?? 0})`,
		t("Doormap"),
		`${t("Messages")}`,
	]

	return <>
		<AdminTabs pageIndex={props.pageIndex} pages={pages} onChangePage={props.onPageIndexChange} />

		{error && <ErrorMessage extra={error} />}

		{props.lockersystemId && data?.lockersystem && <View style={{ flex: 1, marginTop: 10 }}>
			<ActiveTab
				pageIndex={props.pageIndex}
				data={data}
				systemId={props.lockersystemId}
				refetch={refetch}
				loading={loading}
			/>
		</View>}
	</>
}

function ActiveTab(props: {
    pageIndex: number
    data: AdminViewQuery
    systemId: string
    refetch: () => void
    loading: boolean
}) {
	const [updateRenting, { loading: updatingRenting }] = useUpdateRentingMutation()
	const [givePermit] = useGivePermitMutation()
	const [declinePermit] = useDeclinePermitMutation()
	const [interruptRenting] = useGivePermitMutation()

	const givePermitCall = (rentingId: string) => {
		givePermit({
			variables: {
				input: {
					rentingId: rentingId
				}
			}
		})
	}

	const declinePermitCall = (rentingId: string) => {
		declinePermit({
			variables: {
				input: {
					rentingId: rentingId
				}
			}
		})
	}

	if (props.pageIndex === 0) {
		return (
			<ManageItems
				systemId={props.systemId}
				onRefetch={props.refetch}
				loading={props.loading}
			/>
		)
	}

	if (props.pageIndex === 1) {
		return (
			<ManageDeliveries
				systemId={props.systemId}
				deliveries={props.data.lockersystem!.activeDeliveries.deliveries}
				onRefetch={props.refetch}
				loading={props.loading}
			/>
		)
	}

	if (props.pageIndex == 2) {
		return (
			<ManageRentings
				systemId={props.systemId}
				rentings={props.data.lockersystem!.activeRentings.rentings}
				onRefetch={props.refetch}
				loading={props.loading}
				givePermit={givePermitCall}
				declinePermit={declinePermitCall}
				updateRenting={updateRenting}
				interruptRenting={interruptRenting}
			/>
		)
	}

	if (props.pageIndex == 3) {
		return (
			<ManageItemGroups
				lockersystemId={props.systemId}
				itemGroups={props.data.lockersystem!.itemGroups} />
		)
	}

	if (props.pageIndex == 4) {
		return (
			<ShowDoormap
				loading={props.loading}
				lockerGroups={props.data.lockersystem!.lockerGroups}
			/>
		)
	}

	if (props.pageIndex == 5) {
		return (
			<ManageTranslations
				lockersystemId={props.systemId}
				onRefetch={props.refetch}
				translations={props.data.lockersystem!.lainaamoTranslations}
			/>
		)
	}

	return (
		<ShowDoormap
			lockerGroups={props.data.lockersystem?.lockerGroups!}
			loading={props.loading}
		/>
	)
}

