import firebase from "firebase/app"
import "firebase/auth"
import { BuildConfiguration } from "./platform-info/build-configuration"

//  web app's Firebase configuration

let firebaseConfig

if (BuildConfiguration.ENV === "production") {
	firebaseConfig = {
		apiKey: "AIzaSyDTH43XXX99KwMpSEHOSuW3MkaFcJa7o7k",
		authDomain: "pt-doorhub-prod.firebaseapp.com",
		databaseURL: "https://pt-doorhub-prod.firebaseio.com",
		projectId: "pt-doorhub-prod",
		storageBucket: "pt-doorhub-prod.appspot.com",
		messagingSenderId: "997106895004",
		appId: "1:997106895004:web:3ea157f8f98b84c1074559"
	}
} else {
	firebaseConfig = {
		apiKey: "AIzaSyBNIIYQJ8oVd5oFg-dCERvooMt3AqbyxWU",
		authDomain: "pt-doorhub-dev.firebaseapp.com",
		databaseURL: "https://pt-doorhub-dev.firebaseio.com",
		projectId: "pt-doorhub-dev",
		storageBucket: "pt-doorhub-dev.appspot.com",
		messagingSenderId: "1012932064622",
		appId: "1:1012932064622:web:1f4c15214270eec2d53d7f"
	}
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export { firebase }
