import React, { useEffect, useState } from "react"

export interface ParsedDuration {
	days: number
	hours: number
	minutes: number
	seconds: number
	negative: boolean
}

export type DurationUnit =
	| "days"
	| "hours"
	| "minutes"
	| "seconds"
	| "milliseconds"

export default class Duration {
	private milliseconds: number

	constructor(milliseconds?: number) {
		this.milliseconds = milliseconds || 0
	}

	public format(
		translator: (unit: DurationUnit) => string,
		full?: boolean
	): string {
		const { days, hours, minutes, seconds } = this.getParsed()
		if (full) {
			return `${days ? `${days} ${translator("days")}, ` : ""}${hours ? `${hours} ${translator("hours")}, ` : ""
			}${minutes ? `${minutes} ${translator("minutes")}, ` : ""}`
		}
		if (days >= 1) return `${days} ${translator("days")}`
		if (hours >= 1) return `${hours} ${translator("hours")}`
		if (minutes >= 1) return `${minutes} ${translator("minutes")}`
		return `${seconds} ${translator("seconds")}`
	}

	public getParsed(): ParsedDuration {
		let ms = Math.abs(this.milliseconds)

		const days = Math.floor(ms / (24 * 60 * 60 * 1000))
		ms -= days * 24 * 60 * 60 * 1000
		const hours = Math.floor(ms / (60 * 60 * 1000))
		ms -= hours * 60 * 60 * 1000
		const minutes = Math.floor(ms / (60 * 1000))
		ms -= minutes * 60 * 1000
		const seconds = Math.floor(ms / 1000)
		return {
			days,
			hours,
			minutes,
			seconds,
			negative: this.isNegative(),
		}
	}

	public setToParsed(parsed: ParsedDuration) {
		this.milliseconds =
			(((parsed.days * 24 + parsed.hours) * 60 + parsed.minutes) * 60 +
				parsed.seconds) *
			1000
	}

	public setUnit(unit: DurationUnit, amount: number): Duration {
		if (unit === "milliseconds") {
			this.milliseconds = amount
		} else {
			const parsed = this.getParsed()
			parsed[unit] = amount
			this.setToParsed(parsed)
		}
		return this
	}

	public getUnit(unit: DurationUnit): number {
		if (unit === "milliseconds") return this.milliseconds
		return this.getParsed()[unit]
	}

	public getAsUnit(unit: DurationUnit): number {
		if (unit === "milliseconds") return this.milliseconds
		if (unit === "seconds") return Math.floor(this.milliseconds / 1000)
		if (unit === "minutes") return Math.floor(this.milliseconds / (1000 * 60))
		if (unit === "hours") {
			return Math.floor(this.milliseconds / (1000 * 60 * 60))
		}
		if (unit === "days") {
			return Math.floor(this.milliseconds / (1000 * 60 * 60 * 24))
		}
		throw Error("Could not getAsUnit")
	}

	public copy(): Duration {
		return new Duration(this.milliseconds)
	}

	public isNegative(): boolean {
		return this.milliseconds < 0
	}
}

interface HookResult {
	duration: Duration | null
	overtime: boolean
}

export function useDurationToDeadline(
	deadline: Date | null | undefined,
	updateInterval?: number
): HookResult {
	const [timeNow, setTimeNow] = useState(new Date())

	useEffect(() => {
		const interval = setInterval(() => {
			const now = new Date()
			setTimeNow(now)
		}, updateInterval || 30000)
		return () => {
			clearInterval(interval)
		}
	}, [])

	if (!deadline) {
		return {
			duration: null,
			overtime: false,
		}
	}

	const duration = new Duration(deadline.getTime() - timeNow.getTime())

	return {
		duration,
		overtime: deadline.getTime() - timeNow.getTime() <= 0,
	}
}
