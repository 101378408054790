import { useState, useEffect } from "react"
import { BackHandler } from "react-native"
import useBackHandler from "../../utils/useBackHandler"

export interface WizardState {
	activeStepIndex: number
	stepCount: number
	nextStep(): void
	prevStep(): void
}

export default function useWizard(stepCount: number, initialStep?: number): WizardState {
	const [activeStepIndex, setActiveStepIndex] = useState<number>(initialStep ?? 0)

	const nextStep = () => {
		setActiveStepIndex(activeStepIndex + 1)
	}

	const prevStep = () => {
		setActiveStepIndex(activeStepIndex - 1)
	}

	// Capture back presses and use them to navigate wizard
	useBackHandler(() => {
		if (activeStepIndex === 0) return false
		prevStep()
		return true
	}, [activeStepIndex])

	return {
		activeStepIndex,
		stepCount,
		nextStep,
		prevStep,
	}
}
