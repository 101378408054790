import React from "react"
import { ScrollView, StyleSheet, } from "react-native"
import Logo from "../../components/Logo"
import VersionInfo from "../../components/VersionInfo"
import MyText from "../../components/MyText"
import { useTranslation } from "react-i18next"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import Base from "../../components/layout/Base"
import { ErrorMessage } from "pusatec-react-native"

interface Props {
	signinError?: Error
	children?: any
}

export default function SignInScreen(props: Props) {
	const { signinError, children } = props
	const { t } = useTranslation()

	return (
		<MainScrollPanel style={styles.screen}>
			<Logo />

			<Base style={{ marginTop: 20, backgroundColor: "#FDFDFD" }}>
				<MyText style={{ padding: 5, textAlign: "center" }} text={t("Signin screen info text")} />
				<ScrollView style={styles.signinState} contentContainerStyle={styles.signinStateContentContainer}>
					{signinError && <ErrorMessage message="Kirjautuminen epäonnistui" extra={signinError} />}
					{children}

					{/*
					<TouchButton
						type="secondary"
						text={t("Help")}
						onPress={() => { }}
						containerStyle={{ marginTop: 20 }}
					/>
					*/}
				</ScrollView>
			</Base>

			<VersionInfo />
		</MainScrollPanel>
	)
}

const styles = StyleSheet.create({
	screen: {
		flex: 1,
		justifyContent: "center",
		alignItems: "stretch",
	},
	signinState: {
		marginTop: 10,
		marginBottom: 10,
		alignSelf: "stretch",
		minHeight: 140,
		flex: 1,
	},
	signinStateContentContainer: {
		alignItems: "center",
		justifyContent: "center",
	}
})
