import React from "react"
import WizardStep from "../WizardStep"
import { WizardState } from "../use-wizard"
import { useTranslation } from "react-i18next"
import MainScrollPanel from "../../../components/layout/MainScrollPanel"
import Base from "../../../components/layout/Base"
import Panel from "../../../components/layout/Panel"
import { useSelectLockerSystemQuery } from "./queries.generated"
import { ErrorMessage, Text, TouchButton } from "pusatec-react-native"
import { BackIcon } from "../../../components/BackIcon"

interface Props {
	wizardState: WizardState
	userId: string
	selectedSystemId: string | null
	onChange(systemId: string): void
	onComplete(systemId: string): void
	onCancel(): void
	adminOnly?: boolean
}

export function SelectLockerSystemStep(props: Props) {
	const {
		userId,
		selectedSystemId,
		onChange,
		onComplete,
		onCancel,
		wizardState,
	} = props

	const { t } = useTranslation()

	const { data, loading, error, refetch } = useSelectLockerSystemQuery({
		variables: {
			userId,
		},
	})

	return (
		<WizardStep title={t("")} wizardState={wizardState}>'
			<div style={{
				fontFamily: 'Nunito, Mada-Regular, sans-serif',
				fontSize: 25,
				textAlign: 'center'
			}}>
				{t("Select environment")}
			</div>
			<MainScrollPanel onRefresh={refetch} refreshing={loading}>
				{error && <ErrorMessage extra={error} />}
				{data?.user!.lockersystems.lockersystems.map((system) => {
					return (
						<Base
							key={system.id}
							selected={selectedSystemId === system.id}
							onPress={() => {
								onChange(system.id)
							}}
						>
							<Text text={system.name?.toUpperCase()} bold />
						</Base>
					)
				})}
			</MainScrollPanel>

			<Panel style={{ flexDirection: "row", justifyContent: "space-between" }}>
				<TouchButton
					type="secondary"
					text={t("Cancel")}
					icon={BackIcon}
					onPress={onCancel}
				/>
				<TouchButton
					disabled={!selectedSystemId}
					type="primary"
					text={t("Next")}
					onPress={onComplete.bind(null, selectedSystemId!)}
				/>
			</Panel>
		</WizardStep>
	)
}
