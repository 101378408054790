import React from "react"
import { createContext } from "react"
import { Authentication } from "./authentication-types"

interface AuthenticationContextI {
	authentication: Authentication
	signOut: () => void
}

export const AuthenticationContext = createContext<AuthenticationContextI>({
	// @ts-ignore
	authentication: null,
})

interface Props {
	children: any
	authentication: Authentication
	signOut: () => void
}

export function AuthenticationContextProvider(props: Props) {
	const { authentication, signOut, children } = props
	return (
		<AuthenticationContext.Provider value={{ authentication, signOut }}>
			{children}
		</AuthenticationContext.Provider>
	)
}
