import { Text, TouchButton } from "pusatec-react-native"
import { useDebouncedState } from "pusatec-react-utility"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Pressable, TextInput, View } from "react-native"
import { SearchBar } from "react-native-elements"
import Base from "../../components/layout/Base"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import { useCreateItemGroupMutation, useDeleteItemGroupMutation, useSearchItemGroupsQuery } from "../../components/queries.generated"
import { theme } from "../../theme"
import { AdminViewQuery } from "./queries.generated"
import { BiTrash } from "react-icons/bi"
import { AdminToolbar } from "./admin_toolbar"
import { DeleteItemGroupConfirmation } from "./DeleteItemGroupConfirmation"
import { HeaderBar } from "../../components/Headerbar"

type ItemGroup = NonNullable<AdminViewQuery["lockersystem"]>["itemGroups"][0]

const CreateNewItem = (props: {
    lockersystemId: string
    onSuccess: () => void
    onCanceled: () => void
}) => {
	const [itemGroupName, setItemGroupName] = useState("")

	const [createItemGroup] = useCreateItemGroupMutation()

	return (
		<Base>
			<View style={{
				flexDirection: "column",
				marginBottom: 10,
			}}>
				<Text text="New item group" />
				<TextInput placeholder=""
					onChangeText={text => {
						setItemGroupName(text)
					}}
					value={itemGroupName}
					style={{
						borderColor: "grey",
						borderWidth: 1,
						height: 40,
					}} />
			</View>
			<View style={{
				flexDirection: "row",
			}}>
				<TouchButton text="Cancel" onPress={() => {
					props.onCanceled()
				}} />
				<TouchButton text="Create" onPress={() => {
					createItemGroup({
						variables: {
							input: {
								lockersystemId: props.lockersystemId,
								name: itemGroupName
							}
						}
					}).then(res => {
						props.onSuccess()
					})
				}} />
			</View>
		</Base>
	)
}

interface Props {
    lockersystemId: string
    itemGroups: ItemGroup[]
}

export const ManageItemGroups = (props: Props) => {
	const [itemGroupSearch, itemGroupSearchd, setItemGroupSearch] = useDebouncedState("", 400)
	const [selectedItemGroupId, setSelectedItemGroupId] = useState<string>("")
	const [creatingItemGroup, setCreatingItemGroup] = useState<boolean>(false)
	const  [showDeleteItemConfirmation, setShowDeleteItemConfirmation] = useState<boolean>(false)
	const { t } = useTranslation()

	const { data, loading, refetch } = useSearchItemGroupsQuery({
		variables: {
			opt: {
				lockersystemId: props.lockersystemId,
				parentItemGroupId: null,
				searchWord: itemGroupSearchd
			}
		}
	})
	const selectedname = data?.itemGroups.itemGroups.find(itemGroup => itemGroup.id == selectedItemGroupId)?.name

	return (
		<View style={[{ flex: 1 }]}>
			{showDeleteItemConfirmation && <DeleteItemGroupConfirmation
				itemGroup={selectedItemGroupId}
				name={selectedname}
				onExit={() => {
					setShowDeleteItemConfirmation(false)
				}}
			/>}
			<SearchBar
				lightTheme
				inputStyle={theme.Input.inputStyle}
				errorStyle={theme.Input.errorMessageStyle}
				labelStyle={theme.Input.labelStyle}
				containerStyle={theme.Input.containerStyle}
				inputContainerStyle={theme.Input.inputContainerStyle}
				value={itemGroupSearch}
				onChangeText={text => setItemGroupSearch(text)}
			/>

			{creatingItemGroup &&
                <CreateNewItem
                	lockersystemId={props.lockersystemId}
                	onCanceled={() => {
                		setCreatingItemGroup(false)
                	}}
                	onSuccess={() => {
                		setCreatingItemGroup(false)
                		refetch()
                	}}
                />}
			<MainScrollPanel
				style={{ borderWidth: 0, backgroundColor: undefined }}
				refreshing={false}
				onRefresh={() => { }}
			>
				<HeaderBar text={t("Groups")} />
				<View>
					{data?.itemGroups.itemGroups.map(itemGroup => (
						<Base key={itemGroup.id}
							selected={selectedItemGroupId == itemGroup.id}
							onPress={() => {
								if (selectedItemGroupId == itemGroup.id) {
									setSelectedItemGroupId("")
								} else {
									setSelectedItemGroupId(itemGroup.id)
								}
							}}>
							<View style={{ flexDirection: "row"  }}>
								<Text text={itemGroup.name} style={{ flexGrow: 1 }} />
								<Pressable onPress={() => {
									setSelectedItemGroupId(itemGroup.id)
									setShowDeleteItemConfirmation(true)
								}}>
									<BiTrash size={30} style={{color: "#f54058"}} />
								</Pressable>

							</View>
						</Base>
					))}
				</View>
			</MainScrollPanel>
			<AdminToolbar>

				<View style={{ flexGrow: 1, justifyContent: "flex-end" }}>

					<TouchButton
						text={t("Create itemgroup")}
						onPress={() => {
							setCreatingItemGroup(true)
						}}
					/>
				</View>

			</AdminToolbar>
		</View>
	)
}
