import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveViewerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveViewerQuery = { __typename?: 'Query', viewer?: { __typename?: 'Viewer', user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, language?: string | null, activeRentings: { __typename?: 'UserActiveRentingsConnection', totalCount: number, activeRentings: Array<{ __typename?: 'Renting', returnItemBefore?: any | null, useReturnCodeBefore?: any | null, useCodeBefore?: any | null, id: string, state: Types.RentingState, locker: { __typename?: 'Locker', id: string, name: number, lockerGroupId?: string | null, lockersystemId?: string | null }, item?: { __typename?: 'Item', id: string, name?: string | null, imageUrl?: string | null } | null }> }, activeDeliveries: { __typename?: 'UserActiveDeliveriesConnection', deliveries: Array<{ __typename?: 'Delivery', id: string, description?: string | null, pickupBefore?: any | null, usePickupCodeBefore?: any | null, imageUrl?: string | null, userDeliveredId?: string | null, whoCanPickup: Array<{ __typename?: 'User', id: string, name?: string | null, gmail?: string | null, userName?: string | null }>, userWhoDelivered?: { __typename?: 'User', id: string, name?: string | null, gmail?: string | null, userName?: string | null } | null, locker?: { __typename?: 'Locker', id: string, name: number, lockerGroupId?: string | null, lockersystemId?: string | null } | null }> }, lockersystems: { __typename?: 'UserLockersystemsConnection', lockersystems: Array<{ __typename?: 'Lockersystem', id: string, name?: string | null, lockerGroups: Array<{ __typename?: 'LockerGroup', id: string, name: string }> }> }, adminSystems: { __typename?: 'UserLockersystemsConnection', adminSystems: Array<{ __typename?: 'Lockersystem', id: string, name?: string | null }> } } | null } | null };


export const ActiveViewerDocument = gql`
    query ActiveViewer {
  viewer {
    user {
      id
      name
      email
      language
      activeRentings {
        totalCount
        activeRentings {
          returnItemBefore
          useReturnCodeBefore
          useCodeBefore
          id
          state
          locker {
            id
            name
            lockerGroupId
            lockersystemId
          }
          item {
            id
            name
            imageUrl
          }
        }
      }
      activeDeliveries {
        deliveries {
          id
          description
          pickupBefore
          usePickupCodeBefore
          imageUrl
          pickupBefore
          userDeliveredId
          whoCanPickup {
            id
            name
            gmail
            userName
          }
          userWhoDelivered {
            id
            name
            gmail
            userName
          }
          locker {
            id
            name
            lockerGroupId
            lockersystemId
          }
        }
      }
      lockersystems(opt: {rentingAllowed: true, deliveryAllowed: true}) {
        lockersystems {
          id
          name
          lockerGroups {
            id
            name
          }
        }
      }
      adminSystems: lockersystems(
        opt: {isAdmin: true, rentingAllowed: true, deliveryAllowed: true}
      ) {
        adminSystems: lockersystems {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useActiveViewerQuery__
 *
 * To run a query within a React component, call `useActiveViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveViewerQuery(baseOptions?: Apollo.QueryHookOptions<ActiveViewerQuery, ActiveViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveViewerQuery, ActiveViewerQueryVariables>(ActiveViewerDocument, options);
      }
export function useActiveViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveViewerQuery, ActiveViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveViewerQuery, ActiveViewerQueryVariables>(ActiveViewerDocument, options);
        }
export type ActiveViewerQueryHookResult = ReturnType<typeof useActiveViewerQuery>;
export type ActiveViewerLazyQueryHookResult = ReturnType<typeof useActiveViewerLazyQuery>;
export type ActiveViewerQueryResult = Apollo.QueryResult<ActiveViewerQuery, ActiveViewerQueryVariables>;