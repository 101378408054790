import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeliveryPickupCodeQueryVariables = Types.Exact<{
  deliveryId: Types.Scalars['ID'];
}>;


export type DeliveryPickupCodeQuery = { __typename?: 'Query', delivery?: { __typename?: 'Delivery', id: string, pickupCode?: string | null, pickupCodeUsedAt?: any | null, usePickupCodeBefore?: any | null } | null };


export const DeliveryPickupCodeDocument = gql`
    query DeliveryPickupCode($deliveryId: ID!) {
  delivery(deliveryId: $deliveryId) {
    id
    pickupCode
    pickupCodeUsedAt
    usePickupCodeBefore
  }
}
    `;

/**
 * __useDeliveryPickupCodeQuery__
 *
 * To run a query within a React component, call `useDeliveryPickupCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryPickupCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryPickupCodeQuery({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useDeliveryPickupCodeQuery(baseOptions: Apollo.QueryHookOptions<DeliveryPickupCodeQuery, DeliveryPickupCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryPickupCodeQuery, DeliveryPickupCodeQueryVariables>(DeliveryPickupCodeDocument, options);
      }
export function useDeliveryPickupCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryPickupCodeQuery, DeliveryPickupCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryPickupCodeQuery, DeliveryPickupCodeQueryVariables>(DeliveryPickupCodeDocument, options);
        }
export type DeliveryPickupCodeQueryHookResult = ReturnType<typeof useDeliveryPickupCodeQuery>;
export type DeliveryPickupCodeLazyQueryHookResult = ReturnType<typeof useDeliveryPickupCodeLazyQuery>;
export type DeliveryPickupCodeQueryResult = Apollo.QueryResult<DeliveryPickupCodeQuery, DeliveryPickupCodeQueryVariables>;