import { RouteProp } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import { ErrorMessage, Form, Text } from "pusatec-react-native"
import React, { useContext, } from "react"
import { useTranslation } from "react-i18next"
import { View, StyleSheet, Platform, ViewStyle, StyleProp } from "react-native"
import Panel from "../../components/layout/Panel"
import MyText from "../../components/MyText"
import { BackButton, MainStackParamList } from "../../MainNavigation"
import { AuthenticationContext } from "../../services/authentication/authentication-context"
import { ActiveViewerDocument } from "../ActiveItems/queries.generated"
import { useJoinLockersystemMutation } from "./queries.generated"

interface Props {
    navigation: StackNavigationProp<MainStackParamList, "JoinLockersystem">
    route: RouteProp<MainStackParamList, "JoinLockersystem">
}

export function JoinLockersystemScreen(props: Props) {
	return (<>
		<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
			<JoinLockersystem />
		</View>
		{Platform.OS !== "android" && (
			<Panel>
				<BackButton />
			</Panel>
		)}
	</>
	)
}

interface JoinLockersystemProps {
    style?: StyleProp<ViewStyle>
}

export function JoinLockersystem(props: JoinLockersystemProps) {
	const { style } = props
	const { t } = useTranslation()
	const { authentication: { userId } } = useContext(AuthenticationContext)

	const [joinLockersystem, joinLockersystemM] = useJoinLockersystemMutation({
		refetchQueries: [{
			query: ActiveViewerDocument
		}]
	})

	const joinedSystem = joinLockersystemM.data?.joinUserToLockersystem?.lockersystem
	const alreadyJoined = joinLockersystemM.data?.joinUserToLockersystem?.alreadyJoined || false

	return (
		<View style={[styles.content, style]}>
			{joinedSystem ?
				alreadyJoined ? <MyText text={t("You are already joined to lockersystem {{name}}", { name: joinedSystem.name })} />
					: <MyText text={t("Succesfully joined to lockersystem {{name}}", { name: joinedSystem.name })} />
				: <>
					<Form<{ joinCode: string }>
						allowMultipleSubmits
						onSubmit={(values) => {
							joinLockersystem({
								variables: {
									systemUrlId: values.joinCode,
									userId: userId,
								}
							})
						}}
					>
						<Text text={t("Join lockersystem by inputting code")} />
						<Form.TextField name="joinCode" inputStyle={styles.input} required />
						{joinLockersystemM.error && <ErrorMessage extra={joinLockersystemM.error} />}
						<Form.Submit
							type="TouchButton"
							text={t("Join")}
							loading={joinLockersystemM.loading}
							style={{ alignSelf: "flex-start", width: 200 }}
						/>
					</Form>
				</>}
		</View>
	)
}

const styles = StyleSheet.create({
	content: {
		alignItems: "center"
	},
	input: {
		fontSize: 18,
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 10,
		paddingBottom: 10
	}
})
