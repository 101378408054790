import React, { useEffect } from "react"
import { AuthenticationContextProvider } from "./authentication-context"
import { signInToDoorhubWithGoogle } from "./sign-in-to-doorhub-with-google"
import { firebase } from "../firebase-web"
import SignInScreen from "../../screens/SignIn/SignInScreen"
import { setApolloAuthToken } from "../ApolloClient"
import { Image, TouchableWithoutFeedback } from "react-native"
import { Authentication } from "./authentication-types"
import { gotoSamlAuth, signInToDoorhubWithSaml, useSamlReturnDetector } from "./sign-in-to-doorhub-with-saml"
import { useAsync } from "pusatec-react-utility"
import Loading from "../../components/Loading"
import { useTranslation } from "react-i18next"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { parseDoorhubToken } from "./parse-doorhub-token"
import { ErrorMessage } from "pusatec-react-native"
import { BuildConfiguration } from "../platform-info/build-configuration"

const auth = firebase.auth()
const authProvider = new firebase.auth.GoogleAuthProvider()

interface Props {
	signedInContent: any
}

const DOORHUB_TOKEN_ID = "doorhub_token"
const existingTokenPromise = AsyncStorage.getItem(DOORHUB_TOKEN_ID)

export function AuthenticationProvider(props: Props): JSX.Element | null {
	const { signedInContent } = props
	const { t } = useTranslation()

	const [call, state] = useAsync<Authentication | null>()
	const authentication = state.data ?? null

	// USE DEV DOORHUB TOKEN TO BYPASS SIGNIN WHILE IN DEV
	useEffect(() => {
		if (BuildConfiguration.DEV_DOORHUB_TOKEN) {
			call(async () => {
				return parseDoorhubToken(BuildConfiguration.DEV_DOORHUB_TOKEN!)
			})
		}
	}, [])

	useEffect(() => {
		setApolloAuthToken(state.data?.token ?? null)

		if (state.data?.token) {
			AsyncStorage.setItem(DOORHUB_TOKEN_ID, state.data?.token ?? null)
		}
	}, [state.data?.token])

	useEffect(() => {
		existingTokenPromise.then(token => {
			if (token) {
				const authentication = parseDoorhubToken(token)
				console.info({ authentication, now: new Date().getTime() })
				if (new Date().getTime() > authentication.exp * 1000) {
					console.info("Found token in localStorage but it was expired")
				} else {
					call(async () => authentication)
				}
			}
		})
	}, [])

	// Firebase might sign the user in automatically and trigger this. ??
	useEffect(() => {
		if (!authentication) {
			const unsubscribe = auth.onAuthStateChanged(async user => {
				if (user && !state.called) {
					call(async () => {
						console.info("Auth state changed", { user })
						const idToken = await user?.getIdToken()
						return await signInToDoorhubWithGoogle(idToken)
					})
				}
			})

			return unsubscribe
		}
	}, [authentication != null])

	useSamlReturnDetector((samlLoginAuthCode) => {
		call(async () => {
			return await signInToDoorhubWithSaml(samlLoginAuthCode)
		})
	})

	if (!authentication) {
		return <SignInScreen signinError={undefined}>
			{state.loading ? <Loading /> : <>
				<TouchableWithoutFeedback
					style={{ margin: 10 }}
					onPress={() => {
						call(async () => {
							const result = await auth.signInWithPopup(authProvider)
							const user = result.user

							if (!user) throw Error("Google signin user not found")

							console.info(`Signed in with google ${user}`)
							const idToken = await user.getIdToken()
							return await signInToDoorhubWithGoogle(idToken)
						})
					}}
				>
					<Image
						source={require("../../../assets/google-signin-button/btn_google_signin_light_normal_web@2x.png")}
						resizeMode="contain"
						style={{ width: 260, height: 80 }}
					/>
				</TouchableWithoutFeedback>

				<TouchableWithoutFeedback
					style={{ margin: 10 }}
					onPress={() => gotoSamlAuth()}
				>
					<Image
						source={require("../../../assets/Haka_login_vaaka.jpg")}
						resizeMode="contain"
						style={{ width: 260, height: 80 }}
					/>
				</TouchableWithoutFeedback>
			</>}

			{state.error ? <ErrorMessage extra={state.error} /> : null}
		</SignInScreen>
	} else {
		console.info({ authentication })
		return (
			<AuthenticationContextProvider
				authentication={authentication}
				signOut={async () => {
					await auth.signOut()
					localStorage.removeItem(DOORHUB_TOKEN_ID)
					call(async () => null)
				}}>
				{signedInContent}
			</AuthenticationContextProvider>
		)
	}
}
