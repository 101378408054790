import React, { useEffect, useState, createContext } from "react"
import { ToastAndroid } from "react-native"
import CloudMessaging from "./cloud-messaging"
import { useNotifications } from "./use-notifications"
import { NotificationContext } from "./notification-context"
import { FirebaseMessagingTypes } from "@react-native-firebase/messaging"

interface Props {
	children: any
}

export function NotificationsProvider(props: Props) {
	const { children } = props

	const [notification, setNotification] = useState<FirebaseMessagingTypes.RemoteMessage | null>(null)

	const registerCloudMessaging = async () => {
		try {
			await CloudMessaging.requestPermissions()
			const token = await CloudMessaging.getToken()
			if (token) await CloudMessaging.registerTokenToDoorhub(token)
		} catch (error) {
			console.error("Registering CloudMessaging token failed: ", error)
		}
	}
	useEffect(() => {
		registerCloudMessaging()
	}, [])

	useNotifications({
		onNotification: (_notification) => {
			console.info("Received notification ", _notification)
			ToastAndroid.showWithGravity(
				`${_notification.data?.title} \n ${_notification.data?.body || ""}`,
				ToastAndroid.LONG,
				ToastAndroid.TOP
			)
			setNotification(_notification)
		},
	})

	return (
		<NotificationContext.Provider
			value={{
				notification,
				clearNotification: setNotification.bind(null, null),
			}}
		>
			{children}
		</NotificationContext.Provider>
	)
}
