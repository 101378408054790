import React from "react"
import { StackNavigationProp } from "@react-navigation/stack"
import { BackButton, MainStackParamList } from "../../MainNavigation"
import { useTranslation } from "react-i18next"
import Panel from "../../components/layout/Panel"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import LockersystemLink from "./LockersystemLink"
import { Platform } from "react-native"
import { useActiveViewerQuery } from "../ActiveItems/queries.generated"
import { ErrorMessage, Text, } from "pusatec-react-native"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "BrowseSystems">
}

export default function BrowseSystemsScreen(props: Props) {
	const { navigation } = props
	const { t } = useTranslation()

	const { data, loading, error, refetch } = useActiveViewerQuery()

	return (
		<>
			<Panel>
				<Text
					style={{ color: "rgba(0,0,0,0.7)", textAlign: "center", }}
					text={t("Select environment")}
				/>
			</Panel>
			<MainScrollPanel refreshing={loading} onRefresh={() => refetch()}>
				{error && <ErrorMessage extra={error} />}
				{data?.viewer?.user?.lockersystems?.lockersystems?.map((system) => {
					return (
						<LockersystemLink
							key={system!.id}
							lockersystem={system}
							onPress={() => {
								navigation.push("BrowseItems", {
									systemId: system.id
								})
							}}
						/>
					)
				})}
			</MainScrollPanel>

			{Platform.OS !== "android" && (
				<Panel>
					<BackButton />
				</Panel>
			)}
		</>
	)
}
