import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { fi } from "./fi"
import { en } from "./en"

const getBrowserLanguage = () => {
	var language = navigator.languages ? navigator.languages[0] : navigator.language
	if (language.indexOf('fi') === 0) {
		return 'fi';
	} else if (language.indexOf('en') === 0) {
		return 'en';
	} else {
		return "en";
	}
}

export function initializeI18n() {
	i18n.use(initReactI18next).init({
		fallbackLng: getBrowserLanguage(),
		resources: {
			en: {
				translation: en,
			},
			fi: {
				translation: fi,
			},
		},
		interpolation: {
			escapeValue: false, // not needed for react as it does escape per default to prevent xss!
		},
		// let keys through if translations are missing
		parseMissingKeyHandler: (key) => key,
	})
}

export default i18n