export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  DateTime: string;
  File: any;
  Upload: any;
};

export type AcceptEnvironment = {
  clusterId: Scalars['ID'];
  environmentId: Scalars['ID'];
  lockersystemId: Scalars['ID'];
};

export type AcceptEnvironmentResponse = {
  __typename?: 'AcceptEnvironmentResponse';
  environment: Environment;
  lockersystem: Lockersystem;
};

export type AccessCard = {
  __typename?: 'AccessCard';
  accesslist?: Maybe<Accesslist>;
  cardName?: Maybe<Scalars['String']>;
  cardnumber: Scalars['String'];
  id: Scalars['ID'];
  lockedLockers?: Maybe<Array<Maybe<Locker>>>;
  name?: Maybe<Scalars['String']>;
  validityEnd?: Maybe<Scalars['DateTime']>;
  validityStart?: Maybe<Scalars['DateTime']>;
};


export type AccessCardLockedLockersArgs = {
  lockersystemId?: InputMaybe<Scalars['ID']>;
};

export type AccessCardValidiesConnection = {
  __typename?: 'AccessCardValidiesConnection';
  accessCards?: Maybe<Array<Maybe<AccessCard>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AccessGroup = {
  __typename?: 'AccessGroup';
  accesslistHasAccess?: Maybe<Scalars['Boolean']>;
  allowLockAll: Scalars['Boolean'];
  id: Scalars['ID'];
  lockers: Array<Locker>;
  lockersystem?: Maybe<Lockersystem>;
  maxLockedPerCard: Scalars['Int'];
  name: Scalars['String'];
  requireLockerPerm: Scalars['Boolean'];
};


export type AccessGroupAccesslistHasAccessArgs = {
  accesslistId: Scalars['ID'];
};

export type AccessGroupAccess = {
  __typename?: 'AccessGroupAccess';
  accessGroup?: Maybe<AccessGroup>;
  accessGroupId?: Maybe<Scalars['ID']>;
  accesslist?: Maybe<Accesslist>;
  accesslistId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  master: Scalars['Boolean'];
};

export type AccessGroupLocker = {
  __typename?: 'AccessGroupLocker';
  id: Scalars['ID'];
  lockersystem?: Maybe<Lockersystem>;
  lockersystemId?: Maybe<Scalars['ID']>;
  name: Scalars['Int'];
};

export type AccessGroupLockersConnection = {
  __typename?: 'AccessGroupLockersConnection';
  lockers: Array<AccessGroupLocker>;
  totalCount: Scalars['Int'];
};

export type AccessGroupsConnection = {
  __typename?: 'AccessGroupsConnection';
  accessGroups: Array<AccessGroup>;
};

export type Accesslist = {
  __typename?: 'Accesslist';
  accessCards: Array<AccessCard>;
  accessGroupAccesses: Array<AccessGroupAccess>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lockersystem?: Maybe<Lockersystem>;
  lockersystemId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};


export type AccesslistAccessCardsArgs = {
  cardnumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
};

export type AccesslistChange = {
  __typename?: 'AccesslistChange';
  accesslist?: Maybe<Accesslist>;
  card?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<AccesslistChangeType>;
};

export enum AccesslistChangeType {
  AccessGroupRemoved = 'AccessGroupRemoved',
  AccessGroupUpdated = 'AccessGroupUpdated',
  AccesslistRemoved = 'AccesslistRemoved',
  AccesslistUpdated = 'AccesslistUpdated',
  AddCardToAccesslist = 'AddCardToAccesslist',
  RemCardToAccesslist = 'RemCardToAccesslist'
}

export type AccesslistUsersConnection = {
  __typename?: 'AccesslistUsersConnection';
  totalCount?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type AccesslistsConnection = {
  __typename?: 'AccesslistsConnection';
  accesslists: Array<Accesslist>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AddAccessGroupAccess = {
  accessGroupId: Scalars['ID'];
  accesslistId: Scalars['ID'];
  master: Scalars['Boolean'];
};

export type AddAccessGroupAccessForAccesslist = {
  accessGroupId: Scalars['ID'];
  accesslistId: Scalars['ID'];
  master: Scalars['Boolean'];
};

export type AddAccessGroupAccessResponse = {
  __typename?: 'AddAccessGroupAccessResponse';
  accessGroupAccess?: Maybe<AccessGroupAccess>;
};

export type AddGateToGateGroupResponse = {
  __typename?: 'AddGateToGateGroupResponse';
  gate: Gate;
  gateGroup: GateGroup;
  success: Scalars['Boolean'];
};

export type AddLockerToGroup = {
  lockerGroupId: Scalars['ID'];
  lockerId: Scalars['ID'];
};

export type AddLockersToAccessGroupResponse = {
  __typename?: 'AddLockersToAccessGroupResponse';
  accessGroupLockers: Array<AccessGroupLocker>;
  success: Scalars['Boolean'];
};

export type AddLockersToLockerGroup = {
  lockerGroupId: Scalars['ID'];
  lockerIds: Array<Scalars['ID']>;
};

export type AddLockersToLockerGroupResponse = {
  __typename?: 'AddLockersToLockerGroupResponse';
  lockerGroup?: Maybe<LockerGroup>;
};

export type AddLockersystemPermissionForUser = {
  admin?: InputMaybe<Scalars['Boolean']>;
  lockersystemId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AddLockersystemPermissionForUserResponse = {
  __typename?: 'AddLockersystemPermissionForUserResponse';
  lockersystem?: Maybe<Lockersystem>;
  lockersystemUserPermission?: Maybe<LockersystemUserPermission>;
  user?: Maybe<User>;
};

export type AddUserLockerPermission = {
  lockerId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AddUserLockerPermissionResponse = {
  __typename?: 'AddUserLockerPermissionResponse';
  userLockerPermission?: Maybe<UserLockerPermission>;
};

export type AddUserToAccesslist = {
  accesslistId: Scalars['ID'];
  cardName?: InputMaybe<Scalars['String']>;
  cardnumber: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  validityEnd?: InputMaybe<Scalars['DateTime']>;
  validityStart?: InputMaybe<Scalars['DateTime']>;
};

export type AddUserToAccesslistResponse = {
  __typename?: 'AddUserToAccesslistResponse';
  accessCard?: Maybe<AccessCard>;
  accesslist?: Maybe<Accesslist>;
  user?: Maybe<User>;
};

export type AllAccessGroupsConnection = {
  __typename?: 'AllAccessGroupsConnection';
  accessGroups?: Maybe<Array<Maybe<AccessGroup>>>;
};

export type AllowRenting = {
  email?: InputMaybe<Scalars['String']>;
  itemId: Scalars['ID'];
  rentingUserId?: InputMaybe<Scalars['ID']>;
  returnItemBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AllowRentingResponse = {
  __typename?: 'AllowRentingResponse';
  renting?: Maybe<Renting>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['ID'];
  lastUsage?: Maybe<Scalars['String']>;
};

export enum ApiPermission {
  FetchAccessCardUsers = 'FETCH_ACCESS_CARD_USERS',
  SyncAllAccessCardUsers = 'SYNC_ALL_ACCESS_CARD_USERS',
  SyncCurrentAccessCardUsers = 'SYNC_CURRENT_ACCESS_CARD_USERS'
}

export type AreaState = {
  __typename?: 'AreaState';
  areaId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  counter?: Maybe<Scalars['Int']>;
};

export type BlockDoorcontrol = {
  deviceNumber: Scalars['ID'];
};

export type BlockDoorcontrolResponse = {
  __typename?: 'BlockDoorcontrolResponse';
  doorcontrol?: Maybe<Doorcontrol>;
};

export type CalibrateLockers = {
  cmd: CalibrationCmd;
  deviceNumber: Scalars['String'];
  lockerNumbers: Array<Scalars['Int']>;
};

export type CalibrateLockersResponse = {
  __typename?: 'CalibrateLockersResponse';
  status?: Maybe<CommandStatus>;
};

export enum CalibrationCmd {
  Calibrate = 'Calibrate',
  Cancel = 'Cancel',
  FactoryReset = 'FactoryReset'
}

export type CancelRenting = {
  rentingId: Scalars['ID'];
};

export type CancelRentingResponse = {
  __typename?: 'CancelRentingResponse';
  reting?: Maybe<Renting>;
};

export type CardKey = {
  __typename?: 'CardKey';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ChangeEnvironmentSequenceNumber = {
  environmentId: Scalars['ID'];
  sequenceNumber: Scalars['Int'];
};

export type ChangeEnvironmentSequenceNumberResponse = {
  __typename?: 'ChangeEnvironmentSequenceNumberResponse';
  environment: Environment;
};

export type ChangeLanguage = {
  language: Scalars['String'];
  userId: Scalars['ID'];
};

export type ChangeLanguageResponse = {
  __typename?: 'ChangeLanguageResponse';
  user?: Maybe<User>;
};

export type CheckRentingCodeResponse = {
  __typename?: 'CheckRentingCodeResponse';
  lockerNumber?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type CheckRentingReturnCode = {
  __typename?: 'CheckRentingReturnCode';
  lockerNumber?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type ClearLockersystemHistory = {
  lockersystemId: Scalars['ID'];
};

export type ClearLockersystemHistoryResponse = {
  __typename?: 'ClearLockersystemHistoryResponse';
  lockersystem?: Maybe<Lockersystem>;
};

export type ClearLockersystemState = {
  lockersystemId: Scalars['ID'];
};

export type ClearLockersystemStateResponse = {
  __typename?: 'ClearLockersystemStateResponse';
  lockersystem?: Maybe<Lockersystem>;
};

export type CloseSales = {
  areaId?: InputMaybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  gateId?: InputMaybe<Scalars['ID']>;
  nodeId?: InputMaybe<Scalars['ID']>;
};

export type CloseSalesResponse = {
  __typename?: 'CloseSalesResponse';
  affectedNodes?: Maybe<Scalars['Int']>;
  nodes: Array<GateNode>;
};

export type Cluster = {
  __typename?: 'Cluster';
  clusterName?: Maybe<Scalars['String']>;
  clusterState?: Maybe<ClusterState>;
  environmentId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  lockedLockersCount?: Maybe<Scalars['Int']>;
  lockers: Array<Locker>;
  lockersCount?: Maybe<Scalars['Int']>;
  lockersystem?: Maybe<Lockersystem>;
  lockersystemId?: Maybe<Scalars['ID']>;
  onlineLockersCount?: Maybe<Scalars['Int']>;
  openLockersCount?: Maybe<Scalars['Int']>;
};

export enum ClusterState {
  All = 'ALL',
  Configured = 'CONFIGURED',
  NotConfigured = 'NOT_CONFIGURED'
}

export enum CommandStatus {
  Delivered = 'Delivered',
  Offline = 'Offline'
}

export type CompanyGateState = {
  __typename?: 'CompanyGateState';
  areaStates: Array<AreaState>;
  companyId: Scalars['ID'];
  companyName?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Int']>;
  gateStates: Array<GateState>;
  nodeStates: Array<GateNode>;
};

export type CompanyLockersystemChange = {
  __typename?: 'CompanyLockersystemChange';
  lockerGroups: Array<LockerGroup>;
  lockers: Array<Locker>;
  lockersystem: Lockersystem;
};

export type ConnectDoorcontrolToLockersystem = {
  clusterId: Scalars['ID'];
  deviceNumber: Scalars['ID'];
};

export type ConnectDoorcontrolToLockersystemResponse = {
  __typename?: 'ConnectDoorcontrolToLockersystemResponse';
  cluster?: Maybe<Cluster>;
  doorcontrol?: Maybe<Doorcontrol>;
};

export type CreateAccessGroup = {
  lockersystemId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateAccessGroupResponse = {
  __typename?: 'CreateAccessGroupResponse';
  accessGroup: AccessGroup;
};

export type CreateAccesslist = {
  lockersystemId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateAccesslistResponse = {
  __typename?: 'CreateAccesslistResponse';
  accesslist: Accesslist;
};

export type CreateCardKeys = {
  keysdat: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCardKeysResponse = {
  __typename?: 'CreateCardKeysResponse';
  cardKey?: Maybe<CardKey>;
};

export type CreateGateGroupResponse = {
  __typename?: 'CreateGateGroupResponse';
  gateGroup?: Maybe<GateGroup>;
  success: Scalars['Boolean'];
};

export type CreateItem = {
  description?: InputMaybe<Scalars['String']>;
  itemGroupId?: InputMaybe<Scalars['ID']>;
  lockersystemId: Scalars['ID'];
  name: Scalars['String'];
  rentingMaxTime?: InputMaybe<Scalars['Int']>;
};

export type CreateItemGroup = {
  lockersystemId: Scalars['ID'];
  name: Scalars['String'];
  parentItemGroupId?: InputMaybe<Scalars['ID']>;
};

export type CreateItemGroupResponse = {
  __typename?: 'CreateItemGroupResponse';
  itemGroup?: Maybe<ItemGroup>;
};

export type CreateItemResponse = {
  __typename?: 'CreateItemResponse';
  code?: Maybe<Scalars['String']>;
  item?: Maybe<Item>;
};

export type CreateLockerGroup = {
  lockerGroupId?: InputMaybe<Scalars['ID']>;
  lockersystemId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateLockerGroupResponse = {
  __typename?: 'CreateLockerGroupResponse';
  lockerGroup?: Maybe<LockerGroup>;
  lockersystem?: Maybe<Lockersystem>;
};

export type CreateLockerNode = {
  access?: InputMaybe<Scalars['Boolean']>;
  cluster?: InputMaybe<Scalars['String']>;
  demo?: InputMaybe<Scalars['Boolean']>;
  desfire?: InputMaybe<Scalars['Boolean']>;
  doorNumberLeftDown?: InputMaybe<Scalars['Int']>;
  doorNumberLeftUp?: InputMaybe<Scalars['Int']>;
  doorNumberRightDown?: InputMaybe<Scalars['Int']>;
  doorNumberRightUp?: InputMaybe<Scalars['Int']>;
  hslDesfire?: InputMaybe<Scalars['Boolean']>;
  keepLock?: InputMaybe<Scalars['Boolean']>;
  leftOpen?: InputMaybe<Scalars['Boolean']>;
  mifare?: InputMaybe<Scalars['Boolean']>;
  mobile?: InputMaybe<Scalars['Boolean']>;
  multiuse?: InputMaybe<Scalars['Boolean']>;
  nodeNumber?: InputMaybe<Scalars['Int']>;
  personal?: InputMaybe<Scalars['Boolean']>;
  rightOpen?: InputMaybe<Scalars['Boolean']>;
  segment?: InputMaybe<Scalars['String']>;
  silent?: InputMaybe<Scalars['Boolean']>;
  ultralight?: InputMaybe<Scalars['Boolean']>;
  verticalDoubleLock?: InputMaybe<Scalars['Boolean']>;
};

export type CreateLockerNodeResponse = {
  __typename?: 'CreateLockerNodeResponse';
  lockerNode: LockerNode;
};

export type CreateLockersystem = {
  name: Scalars['String'];
};

export type CreateLockersystemResponse = {
  __typename?: 'CreateLockersystemResponse';
  lockersystem?: Maybe<Lockersystem>;
};

export type CreateResetTokenResponse = {
  __typename?: 'CreateResetTokenResponse';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type CreateTokenFromPortalOauthCode = {
  code: Scalars['String'];
};

export type CreateTokenFromPortalOauthCodeResponse = {
  __typename?: 'CreateTokenFromPortalOauthCodeResponse';
  token?: Maybe<Scalars['String']>;
};

export type CreateTokenResponse = {
  __typename?: 'CreateTokenResponse';
  token?: Maybe<Scalars['String']>;
};

export type CreateUser = {
  userName: Scalars['String'];
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  user?: Maybe<User>;
};

export type DatetimeDataPoint = {
  __typename?: 'DatetimeDataPoint';
  datetime: Scalars['DateTime'];
  inCount: Scalars['Int'];
  outCount: Scalars['Int'];
};

export type DeclineDoorcontrol = {
  deviceNumber: Scalars['ID'];
};

export type DeclineDoorcontrolResponse = {
  __typename?: 'DeclineDoorcontrolResponse';
  cluster?: Maybe<Cluster>;
  doorcontrol?: Maybe<Doorcontrol>;
};

export type DeclineEnvironment = {
  environmentId: Scalars['ID'];
};

export type DeclineEnvironmentResponse = {
  __typename?: 'DeclineEnvironmentResponse';
  cluster: Cluster;
  environment: Environment;
};

export type DeclinePermit = {
  rentingId: Scalars['ID'];
};

export type DeclinePermitResponse = {
  __typename?: 'DeclinePermitResponse';
  renting: Renting;
};

export type DeleteAccessCardUser = {
  accesslistId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DeleteAccessCardUserResponse = {
  __typename?: 'DeleteAccessCardUserResponse';
  accesslist?: Maybe<Accesslist>;
};

export type DeleteCardKeys = {
  id: Scalars['ID'];
};

export type DeleteCardKeysResponse = {
  __typename?: 'DeleteCardKeysResponse';
  id: Scalars['ID'];
};

export type DeleteDoorcontrol = {
  deviceNumber: Scalars['ID'];
};

export type DeleteDoorcontrolResponse = {
  __typename?: 'DeleteDoorcontrolResponse';
  doorcontrol?: Maybe<Doorcontrol>;
};

export type DeleteItem = {
  itemId: Scalars['ID'];
};

export type DeleteItemGroup = {
  id: Scalars['ID'];
};

export type DeleteItemGroupResponse = {
  __typename?: 'DeleteItemGroupResponse';
  itemGroupId?: Maybe<Scalars['ID']>;
};

export type DeleteItemResponse = {
  __typename?: 'DeleteItemResponse';
  item: Item;
};

export type DeleteLockerProgram = {
  lockerProgramId: Scalars['ID'];
};

export type DeleteLockerProgramResponse = {
  __typename?: 'DeleteLockerProgramResponse';
  lockerProgramId?: Maybe<Scalars['ID']>;
};

export type DeliverItemToUser = {
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  lockerId: Scalars['ID'];
  pickupBefore: Scalars['DateTime'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type DeliverItemToUserResponse = {
  __typename?: 'DeliverItemToUserResponse';
  code: Scalars['String'];
  delivery: Delivery;
  locker: Locker;
};

export type DeliveriesConnection = {
  __typename?: 'DeliveriesConnection';
  deliveries: Array<Delivery>;
};

export type Delivery = {
  __typename?: 'Delivery';
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryCode?: Maybe<Scalars['String']>;
  deliveryCodeUsedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  interruptCode?: Maybe<Scalars['String']>;
  interruptCodeUsedAt?: Maybe<Scalars['DateTime']>;
  locker?: Maybe<Locker>;
  pickupBefore?: Maybe<Scalars['DateTime']>;
  pickupCode?: Maybe<Scalars['String']>;
  pickupCodeUsedAt?: Maybe<Scalars['DateTime']>;
  pickupUser?: Maybe<User>;
  useDeliveryCodeBefore?: Maybe<Scalars['DateTime']>;
  useInterruptCodeBefore?: Maybe<Scalars['DateTime']>;
  usePickupCodeBefore?: Maybe<Scalars['DateTime']>;
  userDeliveredId?: Maybe<Scalars['String']>;
  userWhoDelivered?: Maybe<User>;
  whoCanPickup: Array<User>;
};

export type DisconnectDoorcontrol = {
  deviceNumber: Scalars['ID'];
};

export type DisconnectDoorcontrolResponse = {
  __typename?: 'DisconnectDoorcontrolResponse';
  doorcontrol?: Maybe<Doorcontrol>;
};

export type DisconnectLainaamoTerminal = {
  deviceNumber: Scalars['ID'];
};

export type DisconnectLainaamoTerminalResponse = {
  __typename?: 'DisconnectLainaamoTerminalResponse';
  lainaamoTerminal?: Maybe<LainaamoTerminal>;
};

export type DoorNote = {
  __typename?: 'DoorNote';
  door?: Maybe<Scalars['Int']>;
  doorcontrol?: Maybe<Doorcontrol>;
  doorcontrolId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  lockersystem?: Maybe<Lockersystem>;
  lockersystemId?: Maybe<Scalars['ID']>;
  noteType?: Maybe<DoorNoteType>;
  serialNumber?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  val1?: Maybe<Scalars['Int']>;
  val2?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

export enum DoorNoteType {
  CalibrationEnd = 'CalibrationEnd',
  OptoLower = 'OptoLower',
  OptoUpper = 'OptoUpper',
  Unknown = 'Unknown'
}

export type DoorNotesQuery = {
  doorcontrolId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type Doorcontrol = {
  __typename?: 'Doorcontrol';
  block?: Maybe<Scalars['Boolean']>;
  canbridgeUsed?: Maybe<Scalars['Boolean']>;
  canbusBroken?: Maybe<Scalars['Boolean']>;
  cardKeysId?: Maybe<Scalars['ID']>;
  cluster?: Maybe<Cluster>;
  connectionEnd?: Maybe<Scalars['DateTime']>;
  connectionStart?: Maybe<Scalars['DateTime']>;
  deviceNumber?: Maybe<Scalars['String']>;
  disconnectCountToday?: Maybe<Scalars['Int']>;
  hide?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  messagesPerHour?: Maybe<Scalars['Int']>;
  offlineReported?: Maybe<Scalars['Boolean']>;
  rateLimit?: Maybe<Scalars['Int']>;
  reportErrors?: Maybe<Scalars['Boolean']>;
  reportOfflines?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type DoorcontrolConnEvent = {
  __typename?: 'DoorcontrolConnEvent';
  eventType?: Maybe<DoorcontrolConnEventType>;
  id: Scalars['ID'];
  timestamp?: Maybe<Scalars['DateTime']>;
};

export enum DoorcontrolConnEventType {
  Connected = 'Connected',
  Disconected = 'Disconected'
}

export type DoorcontrolDoor = {
  __typename?: 'DoorcontrolDoor';
  alarm?: Maybe<Scalars['Boolean']>;
  calibrating?: Maybe<Scalars['Boolean']>;
  door: Scalars['Int'];
  locked?: Maybe<Scalars['Boolean']>;
  node?: Maybe<Scalars['Int']>;
  segment?: Maybe<Scalars['Int']>;
};

export type DoorcontrolEvent = {
  __typename?: 'DoorcontrolEvent';
  doorcontrol?: Maybe<Doorcontrol>;
  doorcontrolId?: Maybe<Scalars['ID']>;
  eventType?: Maybe<DoorcontrolEventType>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export enum DoorcontrolEventType {
  CanbusBroken = 'CanbusBroken',
  CanbusFixed = 'CanbusFixed',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  DoorAccessLocking = 'DoorAccessLocking',
  DoorAccessLockingResponse = 'DoorAccessLockingResponse',
  DoorAccessOpening = 'DoorAccessOpening',
  DoorAccessOpeningResponse = 'DoorAccessOpeningResponse',
  DoorAccessTimeout = 'DoorAccessTimeout',
  DoorCurrentSequenceNumber = 'DoorCurrentSequenceNumber',
  DoorSerial = 'DoorSerial',
  DoorVersion = 'DoorVersion',
  ErrorMessage = 'ErrorMessage',
  LockedDoorsRequest = 'LockedDoorsRequest',
  LockedDoorsResponse = 'LockedDoorsResponse',
  LockerAccessFeedback = 'LockerAccessFeedback',
  OpenLocker = 'OpenLocker',
  RequestLockerHistory = 'RequestLockerHistory',
  RequestLockerStatus = 'RequestLockerStatus',
  SendDoormapToLockerNodes = 'SendDoormapToLockerNodes'
}

export type DoorcontrolEventsConnection = {
  __typename?: 'DoorcontrolEventsConnection';
  doorcontrolEvents: Array<DoorcontrolEvent>;
  totalCount: Scalars['Int'];
};

export type DoorcontrolEventsQuery = {
  deviceNumber?: InputMaybe<Scalars['ID']>;
  doorcontrolEventType?: InputMaybe<DoorcontrolEventType>;
  limit?: InputMaybe<Scalars['Int']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DoorcontrolLocker = {
  __typename?: 'DoorcontrolLocker';
  alarm?: Maybe<Scalars['Boolean']>;
  applicationId?: Maybe<Scalars['Int']>;
  doorcontrolId: Scalars['Int'];
  id: Scalars['Int'];
  locked?: Maybe<Scalars['Boolean']>;
  lockerNumber?: Maybe<Scalars['Int']>;
  nodeId?: Maybe<Scalars['Int']>;
  online?: Maybe<Scalars['Boolean']>;
  serialNumber?: Maybe<Scalars['Int']>;
  serialUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
  versionUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type DoorcontrolLockersUpdate = {
  __typename?: 'DoorcontrolLockersUpdate';
  initialState?: Maybe<Array<DoorcontrolLocker>>;
  update?: Maybe<DoorcontrolLocker>;
};

export type DoorcontrolMessage = {
  __typename?: 'DoorcontrolMessage';
  deviceNumber?: Maybe<Scalars['String']>;
  direction?: Maybe<MessageDirection>;
  message?: Maybe<Scalars['String']>;
  messagesPerHour?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type DoorcontrolNode = {
  __typename?: 'DoorcontrolNode';
  node?: Maybe<Scalars['Int']>;
  panel?: Maybe<Scalars['Int']>;
  segment?: Maybe<Scalars['Int']>;
  serials?: Maybe<Array<Maybe<Scalars['Int']>>>;
  state?: Maybe<HeartbeatState>;
  version?: Maybe<Scalars['Int']>;
};

export type DoorcontrolStateChange = {
  __typename?: 'DoorcontrolStateChange';
  deviceNumber?: Maybe<Scalars['String']>;
  doorcontrolLockers?: Maybe<Array<DoorcontrolLocker>>;
  updatePercent?: Maybe<Scalars['Int']>;
};

export type DoorcontrolsConnection = {
  __typename?: 'DoorcontrolsConnection';
  doorcontrols: Array<Doorcontrol>;
};

export type DoormapRow = {
  access?: InputMaybe<Scalars['Boolean']>;
  cluster: Scalars['String'];
  demo?: InputMaybe<Scalars['Boolean']>;
  desfire?: InputMaybe<Scalars['Boolean']>;
  hslDesfire?: InputMaybe<Scalars['Boolean']>;
  keepLock?: InputMaybe<Scalars['Boolean']>;
  leftDownDoorNr?: InputMaybe<Scalars['Int']>;
  leftOpen?: InputMaybe<Scalars['Boolean']>;
  leftUpDoorNr?: InputMaybe<Scalars['Int']>;
  mifare?: InputMaybe<Scalars['Boolean']>;
  mobile?: InputMaybe<Scalars['Boolean']>;
  multiuse?: InputMaybe<Scalars['Boolean']>;
  node: Scalars['Int'];
  openMask?: InputMaybe<Scalars['Int']>;
  personal?: InputMaybe<Scalars['Boolean']>;
  rightDownDoorNr?: InputMaybe<Scalars['Int']>;
  rightOpen?: InputMaybe<Scalars['Boolean']>;
  rightUpDoorNr?: InputMaybe<Scalars['Int']>;
  segment: Scalars['Int'];
  silent?: InputMaybe<Scalars['Boolean']>;
  ultralight?: InputMaybe<Scalars['Boolean']>;
  verticalDoubleLock?: InputMaybe<Scalars['Boolean']>;
};

export type DoormapTarget = {
  node: Scalars['Int'];
  segment: Scalars['Int'];
};

export type DownloadNewDoormapResponse = {
  __typename?: 'DownloadNewDoormapResponse';
  status?: Maybe<CommandStatus>;
};

export type EditAccessCard = {
  cardName?: InputMaybe<Scalars['String']>;
  cardnumber?: InputMaybe<Scalars['String']>;
  validityEnd?: InputMaybe<Scalars['DateTime']>;
  validityStart?: InputMaybe<Scalars['DateTime']>;
};

export type EditAccessListUser = {
  accessCards?: InputMaybe<Array<InputMaybe<EditAccessCard>>>;
  accesslistId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type EditAccessListUserResponse = {
  __typename?: 'EditAccessListUserResponse';
  user?: Maybe<User>;
};

export type Environment = {
  __typename?: 'Environment';
  accepted?: Maybe<Scalars['Boolean']>;
  cluster?: Maybe<Cluster>;
  connectionEvents: Array<DoorcontrolConnEvent>;
  errors: Array<EnvironmentError>;
  id: Scalars['ID'];
  lockersystem?: Maybe<Lockersystem>;
  lockersystemId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  offline?: Maybe<Scalars['Boolean']>;
  online?: Maybe<Scalars['Boolean']>;
  passivated?: Maybe<Scalars['Boolean']>;
  pinged?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

export enum EnvironmentAcceptedState {
  Accepted = 'ACCEPTED',
  All = 'ALL',
  Declined = 'DECLINED'
}

export enum EnvironmentConnectionState {
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export type EnvironmentError = {
  __typename?: 'EnvironmentError';
  createdAt?: Maybe<Scalars['DateTime']>;
  environmentId?: Maybe<Scalars['ID']>;
  errorText?: Maybe<Scalars['String']>;
  eventDatetime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type EnvironmentErrorsConnection = {
  __typename?: 'EnvironmentErrorsConnection';
  environmentErrors?: Maybe<Array<Maybe<EnvironmentError>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type EnvironmentGatesConnection = {
  __typename?: 'EnvironmentGatesConnection';
  gates?: Maybe<Array<Maybe<Gate>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type FactoryResetLocker = {
  deviceNumber: Scalars['String'];
  door?: InputMaybe<Scalars['Int']>;
  node?: InputMaybe<Scalars['Int']>;
};

export type FactoryResetLockerResponse = {
  __typename?: 'FactoryResetLockerResponse';
  status?: Maybe<CommandStatus>;
};

export enum Feature {
  Accesslists = 'Accesslists',
  DahsboardLatestStucks = 'DahsboardLatestStucks',
  Dashboard = 'Dashboard',
  DashboardGateStatistics = 'DashboardGateStatistics',
  DashboardGroupsUsage = 'DashboardGroupsUsage',
  DashboardLatestAlarms = 'DashboardLatestAlarms',
  Doormaps = 'Doormaps',
  GateUseHistory = 'GateUseHistory',
  Lainaamo = 'Lainaamo',
  RaportGateGroupStatistics = 'RaportGateGroupStatistics',
  RaportGateStatistics = 'RaportGateStatistics',
  RaportLockerSearch = 'RaportLockerSearch',
  RaportLockerStatistics = 'RaportLockerStatistics',
  Raports = 'Raports',
  UseHistory = 'UseHistory'
}

export type Feedback = {
  __typename?: 'Feedback';
  delivery?: Maybe<Delivery>;
  eventTime?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  locker?: Maybe<Locker>;
  message?: Maybe<Scalars['String']>;
  renting?: Maybe<Renting>;
  type?: Maybe<FeedbackType>;
};

export enum FeedbackType {
  DeliveryDeliverDoorAcidentClosed = 'DeliveryDeliverDoorAcidentClosed',
  DeliveryDeliverDoorDidNotOpen = 'DeliveryDeliverDoorDidNotOpen',
  DeliveryDeliverOtherError = 'DeliveryDeliverOtherError',
  DeliveryPickupDoorAcidentClosed = 'DeliveryPickupDoorAcidentClosed',
  DeliveryPickupDoorDidNotOpen = 'DeliveryPickupDoorDidNotOpen',
  DeliveryPickupFaltyItem = 'DeliveryPickupFaltyItem',
  DeliveryPickupItemMissingFromLocker = 'DeliveryPickupItemMissingFromLocker',
  DeliveryPickupOtherError = 'DeliveryPickupOtherError',
  DeliveryPickupWrongItem = 'DeliveryPickupWrongItem',
  GeneralOtherError = 'GeneralOtherError',
  RentingDuringFaltyItem = 'RentingDuringFaltyItem',
  RentingDuringItemBroke = 'RentingDuringItemBroke',
  RentingDuringItemLost = 'RentingDuringItemLost',
  RentingDuringItemNeedService = 'RentingDuringItemNeedService',
  RentingDuringOtherError = 'RentingDuringOtherError',
  RentingDuringWrongItem = 'RentingDuringWrongItem',
  RentingReturnDoorAcidentClosed = 'RentingReturnDoorAcidentClosed',
  RentingReturnDoorDidNotOpen = 'RentingReturnDoorDidNotOpen',
  RentingReturnOtherError = 'RentingReturnOtherError',
  RentingStartDoorAcidentClosed = 'RentingStartDoorAcidentClosed',
  RentingStartDoorDidNotOpen = 'RentingStartDoorDidNotOpen',
  RentingStartFaltyItem = 'RentingStartFaltyItem',
  RentingStartItemMissingFromLocker = 'RentingStartItemMissingFromLocker',
  RentingStartOtherError = 'RentingStartOtherError',
  RentingStartWrongItem = 'RentingStartWrongItem'
}

export type FeedbacksQuery = {
  lockerGroupId?: InputMaybe<Scalars['ID']>;
  lockerId?: InputMaybe<Scalars['ID']>;
};

export type Freepass = {
  areaId: Scalars['ID'];
  companyId: Scalars['ID'];
  gateId: Scalars['ID'];
  handicap?: InputMaybe<Scalars['Boolean']>;
  nodeId: Scalars['ID'];
};

export type FreepassResponse = {
  __typename?: 'FreepassResponse';
  node?: Maybe<GateNode>;
};

export enum Gate_Sort_Option {
  NodeAsc = 'NODE_ASC',
  NodeDesc = 'NODE_DESC',
  OpenedAsc = 'OPENED_ASC',
  OpenedDesc = 'OPENED_DESC'
}

export type Gate = {
  __typename?: 'Gate';
  dailyCount?: Maybe<Scalars['Int']>;
  device?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  inCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  node?: Maybe<Scalars['String']>;
  outCount?: Maybe<Scalars['Int']>;
  passivated?: Maybe<Scalars['Boolean']>;
};

export type GateEvent = {
  __typename?: 'GateEvent';
  cardNumber?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['DateTime']>;
  eventType?: Maybe<GateEventType>;
  gate?: Maybe<Gate>;
  id: Scalars['ID'];
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
};

export enum GateEventType {
  GateIn = 'GATE_IN',
  GateOut = 'GATE_OUT'
}

export type GateGroup = {
  __typename?: 'GateGroup';
  id?: Maybe<Scalars['ID']>;
  inCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  outCount?: Maybe<Scalars['Int']>;
};

export type GateGroupGatesConnection = {
  __typename?: 'GateGroupGatesConnection';
  gates: Array<Gate>;
  totalCount: Scalars['Int'];
};

export type GateHistoryConnection = {
  __typename?: 'GateHistoryConnection';
  gateEvents: Array<GateEvent>;
  totalCount: Scalars['Int'];
};

export type GateNode = {
  __typename?: 'GateNode';
  allowCloseSales: Scalars['Boolean'];
  allowOpenSales: Scalars['Boolean'];
  areaId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  connectionEndedAt?: Maybe<Scalars['DateTime']>;
  connectionStartedAt?: Maybe<Scalars['DateTime']>;
  counter?: Maybe<Scalars['Int']>;
  deviceNumber?: Maybe<Scalars['String']>;
  gateId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  lastChangedAt?: Maybe<Scalars['DateTime']>;
  lastRealCounterChange?: Maybe<Scalars['DateTime']>;
  nodeId?: Maybe<Scalars['ID']>;
  online?: Maybe<Scalars['Boolean']>;
  open?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
};

export type GateNodeEvent = {
  __typename?: 'GateNodeEvent';
  areaId?: Maybe<Scalars['String']>;
  cardnumber?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  deviceNumber?: Maybe<Scalars['String']>;
  gateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  nodeId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<GateNodeEventType>;
  validationDirection?: Maybe<ValidationDirection>;
  validationErrorCode?: Maybe<Scalars['String']>;
  validationType?: Maybe<ValidationType>;
  version?: Maybe<Scalars['String']>;
};

export enum GateNodeEventType {
  CloseSales = 'CloseSales',
  CloseSalesTransmitted = 'CloseSalesTransmitted',
  Freepass = 'Freepass',
  FreepassTransmitted = 'FreepassTransmitted',
  Handicap = 'Handicap',
  HandicapTransmitted = 'HandicapTransmitted',
  NodeOffline = 'NodeOffline',
  NodeOnline = 'NodeOnline',
  NodeSalesClosed = 'NodeSalesClosed',
  NodeSalesOpened = 'NodeSalesOpened',
  OpenSales = 'OpenSales',
  OpenSalesTransmitted = 'OpenSalesTransmitted',
  Validation = 'Validation'
}

export type GateNodeEventsConnection = {
  __typename?: 'GateNodeEventsConnection';
  gateNodeEvents: Array<GateNodeEvent>;
};

export type GateNodesConnection = {
  __typename?: 'GateNodesConnection';
  gateNodes?: Maybe<Array<Maybe<GateNode>>>;
};

export type GateState = {
  __typename?: 'GateState';
  areaId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  gateId?: Maybe<Scalars['ID']>;
  inCounter?: Maybe<Scalars['Int']>;
  outCounter?: Maybe<Scalars['Int']>;
};

export type GivePermit = {
  rentingId: Scalars['ID'];
};

export type GivePermitResponse = {
  __typename?: 'GivePermitResponse';
  renting: Renting;
};

export type HakaUserCallback = {
  commonName?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  eduPersonPrincipalName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  issuer?: InputMaybe<Scalars['String']>;
  mailAtribute?: InputMaybe<Scalars['String']>;
  nameID?: InputMaybe<Scalars['String']>;
  nameIDFormat?: InputMaybe<Scalars['String']>;
  schacHomeOrganization?: InputMaybe<Scalars['String']>;
  schacHomeOrganizationType?: InputMaybe<Scalars['String']>;
  sessionIndex?: InputMaybe<Scalars['String']>;
};

export type HakaUserCallbackResponse = {
  __typename?: 'HakaUserCallbackResponse';
  code: Scalars['String'];
};

export enum HeartbeatState {
  BootingUp = 'BootingUp',
  NoClock = 'NoClock',
  NoSegment = 'NoSegment',
  Operational = 'Operational',
  PreOperational = 'PreOperational',
  Stopped = 'Stopped'
}

export type InterruptDelivery = {
  deliveryId: Scalars['ID'];
};

export type InterruptDeliveryResponse = {
  __typename?: 'InterruptDeliveryResponse';
  delivery: Delivery;
};

export type InterruptRenting = {
  rentingId: Scalars['ID'];
};

export type InterruptRentingResponse = {
  __typename?: 'InterruptRentingResponse';
  renting: Renting;
};

export type Item = {
  __typename?: 'Item';
  activeRenting?: Maybe<Renting>;
  available?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationCode?: Maybe<Scalars['String']>;
  creationCodeUsedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  emailAdminWhenReturned?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  itemGroup?: Maybe<ItemGroup>;
  itemGroupId?: Maybe<Scalars['ID']>;
  locker?: Maybe<Locker>;
  lockerGroup?: Maybe<LockerGroup>;
  lockersystem?: Maybe<Lockersystem>;
  lockersystemId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  needsPermit?: Maybe<Scalars['Boolean']>;
  removeCode?: Maybe<Scalars['String']>;
  removeCodeUsedAt?: Maybe<Scalars['DateTime']>;
  rentable?: Maybe<Scalars['Boolean']>;
  rentingTime?: Maybe<Scalars['Int']>;
  rentings: Array<Renting>;
  useCreationCodeBefore?: Maybe<Scalars['DateTime']>;
  useRemoveCodeBefore?: Maybe<Scalars['DateTime']>;
  whoCreated?: Maybe<User>;
  whoDeleted?: Maybe<User>;
  whoRemoved?: Maybe<User>;
};


export type ItemRentingsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ItemGroup = {
  __typename?: 'ItemGroup';
  id: Scalars['ID'];
  items: Array<Item>;
  lockersystem: Lockersystem;
  lockersystemId: Scalars['ID'];
  name: Scalars['String'];
};

export type ItemGroupsConnection = {
  __typename?: 'ItemGroupsConnection';
  itemGroups: Array<ItemGroup>;
};

export type ItemsConnection = {
  __typename?: 'ItemsConnection';
  items: Array<Item>;
};

export type JoinUserToLockersystem = {
  lockersystemUrlId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type JoinUserToLockersystemResponse = {
  __typename?: 'JoinUserToLockersystemResponse';
  alreadyJoined: Scalars['Boolean'];
  lockersystem?: Maybe<Lockersystem>;
  user?: Maybe<User>;
};

export enum Locker_Node_Type {
  Doors_1 = 'DOORS_1',
  Doors_2 = 'DOORS_2',
  Doors_4 = 'DOORS_4'
}

export enum Locker_Order_By {
  CardId = 'CARD_ID',
  DoorName = 'DOOR_NAME',
  EventDatetime = 'EVENT_DATETIME',
  Status = 'STATUS'
}

export enum Locker_Statistics_Row_Order_By_Column {
  Alarm = 'ALARM',
  Locked = 'LOCKED',
  Name = 'NAME',
  Opened = 'OPENED',
  Stuck = 'STUCK'
}

export type LainaamoTerminal = {
  __typename?: 'LainaamoTerminal';
  adminMenuEnabled?: Maybe<Scalars['Boolean']>;
  cluster?: Maybe<Cluster>;
  connectionEnd?: Maybe<Scalars['DateTime']>;
  connectionStart?: Maybe<Scalars['DateTime']>;
  deviceNumber: Scalars['String'];
  hide?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastMsgReceivedAt?: Maybe<Scalars['DateTime']>;
  lockersystem?: Maybe<Lockersystem>;
  reportErrors?: Maybe<Scalars['Boolean']>;
  reportOfflines?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
};

export type Locker = {
  __typename?: 'Locker';
  accessGroup?: Maybe<AccessGroup>;
  accessGroupId?: Maybe<Scalars['ID']>;
  alarm?: Maybe<Scalars['Boolean']>;
  cardId?: Maybe<Scalars['String']>;
  cluster?: Maybe<Cluster>;
  delivery?: Maybe<Delivery>;
  depth?: Maybe<Scalars['Int']>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['ID']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  item?: Maybe<Item>;
  lastLockedAt?: Maybe<Scalars['DateTime']>;
  lastOnlineAt?: Maybe<Scalars['DateTime']>;
  locked?: Maybe<Scalars['Boolean']>;
  lockerGroup?: Maybe<LockerGroup>;
  lockerGroupId?: Maybe<Scalars['ID']>;
  lockerNode?: Maybe<LockerNode>;
  lockerNodeId?: Maybe<Scalars['ID']>;
  lockerSeq?: Maybe<Scalars['Int']>;
  lockersystem?: Maybe<Lockersystem>;
  lockersystemId?: Maybe<Scalars['ID']>;
  maintanenceMode?: Maybe<Scalars['Boolean']>;
  mode?: Maybe<LockerMode>;
  name: Scalars['Int'];
  newestEventDate?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  passivated?: Maybe<Scalars['Boolean']>;
  status?: Maybe<LockerStatus>;
  stuck?: Maybe<Scalars['Boolean']>;
  terminal?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
};

export type LockerGroup = {
  __typename?: 'LockerGroup';
  hasLockerGroupAccess?: Maybe<Scalars['Boolean']>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  lainaamo: Scalars['Boolean'];
  lockedCount: Scalars['Int'];
  lockerCount: Scalars['Int'];
  lockerGroupAccess?: Maybe<LockerGroupAccess>;
  lockerUnits: Array<LockerUnit>;
  lockers: Array<Locker>;
  lockersystemId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  openCount: Scalars['Int'];
};


export type LockerGroupHasLockerGroupAccessArgs = {
  accesslistId: Scalars['ID'];
};


export type LockerGroupLockerGroupAccessArgs = {
  accesslistId: Scalars['ID'];
};


export type LockerGroupLockersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type LockerGroupAccess = {
  __typename?: 'LockerGroupAccess';
  accesslistId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  lockerGroup?: Maybe<LockerGroup>;
  lockerGroupId: Scalars['ID'];
  master?: Maybe<Scalars['Boolean']>;
  maxLockedCount?: Maybe<Scalars['Int']>;
};

export type LockerGroupItemsConnection = {
  __typename?: 'LockerGroupItemsConnection';
  items: Array<Item>;
  totalCount: Scalars['Int'];
};

export type LockerGroupsConnection = {
  __typename?: 'LockerGroupsConnection';
  lockerGroups?: Maybe<Array<Maybe<LockerGroup>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LockerHistoryEvent = {
  __typename?: 'LockerHistoryEvent';
  cardId?: Maybe<Scalars['ID']>;
  eventType?: Maybe<LockerHistoryEventType>;
  id: Scalars['ID'];
  locker?: Maybe<Locker>;
  lockerId?: Maybe<Scalars['ID']>;
  lockerSeq?: Maybe<Scalars['Int']>;
  timestamp: Scalars['DateTime'];
  userName?: Maybe<Scalars['String']>;
};

export enum LockerHistoryEventType {
  Alarm = 'ALARM',
  Closed = 'CLOSED',
  MasterClose = 'MASTER_CLOSE',
  MasterOpen = 'MASTER_OPEN',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Open = 'OPEN',
  RemoteOpen = 'REMOTE_OPEN',
  Stuck = 'STUCK',
  StuckOpen = 'STUCK_OPEN'
}

export type LockerHistoryEventsConnection = {
  __typename?: 'LockerHistoryEventsConnection';
  lockerHistoryEvents: Array<LockerHistoryEvent>;
};

export enum LockerMode {
  Renting = 'RENTING',
  Use = 'USE'
}

export type LockerNode = {
  __typename?: 'LockerNode';
  access?: Maybe<Scalars['Boolean']>;
  clusterId?: Maybe<Scalars['ID']>;
  clusterName?: Maybe<Scalars['String']>;
  demo?: Maybe<Scalars['Boolean']>;
  desfire?: Maybe<Scalars['Boolean']>;
  doorNumberLeftDown?: Maybe<Scalars['Int']>;
  doorNumberLeftUp?: Maybe<Scalars['Int']>;
  doorNumberRightDown?: Maybe<Scalars['Int']>;
  doorNumberRightUp?: Maybe<Scalars['Int']>;
  doorNumbers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  groupId?: Maybe<Scalars['ID']>;
  groupName?: Maybe<Scalars['String']>;
  hslDesfire?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  keepLock?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<Scalars['Int']>;
  leftDownLocker?: Maybe<Locker>;
  leftOpen?: Maybe<Scalars['Boolean']>;
  leftUpLocker?: Maybe<Locker>;
  lockerNodeType?: Maybe<Locker_Node_Type>;
  lockerUnitId?: Maybe<Scalars['ID']>;
  lockers?: Maybe<Array<Maybe<Locker>>>;
  mifare?: Maybe<Scalars['Boolean']>;
  mobile?: Maybe<Scalars['Boolean']>;
  multiuse?: Maybe<Scalars['Boolean']>;
  nodeNumber?: Maybe<Scalars['Int']>;
  openMask?: Maybe<Scalars['Int']>;
  personal?: Maybe<Scalars['Boolean']>;
  rightDownLocker?: Maybe<Locker>;
  rightOpen?: Maybe<Scalars['Boolean']>;
  rightUpLocker?: Maybe<Locker>;
  segment: Scalars['Int'];
  silent?: Maybe<Scalars['Boolean']>;
  ultralight?: Maybe<Scalars['Boolean']>;
  verticalDoubleLock?: Maybe<Scalars['Boolean']>;
};

export type LockerNodeUpdateProgress = {
  __typename?: 'LockerNodeUpdateProgress';
  percent?: Maybe<Scalars['Int']>;
};

export type LockerNodesConnection = {
  __typename?: 'LockerNodesConnection';
  lockerNodes?: Maybe<Array<Maybe<LockerNode>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LockerProgram = {
  __typename?: 'LockerProgram';
  applicationId?: Maybe<Scalars['Int']>;
  buildDate?: Maybe<Scalars['Int']>;
  cpuCardType?: Maybe<Scalars['Int']>;
  cpuCardType2?: Maybe<Scalars['Int']>;
  cpuCardType3?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

export type LockerStatisticsOpt = {
  limit?: InputMaybe<Scalars['Int']>;
  lockersystemId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Locker_Statistics_Row_Order_By_Column>;
  orderByDirection?: InputMaybe<Order_By_Direction>;
  untillDate?: InputMaybe<Scalars['DateTime']>;
};

export type LockerStatisticsRow = {
  __typename?: 'LockerStatisticsRow';
  alarmCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  latestOperationDate?: Maybe<Scalars['String']>;
  lockedCount?: Maybe<Scalars['Int']>;
  locker?: Maybe<Locker>;
  lockerName?: Maybe<Scalars['Int']>;
  openedCount?: Maybe<Scalars['Int']>;
  stuckCount?: Maybe<Scalars['Int']>;
};

export type LockerStatisticsRowsConnection = {
  __typename?: 'LockerStatisticsRowsConnection';
  lockerStatisticsRows?: Maybe<Array<Maybe<LockerStatisticsRow>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum LockerStatus {
  Alarm = 'ALARM',
  Locked = 'LOCKED',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Open = 'OPEN',
  Stuck = 'STUCK'
}

export type LockerUnit = {
  __typename?: 'LockerUnit';
  id?: Maybe<Scalars['ID']>;
  lockerNodes: Array<LockerNode>;
};

export type LockerhistoryEventsOpt = {
  cardnumber?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  eventTypes?: InputMaybe<Array<InputMaybe<LockerHistoryEventType>>>;
  limit?: InputMaybe<Scalars['Int']>;
  lockerGroupName?: InputMaybe<Scalars['String']>;
  lockerId?: InputMaybe<Scalars['ID']>;
  lockerNumber?: InputMaybe<Scalars['Int']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type LockersConnection = {
  __typename?: 'LockersConnection';
  lockers?: Maybe<Array<Maybe<Locker>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LockersOpt = {
  lockersystemId: Scalars['ID'];
  stateChangedBefore?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<LockerStatus>;
};

export type Lockersystem = {
  __typename?: 'Lockersystem';
  accessGroups: Array<AccessGroup>;
  accesslists?: Maybe<Array<Maybe<Accesslist>>>;
  activeDeliveries: LockersystemActiveDeliveriesConnection;
  activeRentings: LockersystemActiveRentingsConnection;
  alarmsTodayCount: Scalars['Int'];
  clusters: Array<Cluster>;
  companyId?: Maybe<Scalars['ID']>;
  deliveryAllowed?: Maybe<Scalars['Boolean']>;
  deliveryNonRegistered?: Maybe<Scalars['Boolean']>;
  doormapCsv?: Maybe<Scalars['String']>;
  emailPermissionDomains: Array<Scalars['String']>;
  environments?: Maybe<Array<Maybe<Environment>>>;
  googleUsersAllowed?: Maybe<Scalars['Boolean']>;
  hasAccesslist?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  itemGroups: Array<ItemGroup>;
  items: LockersystemItemsConnection;
  lainaamoTranslations: Array<TranslationItem>;
  lockedLockersCount: Scalars['Int'];
  lockerGroups: Array<LockerGroup>;
  lockerNodes: Array<LockerNode>;
  lockerUnits: Array<LockerUnit>;
  lockerhistory: Array<LockerHistoryEvent>;
  lockers: Array<Locker>;
  lockersWithoutAccessGroup: Array<Locker>;
  lockersWithoutLockerGroup: Array<Locker>;
  maintenanceMode?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  openLockersCount: Scalars['Int'];
  openSystem?: Maybe<Scalars['Boolean']>;
  ouluUniUsersAllowed?: Maybe<Scalars['Boolean']>;
  passivated?: Maybe<Scalars['Boolean']>;
  rentingAllowed?: Maybe<Scalars['Boolean']>;
  rentingNonRegistered?: Maybe<Scalars['Boolean']>;
  setupDownloadUrl?: Maybe<Scalars['String']>;
  showWhoBorrows?: Maybe<Scalars['Boolean']>;
  totalLockersCount: Scalars['Int'];
  translations: Array<Translation>;
  urlId?: Maybe<Scalars['String']>;
  userPermissions: Array<LockersystemUserPermission>;
};


export type LockersystemHasAccesslistArgs = {
  accesslistId: Scalars['ID'];
};


export type LockersystemItemGroupsArgs = {
  searchWord?: InputMaybe<Scalars['String']>;
};


export type LockersystemItemsArgs = {
  options: LockersystemItemsSearchOptions;
};


export type LockersystemLockerGroupsArgs = {
  lainaamo?: InputMaybe<Scalars['Boolean']>;
  showHidden?: InputMaybe<Scalars['Boolean']>;
};


export type LockersystemLockerhistoryArgs = {
  opt: LockersystemLockerhistoryOpt;
};

export type LockersystemActiveDeliveriesConnection = {
  __typename?: 'LockersystemActiveDeliveriesConnection';
  deliveries: Array<Delivery>;
};

export type LockersystemActiveRentingsConnection = {
  __typename?: 'LockersystemActiveRentingsConnection';
  rentings: Array<Renting>;
};

export type LockersystemClustersConnection = {
  __typename?: 'LockersystemClustersConnection';
  clusters?: Maybe<Array<Maybe<Cluster>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LockersystemGatesConnection = {
  __typename?: 'LockersystemGatesConnection';
  gates?: Maybe<Array<Maybe<Gate>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LockersystemItemsConnection = {
  __typename?: 'LockersystemItemsConnection';
  items: Array<Item>;
};

export type LockersystemItemsSearchOptions = {
  rentable?: InputMaybe<Scalars['Boolean']>;
  searchWord?: InputMaybe<Scalars['String']>;
};

export type LockersystemLockerhistoryOpt = {
  cardnumber?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  eventTypes?: InputMaybe<Array<InputMaybe<LockerHistoryEventType>>>;
  limit?: InputMaybe<Scalars['Int']>;
  lockerNumber?: InputMaybe<Scalars['Int']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type LockersystemSubscription = {
  __typename?: 'LockersystemSubscription';
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['String']>;
};

export type LockersystemUserPermission = {
  __typename?: 'LockersystemUserPermission';
  id?: Maybe<Scalars['ID']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  lockersystem?: Maybe<Lockersystem>;
  lockersystemId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type LockersystemsConnection = {
  __typename?: 'LockersystemsConnection';
  lockersystems?: Maybe<Array<Maybe<Lockersystem>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token?: Maybe<Scalars['String']>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  success?: Maybe<Scalars['Boolean']>;
  viewer?: Maybe<Viewer>;
};

export type MakeItemRentableResponse = {
  __typename?: 'MakeItemRentableResponse';
  item: Item;
  locker: Locker;
  success: Scalars['Boolean'];
};

export enum MessageDirection {
  Recv = 'Recv',
  Send = 'Send'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptEnvironment: AcceptEnvironmentResponse;
  activateGate?: Maybe<Scalars['Boolean']>;
  addAccessGroupAccess: AddAccessGroupAccessResponse;
  addGateToGateGroup: AddGateToGateGroupResponse;
  addLockersToAccessGroup?: Maybe<AddLockersToAccessGroupResponse>;
  addLockersToLockerGroup: AddLockersToLockerGroupResponse;
  addLockersystemPermissionForUser: AddLockersystemPermissionForUserResponse;
  addPageView: Scalars['Boolean'];
  addUserLockerPermission: AddUserLockerPermissionResponse;
  addUserToAccesslist: AddUserToAccesslistResponse;
  allowRenting: AllowRentingResponse;
  blockDoorcontrol: BlockDoorcontrolResponse;
  calibrateLockers: CalibrateLockersResponse;
  cancelRenting: CancelRentingResponse;
  changeEnvironmentSequenceNumber: ChangeEnvironmentSequenceNumberResponse;
  changeUserDefaultLanguage?: Maybe<ChangeLanguageResponse>;
  clearLockersystemHistory: ClearLockersystemHistoryResponse;
  clearLockersystemState: ClearLockersystemStateResponse;
  closeSales: CloseSalesResponse;
  connectDoorcontrolToLockersystem: ConnectDoorcontrolToLockersystemResponse;
  createAccessGroup: CreateAccessGroupResponse;
  createAccesslist: CreateAccesslistResponse;
  createCardKeys: CreateCardKeysResponse;
  createGateGroup: CreateGateGroupResponse;
  createItem: CreateItemResponse;
  createItemGroup: CreateItemGroupResponse;
  createLockerGroup: CreateLockerGroupResponse;
  createLockerNode: CreateLockerNodeResponse;
  createLockersystem: CreateLockersystemResponse;
  createResetToken?: Maybe<CreateResetTokenResponse>;
  createToken: CreateTokenResponse;
  createTokenFromPortalOauthCode: CreateTokenFromPortalOauthCodeResponse;
  createUser: CreateUserResponse;
  declineDoorcontrol: DeclineDoorcontrolResponse;
  declineEnvironment?: Maybe<DeclineEnvironmentResponse>;
  declinePermit: DeclinePermitResponse;
  deleteAccessCardUser: DeleteAccessCardUserResponse;
  deleteCardKeys: DeleteCardKeysResponse;
  deleteDoorcontrol: DeleteDoorcontrolResponse;
  deleteItem: DeleteItemResponse;
  deleteItemGroup: DeleteItemGroupResponse;
  deleteLockerProgram: DeleteLockerProgramResponse;
  deliverItemToUser: DeliverItemToUserResponse;
  disconnectDoorcontrol: DisconnectDoorcontrolResponse;
  disconnectLainaamoTerminal: DisconnectLainaamoTerminalResponse;
  editAccessListUser: EditAccessListUserResponse;
  factoryResetLocker: FactoryResetLockerResponse;
  freepass: FreepassResponse;
  generateUserApiToken?: Maybe<ApiKey>;
  givePermit: GivePermitResponse;
  hakaUserCallback: HakaUserCallbackResponse;
  interruptDelivery: InterruptDeliveryResponse;
  interruptRenting: InterruptRentingResponse;
  joinUserToLockersystem: JoinUserToLockersystemResponse;
  login?: Maybe<LoginResponse>;
  openLocker: OpenLockerResponse;
  openSales: OpenSalesResponse;
  passivateEnvironment: PassivateEnvironmentResponse;
  passivateGate?: Maybe<Scalars['Boolean']>;
  passivateLockersystem: PassivateLockersystemResponse;
  passivateUser?: Maybe<PassivateUserResponse>;
  pickupDeliveredItem: PickupDeliveredItemResponse;
  placeItemToLocker: PlaceItemToLockerResponse;
  registerFcm: RegisterFcmResponse;
  registerPhoneWithGoogleIdToken: RegisterPhoneWithGoogleTokenResponse;
  registerPhoneWithSamlAuthCode: RegisterPhoneWithSamlAuthCodeResponse;
  remoteOpenLocker: RemoteOpenLockerResponse;
  removeAccessGroup: RemoveAccessGroupResponse;
  removeAccessGroupAccess: RemoveAccessGroupAccessResponse;
  removeAccesslist: RemoveAccesslistResponse;
  removeCardFromAccesslist: RemoveCardFromAccesslistResponse;
  removeCompanyAreas: Scalars['Boolean'];
  removeCompanyGateEvents: Scalars['Boolean'];
  removeGateEntity: RemoveGateEntityResponse;
  removeGateFromGateGroup: RemoveGateFromGateGroupResponse;
  removeGateGroup: RemoveGateGroupResponse;
  removeItemFromLocker: RemoveItemFromLockerResponse;
  removeLockerFromAccessGroup?: Maybe<Scalars['Boolean']>;
  removeLockerGroup: RemoveLockerGroupResponse;
  removeLockerNode: RemoveLockerNodeResponse;
  removeLockersFromLockerGroup: RemoveLockersFromLockerGroupResponse;
  removeLockersystemPermission: RemoveLockersystemPermissionResponse;
  removeTranslation: RemoveTranslationResponse;
  removeUser?: Maybe<RemoveUserResponse>;
  removeUserApiToken?: Maybe<Scalars['Boolean']>;
  removeUserLockerPermission: RemoveUserLockerPermissionResponse;
  rentItem: RentItemResponse;
  requestLockerHistory: Scalars['Boolean'];
  requestLockerStatus: Scalars['Boolean'];
  resetGateCounters: Scalars['Boolean'];
  resetUserPassword: ResetUserPasswordResponse;
  returnRentedItem: ReturnRentedItemResponse;
  sendCardKeys: SendCardKeysResponse;
  sendDoormapToLockerNodes: SendDoormapToLockerNodesResponse;
  sendDoormapToLockers: SendDoormapToLockersResponse;
  sendFeedback: SendFeedbackResponse;
  sendMasterPincode: Scalars['Boolean'];
  setGateName?: Maybe<Scalars['Boolean']>;
  setupLockersystem: SetupLockersystemResponse;
  unblockDoorcontrol: UnblockDoorcontrolResponse;
  updateAccessGroup: UpdateAccessGroupResponse;
  updateAccessGroupAccess: UpdateAccessGroupAccessResponse;
  updateAccesslist: UpdateAccesslistResponse;
  updateDelivery: UpdateDeliveryResponse;
  updateDoorcontrol: UpdateDoorcontrolResponse;
  updateDoormap: UpdateDoormapResponse;
  updateGateNode: UpdateGateNodeResponse;
  updateItem: UpdateItemResponse;
  updateItemGroup: UpdateItemGroupResponse;
  updateLainaamoTerminal: UpdateLainaamoTerminalResponse;
  updateLocker: UpdateLockerResponse;
  updateLockerGroup: UpdateLockerGroupResponse;
  updateLockerNode: UpdateLockerNodeResponse;
  updateLockerProgram: UpdateLockerProgramResponse;
  updateLockers: UpdateLockersResponse;
  updateLockersystem: UpdateLockersystemResponse;
  updateLockersystemAccessGroups: UpdateLockersystemAccessGroupsResponse;
  updateLockersystemLockerGroups: UpdateLockersystemLockerGroupsResponse;
  updateLockersystemPermission: UpdateLockersystemPermissionResponse;
  updateRenting: UpdateRentingResponse;
  updateTranslation: UpdateTranslationResponse;
  updateUser: UpdateUserResponse;
  uploadDoormap: UploadDoormapResponse;
  uploadLockerProgram: UploadLockerProgramResponse;
};


export type MutationAcceptEnvironmentArgs = {
  input: AcceptEnvironment;
};


export type MutationActivateGateArgs = {
  gateId: Scalars['ID'];
};


export type MutationAddAccessGroupAccessArgs = {
  input: AddAccessGroupAccess;
};


export type MutationAddGateToGateGroupArgs = {
  gateGroupId: Scalars['ID'];
  gateId: Scalars['ID'];
  position: Scalars['Int'];
};


export type MutationAddLockersToAccessGroupArgs = {
  accessGroupName: Scalars['String'];
  endLockerNumber?: InputMaybe<Scalars['Int']>;
  lockers?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  startLockerNumber?: InputMaybe<Scalars['Int']>;
};


export type MutationAddLockersToLockerGroupArgs = {
  input: AddLockersToLockerGroup;
};


export type MutationAddLockersystemPermissionForUserArgs = {
  input: AddLockersystemPermissionForUser;
};


export type MutationAddPageViewArgs = {
  url: Scalars['String'];
};


export type MutationAddUserLockerPermissionArgs = {
  input: AddUserLockerPermission;
};


export type MutationAddUserToAccesslistArgs = {
  input: AddUserToAccesslist;
};


export type MutationAllowRentingArgs = {
  input: AllowRenting;
};


export type MutationBlockDoorcontrolArgs = {
  input: BlockDoorcontrol;
};


export type MutationCalibrateLockersArgs = {
  input: CalibrateLockers;
};


export type MutationCancelRentingArgs = {
  input: CancelRenting;
};


export type MutationChangeEnvironmentSequenceNumberArgs = {
  input: ChangeEnvironmentSequenceNumber;
};


export type MutationChangeUserDefaultLanguageArgs = {
  input: ChangeLanguage;
};


export type MutationClearLockersystemHistoryArgs = {
  input?: InputMaybe<ClearLockersystemHistory>;
};


export type MutationClearLockersystemStateArgs = {
  input?: InputMaybe<ClearLockersystemState>;
};


export type MutationCloseSalesArgs = {
  input: CloseSales;
};


export type MutationConnectDoorcontrolToLockersystemArgs = {
  input: ConnectDoorcontrolToLockersystem;
};


export type MutationCreateAccessGroupArgs = {
  input: CreateAccessGroup;
};


export type MutationCreateAccesslistArgs = {
  input: CreateAccesslist;
};


export type MutationCreateCardKeysArgs = {
  input: CreateCardKeys;
};


export type MutationCreateGateGroupArgs = {
  gateGroupName: Scalars['String'];
};


export type MutationCreateItemArgs = {
  input: CreateItem;
};


export type MutationCreateItemGroupArgs = {
  input: CreateItemGroup;
};


export type MutationCreateLockerGroupArgs = {
  input: CreateLockerGroup;
};


export type MutationCreateLockerNodeArgs = {
  input: CreateLockerNode;
};


export type MutationCreateLockersystemArgs = {
  input: CreateLockersystem;
};


export type MutationCreateResetTokenArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateTokenArgs = {
  apiKey: Scalars['String'];
};


export type MutationCreateTokenFromPortalOauthCodeArgs = {
  input: CreateTokenFromPortalOauthCode;
};


export type MutationCreateUserArgs = {
  input: CreateUser;
};


export type MutationDeclineDoorcontrolArgs = {
  input: DeclineDoorcontrol;
};


export type MutationDeclineEnvironmentArgs = {
  input: DeclineEnvironment;
};


export type MutationDeclinePermitArgs = {
  input: DeclinePermit;
};


export type MutationDeleteAccessCardUserArgs = {
  input: DeleteAccessCardUser;
};


export type MutationDeleteCardKeysArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDoorcontrolArgs = {
  input: DeleteDoorcontrol;
};


export type MutationDeleteItemArgs = {
  input: DeleteItem;
};


export type MutationDeleteItemGroupArgs = {
  input: DeleteItemGroup;
};


export type MutationDeleteLockerProgramArgs = {
  input: DeleteLockerProgram;
};


export type MutationDeliverItemToUserArgs = {
  input?: InputMaybe<DeliverItemToUser>;
};


export type MutationDisconnectDoorcontrolArgs = {
  input: DisconnectDoorcontrol;
};


export type MutationDisconnectLainaamoTerminalArgs = {
  input: DisconnectLainaamoTerminal;
};


export type MutationEditAccessListUserArgs = {
  input: EditAccessListUser;
};


export type MutationFactoryResetLockerArgs = {
  input: FactoryResetLocker;
};


export type MutationFreepassArgs = {
  input: Freepass;
};


export type MutationGenerateUserApiTokenArgs = {
  userId: Scalars['ID'];
};


export type MutationGivePermitArgs = {
  input: GivePermit;
};


export type MutationHakaUserCallbackArgs = {
  input: HakaUserCallback;
};


export type MutationInterruptDeliveryArgs = {
  input: InterruptDelivery;
};


export type MutationInterruptRentingArgs = {
  input: InterruptRenting;
};


export type MutationJoinUserToLockersystemArgs = {
  input: JoinUserToLockersystem;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  userName: Scalars['String'];
};


export type MutationOpenLockerArgs = {
  input: OpenLocker;
};


export type MutationOpenSalesArgs = {
  input: OpenSales;
};


export type MutationPassivateEnvironmentArgs = {
  input: PassivateEnvironment;
};


export type MutationPassivateGateArgs = {
  gateId: Scalars['ID'];
};


export type MutationPassivateLockersystemArgs = {
  input: PassivateLockersystem;
};


export type MutationPassivateUserArgs = {
  input: PassivateUser;
};


export type MutationPickupDeliveredItemArgs = {
  input: PickupDeliveredItem;
};


export type MutationPlaceItemToLockerArgs = {
  input: PlaceItemToLocker;
};


export type MutationRegisterFcmArgs = {
  token: Scalars['String'];
};


export type MutationRegisterPhoneWithGoogleIdTokenArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  googleIdToken: Scalars['String'];
  model: Scalars['String'];
  os: PhoneOs;
  versionCode?: InputMaybe<Scalars['Int']>;
  versionName?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterPhoneWithSamlAuthCodeArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  model: Scalars['String'];
  os: PhoneOs;
  samlAuthCode: Scalars['String'];
  versionCode?: InputMaybe<Scalars['Int']>;
  versionName?: InputMaybe<Scalars['String']>;
};


export type MutationRemoteOpenLockerArgs = {
  input: RemoteOpenLocker;
};


export type MutationRemoveAccessGroupArgs = {
  input: RemoveAccessGroup;
};


export type MutationRemoveAccessGroupAccessArgs = {
  input: RemoveAccessGroupAccess;
};


export type MutationRemoveAccesslistArgs = {
  input: RemoveAccesslist;
};


export type MutationRemoveCardFromAccesslistArgs = {
  input: RemoveCardFromAccesslist;
};


export type MutationRemoveCompanyAreasArgs = {
  input: RemoveCompanyAreas;
};


export type MutationRemoveCompanyGateEventsArgs = {
  input: RemoveCompanyGateEvents;
};


export type MutationRemoveGateEntityArgs = {
  input: RemoveGateEntity;
};


export type MutationRemoveGateFromGateGroupArgs = {
  gateGroupId: Scalars['ID'];
  gateId: Scalars['ID'];
};


export type MutationRemoveGateGroupArgs = {
  gateGroupId: Scalars['ID'];
};


export type MutationRemoveItemFromLockerArgs = {
  input: RemoveItemFromLocker;
};


export type MutationRemoveLockerFromAccessGroupArgs = {
  accessGroupName: Scalars['String'];
  lockerNumber: Scalars['Int'];
};


export type MutationRemoveLockerGroupArgs = {
  input: RemoveLockerGroup;
};


export type MutationRemoveLockerNodeArgs = {
  input: RemoveLockerNode;
};


export type MutationRemoveLockersFromLockerGroupArgs = {
  input: RemoveLockersFromLockerGroup;
};


export type MutationRemoveLockersystemPermissionArgs = {
  input: RemoveLockersystemPermission;
};


export type MutationRemoveTranslationArgs = {
  input: RemoveTranslation;
};


export type MutationRemoveUserArgs = {
  input: RemoveUser;
};


export type MutationRemoveUserApiTokenArgs = {
  apiToken: Scalars['String'];
};


export type MutationRemoveUserLockerPermissionArgs = {
  input: RemoveUserLockerPermission;
};


export type MutationRentItemArgs = {
  input: RentItem;
};


export type MutationRequestLockerHistoryArgs = {
  input: RequestLockerHistory;
};


export type MutationRequestLockerStatusArgs = {
  input?: InputMaybe<RequestLockerStatus>;
};


export type MutationResetGateCountersArgs = {
  input: ResetGateCounters;
};


export type MutationResetUserPasswordArgs = {
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
};


export type MutationReturnRentedItemArgs = {
  input: ReturnRentedItem;
};


export type MutationSendCardKeysArgs = {
  input: SendCardKeys;
};


export type MutationSendDoormapToLockerNodesArgs = {
  input: SendDoormapToLockerNodes;
};


export type MutationSendDoormapToLockersArgs = {
  input: SendDoormapToLockers;
};


export type MutationSendFeedbackArgs = {
  input: SendFeedback;
};


export type MutationSendMasterPincodeArgs = {
  input: SendMasterPincode;
};


export type MutationSetGateNameArgs = {
  gateId: Scalars['ID'];
  gateName: Scalars['String'];
};


export type MutationSetupLockersystemArgs = {
  input: SetupLockersystem;
};


export type MutationUnblockDoorcontrolArgs = {
  input: UnblockDoorcontrol;
};


export type MutationUpdateAccessGroupArgs = {
  input: UpdateAccessGroup;
};


export type MutationUpdateAccessGroupAccessArgs = {
  input: UpdateAccessGroupAccess;
};


export type MutationUpdateAccesslistArgs = {
  input: UpdateAccesslist;
};


export type MutationUpdateDeliveryArgs = {
  input: UpdateDelivery;
};


export type MutationUpdateDoorcontrolArgs = {
  input: UpdateDoorcontrol;
};


export type MutationUpdateDoormapArgs = {
  input: UpdateDoormap;
};


export type MutationUpdateGateNodeArgs = {
  input: UpdateGateNode;
};


export type MutationUpdateItemArgs = {
  input: UpdateItem;
};


export type MutationUpdateItemGroupArgs = {
  input: UpdateItemGroup;
};


export type MutationUpdateLainaamoTerminalArgs = {
  input: UpdateLainaamoTerminal;
};


export type MutationUpdateLockerArgs = {
  input: UpdateLocker;
};


export type MutationUpdateLockerGroupArgs = {
  input: UpdateLockerGroup;
};


export type MutationUpdateLockerNodeArgs = {
  input: UpdateLockerNode;
};


export type MutationUpdateLockerProgramArgs = {
  input: UpdateLockerProgram;
};


export type MutationUpdateLockersArgs = {
  input: UpdateLockers;
};


export type MutationUpdateLockersystemArgs = {
  input: UpdateLockersystem;
};


export type MutationUpdateLockersystemAccessGroupsArgs = {
  input: UpdateLockersystemAccessGroups;
};


export type MutationUpdateLockersystemLockerGroupsArgs = {
  input: UpdateLockersystemLockerGroups;
};


export type MutationUpdateLockersystemPermissionArgs = {
  input: UpdateLockersystemPermission;
};


export type MutationUpdateRentingArgs = {
  input: UpdateRenting;
};


export type MutationUpdateTranslationArgs = {
  input: UpdateTranslation;
};


export type MutationUpdateUserArgs = {
  input: UpdateUser;
};


export type MutationUploadDoormapArgs = {
  input: UploadDoormap;
};


export type MutationUploadLockerProgramArgs = {
  input: UploadLockerProgram;
};

export type NewAccessGroup = {
  name: Scalars['String'];
};

export type NewLockerGroup = {
  name: Scalars['String'];
  tempId: Scalars['ID'];
};

export type NodeConnection = {
  __typename?: 'NodeConnection';
  areaId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  gateId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  nodeId?: Maybe<Scalars['ID']>;
};

export enum Order_By_Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Order_Direction {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type OpenLocker = {
  lockerId: Scalars['ID'];
};

export type OpenLockerResponse = {
  __typename?: 'OpenLockerResponse';
  locker: Locker;
  openCode: Scalars['String'];
};

export type OpenSales = {
  areaId?: InputMaybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  gateId?: InputMaybe<Scalars['ID']>;
  nodeId?: InputMaybe<Scalars['ID']>;
  openingMode?: InputMaybe<Scalars['String']>;
  saleperson?: InputMaybe<Scalars['String']>;
  saleplace?: InputMaybe<Scalars['String']>;
};

export type OpenSalesResponse = {
  __typename?: 'OpenSalesResponse';
  affectedNodes?: Maybe<Scalars['Int']>;
  nodes: Array<GateNode>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PageViewStatRow = {
  __typename?: 'PageViewStatRow';
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  useTimes?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type PageViewStatRowsConnection = {
  __typename?: 'PageViewStatRowsConnection';
  pageViewStatRows?: Maybe<Array<Maybe<PageViewStatRow>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PassivateEnvironment = {
  environmentId: Scalars['ID'];
};

export type PassivateEnvironmentResponse = {
  __typename?: 'PassivateEnvironmentResponse';
  environment: Environment;
};

export type PassivateLockersystem = {
  lockersystemId: Scalars['ID'];
};

export type PassivateLockersystemResponse = {
  __typename?: 'PassivateLockersystemResponse';
  lockersystem?: Maybe<Lockersystem>;
};

export type PassivateUser = {
  userId: Scalars['ID'];
};

export type PassivateUserResponse = {
  __typename?: 'PassivateUserResponse';
  user?: Maybe<User>;
};

export enum PassivatedState {
  All = 'ALL',
  NotPassivated = 'NOT_PASSIVATED',
  Passivated = 'PASSIVATED'
}

export type Phone = {
  __typename?: 'Phone';
  id?: Maybe<Scalars['ID']>;
  lockedLockerNumbers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lockedLockers?: Maybe<Array<Maybe<Locker>>>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<PhoneOs>;
};

export enum PhoneOs {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type PhoneViewer = {
  __typename?: 'PhoneViewer';
  phone?: Maybe<Phone>;
  user?: Maybe<User>;
};

export type PickupDeliveredItem = {
  deliveryId: Scalars['ID'];
};

export type PickupDeliveredItemResponse = {
  __typename?: 'PickupDeliveredItemResponse';
  code: Scalars['String'];
  delivery: Delivery;
  locker: Locker;
};

export type PlaceItemToLocker = {
  itemId: Scalars['ID'];
  lockerId: Scalars['ID'];
};

export type PlaceItemToLockerResponse = {
  __typename?: 'PlaceItemToLockerResponse';
  item: Item;
};

export type Query = {
  __typename?: 'Query';
  accessGroup?: Maybe<AccessGroup>;
  accessGroupLockers: AccessGroupLockersConnection;
  accessGroups: AccessGroupsConnection;
  accesslist?: Maybe<Accesslist>;
  accesslistChanges: Array<AccesslistChange>;
  accesslistUsers: Array<User>;
  accesslists: AccesslistsConnection;
  allAccessGroups: AllAccessGroupsConnection;
  cardKeys: Array<CardKey>;
  cardLockedLockers: Array<Locker>;
  deliveries: DeliveriesConnection;
  delivery?: Maybe<Delivery>;
  doorNotes: Array<DoorNote>;
  doorcontrol?: Maybe<Doorcontrol>;
  doorcontrolEventsSearch: DoorcontrolEventsConnection;
  doorcontrols: DoorcontrolsConnection;
  environment?: Maybe<Environment>;
  environmentErrors?: Maybe<EnvironmentErrorsConnection>;
  environmentGates?: Maybe<EnvironmentGatesConnection>;
  environmentLockers?: Maybe<Array<Maybe<Locker>>>;
  environmentNodes?: Maybe<Array<Maybe<LockerNode>>>;
  gateGroup?: Maybe<GateGroup>;
  gateGroupGates: GateGroupGatesConnection;
  gateNode?: Maybe<GateNode>;
  gateNodeEvents: GateNodeEventsConnection;
  gateNodes: GateNodesConnection;
  gateSystems: Array<CompanyGateState>;
  item?: Maybe<Item>;
  itemGroup?: Maybe<ItemGroup>;
  itemGroups: ItemGroupsConnection;
  items: ItemsConnection;
  lainaamoTerminal?: Maybe<LainaamoTerminal>;
  lainaamoTerminals: Array<LainaamoTerminal>;
  lockedLockers: Array<Locker>;
  locker?: Maybe<Locker>;
  lockerGroup?: Maybe<LockerGroup>;
  lockerGroupItems: LockerGroupItemsConnection;
  lockerHistoryEvents: LockerHistoryEventsConnection;
  lockerProgram?: Maybe<LockerProgram>;
  lockerPrograms: Array<LockerProgram>;
  lockerStatisticsRows?: Maybe<LockerStatisticsRowsConnection>;
  lockerUserPermissions?: Maybe<Array<Maybe<UserLockerPermission>>>;
  lockers?: Maybe<LockersConnection>;
  lockersystem?: Maybe<Lockersystem>;
  lockersystemAccesslistUsers: Array<User>;
  lockersystemClusters?: Maybe<LockersystemClustersConnection>;
  lockersystemGateOpenings?: Maybe<Scalars['Int']>;
  lockersystemGates?: Maybe<LockersystemGatesConnection>;
  lockersystemLockerNodes?: Maybe<LockerNodesConnection>;
  lockersystemSubscriptions: Array<LockersystemSubscription>;
  nodeConnections: Array<NodeConnection>;
  offlineLockers: Array<Locker>;
  pageViewStatRows?: Maybe<PageViewStatRowsConnection>;
  phone?: Maybe<Phone>;
  phoneViewer: PhoneViewer;
  renting?: Maybe<Renting>;
  rentingStatistics: Array<RentingStatisticsRow>;
  rentings: RentingsConnection;
  searchEnvironments: SearchEnvironmentsConnection;
  searchFeedbacks?: Maybe<SearchFeedbacksConnection>;
  searchGateHistory?: Maybe<GateHistoryConnection>;
  searchLockersystems: SearchLockersystemsConnection;
  user?: Maybe<User>;
  userApiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  users: UsersConnection;
  viewer?: Maybe<Viewer>;
  visitorCounts: VisitorQueryResult;
};


export type QueryAccessGroupArgs = {
  accessGroupId: Scalars['ID'];
};


export type QueryAccessGroupLockersArgs = {
  accessGroupName: Scalars['String'];
};


export type QueryAccesslistArgs = {
  accesslistId: Scalars['ID'];
};


export type QueryAccesslistChangesArgs = {
  accesslistId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAccesslistUsersArgs = {
  accesslistId: Scalars['ID'];
  cardnumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
};


export type QueryAccesslistsArgs = {
  hidden?: InputMaybe<Scalars['Boolean']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
};


export type QueryAllAccessGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryCardLockedLockersArgs = {
  cardnumber: Scalars['String'];
  lockersystemId?: InputMaybe<Scalars['ID']>;
};


export type QueryDeliveriesArgs = {
  deliveryUser?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  lockerNumber?: InputMaybe<Scalars['Int']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  pickupUser?: InputMaybe<Scalars['String']>;
  skipInvalid?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
};


export type QueryDeliveryArgs = {
  deliveryId: Scalars['ID'];
};


export type QueryDoorNotesArgs = {
  query?: InputMaybe<DoorNotesQuery>;
};


export type QueryDoorcontrolArgs = {
  deviceNumber: Scalars['ID'];
};


export type QueryDoorcontrolEventsSearchArgs = {
  input?: InputMaybe<DoorcontrolEventsQuery>;
};


export type QueryEnvironmentArgs = {
  environmentId: Scalars['ID'];
};


export type QueryEnvironmentErrorsArgs = {
  environmentId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryEnvironmentGatesArgs = {
  environmentId: Scalars['ID'];
};


export type QueryEnvironmentLockersArgs = {
  all?: InputMaybe<Scalars['Boolean']>;
  environmentId: Scalars['ID'];
};


export type QueryEnvironmentNodesArgs = {
  environmentId: Scalars['ID'];
};


export type QueryGateGroupArgs = {
  gateGroupId: Scalars['ID'];
};


export type QueryGateGroupGatesArgs = {
  gateGroupId: Scalars['ID'];
};


export type QueryGateNodeArgs = {
  gateId: Scalars['ID'];
};


export type QueryGateNodeEventsArgs = {
  areaId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  deviceNumber?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventType?: InputMaybe<GateNodeEventType>;
  gateId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  nodeId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGateNodesArgs = {
  areaId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  deviceNumber?: InputMaybe<Scalars['String']>;
  gateId?: InputMaybe<Scalars['ID']>;
  nodeId?: InputMaybe<Scalars['ID']>;
};


export type QueryItemArgs = {
  itemId: Scalars['ID'];
};


export type QueryItemGroupArgs = {
  itemGroupId: Scalars['ID'];
};


export type QueryItemGroupsArgs = {
  opt?: InputMaybe<SearchItemGroupsOptions>;
};


export type QueryItemsArgs = {
  opt?: InputMaybe<SearchItemsOptions>;
};


export type QueryLainaamoTerminalArgs = {
  deviceNumber: Scalars['ID'];
};


export type QueryLockedLockersArgs = {
  cardnumber: Scalars['String'];
};


export type QueryLockerArgs = {
  lockerId?: InputMaybe<Scalars['ID']>;
};


export type QueryLockerGroupArgs = {
  lockerGroupId: Scalars['ID'];
};


export type QueryLockerGroupItemsArgs = {
  lockerGroupId: Scalars['ID'];
};


export type QueryLockerHistoryEventsArgs = {
  opt?: InputMaybe<LockerhistoryEventsOpt>;
};


export type QueryLockerProgramArgs = {
  lockerProgramId: Scalars['ID'];
};


export type QueryLockerStatisticsRowsArgs = {
  opt: LockerStatisticsOpt;
};


export type QueryLockerUserPermissionsArgs = {
  lockerId: Scalars['ID'];
};


export type QueryLockersArgs = {
  opt?: InputMaybe<LockersOpt>;
};


export type QueryLockersystemArgs = {
  lockersystemId?: InputMaybe<Scalars['ID']>;
};


export type QueryLockersystemAccesslistUsersArgs = {
  lockersystemId: Scalars['ID'];
};


export type QueryLockersystemClustersArgs = {
  clusterState?: InputMaybe<ClusterState>;
  lockersystemId: Scalars['ID'];
};


export type QueryLockersystemGateOpeningsArgs = {
  lockersystemId: Scalars['ID'];
};


export type QueryLockersystemGatesArgs = {
  lockersystemId: Scalars['ID'];
  passivated?: InputMaybe<Scalars['Boolean']>;
  sortOption?: InputMaybe<Gate_Sort_Option>;
};


export type QueryLockersystemLockerNodesArgs = {
  lockersystemId: Scalars['ID'];
  noLockerUnit?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPhoneArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryRentingArgs = {
  rentingId: Scalars['ID'];
};


export type QueryRentingStatisticsArgs = {
  lockersystemId: Scalars['ID'];
};


export type QueryRentingsArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  itemName?: InputMaybe<Scalars['String']>;
  lockerNumber?: InputMaybe<Scalars['Int']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  rentingUser?: InputMaybe<Scalars['String']>;
  skipInvalid?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
};


export type QuerySearchEnvironmentsArgs = {
  input?: InputMaybe<SearchEnvironmentsOpt>;
};


export type QuerySearchFeedbacksArgs = {
  input?: InputMaybe<FeedbacksQuery>;
};


export type QuerySearchGateHistoryArgs = {
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  gateGroupId?: InputMaybe<Scalars['ID']>;
  gateId?: InputMaybe<Scalars['ID']>;
  lastDay?: InputMaybe<Scalars['Boolean']>;
  lastFiveMinutes?: InputMaybe<Scalars['Boolean']>;
  lastHour?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchLockersystemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchWord?: InputMaybe<Scalars['String']>;
  showPassivated?: InputMaybe<Scalars['Boolean']>;
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};


export type QueryUserApiKeysArgs = {
  userId: Scalars['ID'];
};


export type QueryUsersArgs = {
  input?: InputMaybe<UserSearchOptions>;
};


export type QueryVisitorCountsArgs = {
  query: VisitorCountsQuery;
};

export type RegisterFcmResponse = {
  __typename?: 'RegisterFcmResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type RegisterPhoneWithGoogleTokenResponse = {
  __typename?: 'RegisterPhoneWithGoogleTokenResponse';
  token: Scalars['String'];
};

export type RegisterPhoneWithSamlAuthCodeResponse = {
  __typename?: 'RegisterPhoneWithSamlAuthCodeResponse';
  token: Scalars['String'];
};

export type RemoteOpenLocker = {
  deviceNumber: Scalars['ID'];
  lockerNumber: Scalars['Int'];
};

export type RemoteOpenLockerResponse = {
  __typename?: 'RemoteOpenLockerResponse';
  locker?: Maybe<Locker>;
};

export type RemoveAccessGroup = {
  accessGroupId: Scalars['ID'];
};

export type RemoveAccessGroupAccess = {
  accessGroupAccessId: Scalars['ID'];
};

export type RemoveAccessGroupAccessResponse = {
  __typename?: 'RemoveAccessGroupAccessResponse';
  accessGroupAccessId: Scalars['ID'];
};

export type RemoveAccessGroupResponse = {
  __typename?: 'RemoveAccessGroupResponse';
  accessGroupId: Scalars['ID'];
};

export type RemoveAccesslist = {
  accesslistId: Scalars['ID'];
};

export type RemoveAccesslistResponse = {
  __typename?: 'RemoveAccesslistResponse';
  accesslistId?: Maybe<Scalars['ID']>;
};

export type RemoveCardFromAccesslist = {
  accesslistId: Scalars['ID'];
  cardnumber: Scalars['String'];
};

export type RemoveCardFromAccesslistResponse = {
  __typename?: 'RemoveCardFromAccesslistResponse';
  accessCardId: Scalars['ID'];
  accesslist: Accesslist;
  cardnumber?: Maybe<Scalars['String']>;
};

export type RemoveCompanyAreas = {
  companyId: Scalars['ID'];
};

export type RemoveCompanyGateEvents = {
  companyId: Scalars['ID'];
};

export type RemoveGateEntity = {
  areaId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  force?: InputMaybe<Scalars['Boolean']>;
  gateId?: InputMaybe<Scalars['ID']>;
  nodeId?: InputMaybe<Scalars['ID']>;
};

export type RemoveGateEntityResponse = {
  __typename?: 'RemoveGateEntityResponse';
  removedGateNode?: Maybe<RemovedGateNode>;
};

export type RemoveGateFromGateGroupResponse = {
  __typename?: 'RemoveGateFromGateGroupResponse';
  success: Scalars['Boolean'];
};

export type RemoveGateGroupResponse = {
  __typename?: 'RemoveGateGroupResponse';
  success: Scalars['Boolean'];
};

export type RemoveItemFromLocker = {
  itemId: Scalars['ID'];
};

export type RemoveItemFromLockerResponse = {
  __typename?: 'RemoveItemFromLockerResponse';
  item: Item;
};

export type RemoveLockerGroup = {
  lockerGroupId: Scalars['ID'];
};

export type RemoveLockerGroupResponse = {
  __typename?: 'RemoveLockerGroupResponse';
  lockerGroup?: Maybe<LockerGroup>;
};

export type RemoveLockerNode = {
  lockerNodeId: Scalars['ID'];
};

export type RemoveLockerNodeResponse = {
  __typename?: 'RemoveLockerNodeResponse';
  lockerNodeId?: Maybe<Scalars['ID']>;
};

export type RemoveLockersFromLockerGroup = {
  lockerGroupId: Scalars['ID'];
  lockerIds: Array<Scalars['ID']>;
};

export type RemoveLockersFromLockerGroupResponse = {
  __typename?: 'RemoveLockersFromLockerGroupResponse';
  lockerGroup?: Maybe<LockerGroup>;
};

export type RemoveLockersystemPermission = {
  lockersystemUserPermissionId: Scalars['ID'];
};

export type RemoveLockersystemPermissionResponse = {
  __typename?: 'RemoveLockersystemPermissionResponse';
  lockersystemUserPermissionId: Scalars['ID'];
};

export type RemoveTranslation = {
  key: Scalars['String'];
  language: Scalars['String'];
  lockersystemId?: InputMaybe<Scalars['ID']>;
};

export type RemoveTranslationResponse = {
  __typename?: 'RemoveTranslationResponse';
  translationId?: Maybe<Scalars['ID']>;
};

export type RemoveUser = {
  userId: Scalars['ID'];
};

export type RemoveUserLockerPermission = {
  lockerId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RemoveUserLockerPermissionResponse = {
  __typename?: 'RemoveUserLockerPermissionResponse';
  userLockerPermissionId?: Maybe<Scalars['ID']>;
};

export type RemoveUserResponse = {
  __typename?: 'RemoveUserResponse';
  userId?: Maybe<Scalars['ID']>;
};

export type RemovedGateNode = {
  __typename?: 'RemovedGateNode';
  areaId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  gateId?: Maybe<Scalars['ID']>;
  nodeId?: Maybe<Scalars['ID']>;
};

export type RentItem = {
  itemId: Scalars['ID'];
};

export type RentItemResponse = {
  __typename?: 'RentItemResponse';
  item: Item;
  renting: Renting;
};

export type Renting = {
  __typename?: 'Renting';
  cancelledAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  codeUsedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  interruptedAt?: Maybe<Scalars['DateTime']>;
  item?: Maybe<Item>;
  locker: Locker;
  permitGiven?: Maybe<Scalars['Boolean']>;
  returnCode?: Maybe<Scalars['String']>;
  returnCodeUsedAt?: Maybe<Scalars['DateTime']>;
  returnItemBefore?: Maybe<Scalars['DateTime']>;
  state: RentingState;
  useCodeBefore?: Maybe<Scalars['DateTime']>;
  useReturnCodeBefore?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export enum RentingState {
  Active = 'Active',
  Finished = 'Finished',
  NeedsPermit = 'NeedsPermit',
  WaitsPickup = 'WaitsPickup',
  WaitsReturn = 'WaitsReturn'
}

export type RentingStatisticsRow = {
  __typename?: 'RentingStatisticsRow';
  item?: Maybe<Item>;
  rentingsCount?: Maybe<Scalars['Int']>;
};

export type RentingsConnection = {
  __typename?: 'RentingsConnection';
  rentings: Array<Renting>;
};

export type RequestLockerHistory = {
  deviceNumber: Scalars['ID'];
  endSeq: Scalars['Int'];
  lockerNumber: Scalars['Int'];
  startSeq: Scalars['Int'];
};

export type RequestLockerHistoryResponse = {
  __typename?: 'RequestLockerHistoryResponse';
  doorcontrol?: Maybe<Doorcontrol>;
};

export type RequestLockerStatus = {
  deviceNumber: Scalars['ID'];
  lockerNumbers: Array<Scalars['Int']>;
};

export type ResetGateCounters = {
  areaId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  gateId?: InputMaybe<Scalars['ID']>;
  nodeId?: InputMaybe<Scalars['ID']>;
};

export type ResetUserPasswordResponse = {
  __typename?: 'ResetUserPasswordResponse';
  passwordToShort?: Maybe<Scalars['Boolean']>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type ReturnRentedItem = {
  rentingId: Scalars['ID'];
};

export type ReturnRentedItemResponse = {
  __typename?: 'ReturnRentedItemResponse';
  code?: Maybe<Scalars['String']>;
  itemAlreadyRenturned?: Maybe<Scalars['Boolean']>;
  renting?: Maybe<Renting>;
  success: Scalars['Boolean'];
};

export type SearchEnvironmentsConnection = {
  __typename?: 'SearchEnvironmentsConnection';
  environments: Array<Environment>;
  totalCount: Scalars['Int'];
};

export type SearchEnvironmentsOpt = {
  acceptedState?: InputMaybe<EnvironmentAcceptedState>;
  connectionState?: InputMaybe<EnvironmentConnectionState>;
  device?: InputMaybe<Scalars['String']>;
  environmentId?: InputMaybe<Scalars['String']>;
  hasGates?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  lockersystemName?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  passivatedState?: InputMaybe<PassivatedState>;
};

export type SearchFeedbacksConnection = {
  __typename?: 'SearchFeedbacksConnection';
  feedbacks: Array<Feedback>;
  totalCount: Scalars['Int'];
};

export type SearchItemGroupsOptions = {
  lockersystemId?: InputMaybe<Scalars['ID']>;
  parentItemGroupId?: InputMaybe<Scalars['ID']>;
  searchWord?: InputMaybe<Scalars['String']>;
};

export type SearchItemsOptions = {
  lockersystemId?: InputMaybe<Scalars['ID']>;
  onlyShowRentable?: InputMaybe<Scalars['Boolean']>;
  searchWord?: InputMaybe<Scalars['String']>;
};

export type SearchLockersystemsConnection = {
  __typename?: 'SearchLockersystemsConnection';
  lockersystems: Array<Lockersystem>;
  totalCount: Scalars['Int'];
};

export type SelectLockersystemResponse = {
  __typename?: 'SelectLockersystemResponse';
  success?: Maybe<Scalars['Boolean']>;
  viewer?: Maybe<Viewer>;
};

export type SendCardKeys = {
  deviceNumber: Scalars['String'];
};

export type SendCardKeysResponse = {
  __typename?: 'SendCardKeysResponse';
  status?: Maybe<CommandStatus>;
};

export type SendDoormapToLockerNodes = {
  deviceNumber: Scalars['ID'];
  targets: Array<DoormapTarget>;
};

export type SendDoormapToLockerNodesResponse = {
  __typename?: 'SendDoormapToLockerNodesResponse';
  status: CommandStatus;
};

export type SendDoormapToLockers = {
  deviceNumber: Scalars['ID'];
  lockerNumbers?: InputMaybe<Array<Scalars['Int']>>;
};

export type SendDoormapToLockersResponse = {
  __typename?: 'SendDoormapToLockersResponse';
  status?: Maybe<CommandStatus>;
};

export type SendFeedback = {
  deliveryId?: InputMaybe<Scalars['ID']>;
  eventTime?: InputMaybe<Scalars['DateTime']>;
  lockerGroupId?: InputMaybe<Scalars['ID']>;
  lockerId?: InputMaybe<Scalars['ID']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  rentingId?: InputMaybe<Scalars['ID']>;
  type: FeedbackType;
  userId?: InputMaybe<Scalars['ID']>;
};

export type SendFeedbackResponse = {
  __typename?: 'SendFeedbackResponse';
  feedback?: Maybe<Feedback>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SendMasterPincode = {
  deviceNumber: Scalars['String'];
  pincode: Scalars['String'];
};

export type SetupAccessGroup = {
  allowLockAll?: InputMaybe<Scalars['Boolean']>;
  maxLockedCount?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type SetupAccessGroupAccess = {
  access: Scalars['Boolean'];
  accessGroup: Scalars['String'];
  master: Scalars['Boolean'];
  maxLockedCount?: InputMaybe<Scalars['Int']>;
};

export type SetupAccesslist = {
  accessGroupAccess?: InputMaybe<Array<SetupAccessGroupAccess>>;
  cards?: InputMaybe<Array<Scalars['String']>>;
  clearAccesslistChanges?: InputMaybe<Scalars['Boolean']>;
  clearCards?: InputMaybe<Scalars['Boolean']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type SetupDoorcontrol = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  cluster: Scalars['String'];
  deviceNumber: Scalars['String'];
};

export type SetupFileMessage = {
  __typename?: 'SetupFileMessage';
  metadata?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SetupItem = {
  clearRentings?: InputMaybe<Scalars['Boolean']>;
  locker: Scalars['Int'];
  name: Scalars['String'];
  needsPermit?: InputMaybe<Scalars['Boolean']>;
  rentable?: InputMaybe<Scalars['Boolean']>;
};

export type SetupLainaamoTerminals = {
  deviceNumber: Scalars['String'];
};

export type SetupLockerGroup = {
  hidden?: InputMaybe<Scalars['Boolean']>;
  lainaamo?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type SetupLockerNode = {
  access?: InputMaybe<Scalars['Boolean']>;
  cluster: Scalars['String'];
  demo?: InputMaybe<Scalars['Boolean']>;
  desfire?: InputMaybe<Scalars['Boolean']>;
  hslDesfire?: InputMaybe<Scalars['Boolean']>;
  keepLock?: InputMaybe<Scalars['Boolean']>;
  leftDownAccessGroup?: InputMaybe<Scalars['String']>;
  leftDownDoorNr?: InputMaybe<Scalars['Int']>;
  leftDownLockerGroup?: InputMaybe<Scalars['String']>;
  leftDownLockerSeq?: InputMaybe<Scalars['Int']>;
  leftOpen?: InputMaybe<Scalars['Boolean']>;
  leftUpAccessGroup?: InputMaybe<Scalars['String']>;
  leftUpDoorNr?: InputMaybe<Scalars['Int']>;
  leftUpLockerGroup?: InputMaybe<Scalars['String']>;
  leftUpLockerSeq?: InputMaybe<Scalars['Int']>;
  mifare?: InputMaybe<Scalars['Boolean']>;
  mobile?: InputMaybe<Scalars['Boolean']>;
  multiuse?: InputMaybe<Scalars['Boolean']>;
  node: Scalars['Int'];
  personal?: InputMaybe<Scalars['Boolean']>;
  rightDownAccessGroup?: InputMaybe<Scalars['String']>;
  rightDownDoorNr?: InputMaybe<Scalars['Int']>;
  rightDownLockerGroup?: InputMaybe<Scalars['String']>;
  rightDownLockerSeq?: InputMaybe<Scalars['Int']>;
  rightOpen?: InputMaybe<Scalars['Boolean']>;
  rightUpAccessGroup?: InputMaybe<Scalars['String']>;
  rightUpDoorNr?: InputMaybe<Scalars['Int']>;
  rightUpLockerGroup?: InputMaybe<Scalars['String']>;
  rightUpLockerSeq?: InputMaybe<Scalars['Int']>;
  segment: Scalars['Int'];
  silent?: InputMaybe<Scalars['Boolean']>;
  ultralight?: InputMaybe<Scalars['Boolean']>;
  verticalDoubleLock?: InputMaybe<Scalars['Boolean']>;
};

export type SetupLockersystem = {
  accessGroups?: InputMaybe<Array<SetupAccessGroup>>;
  accesslists?: InputMaybe<Array<SetupAccesslist>>;
  clearHistory?: InputMaybe<Scalars['Boolean']>;
  clearState?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['ID']>;
  deliveryAllowed?: InputMaybe<Scalars['Boolean']>;
  deliveryNonRegistered?: InputMaybe<Scalars['Boolean']>;
  doorcontrols?: InputMaybe<Array<SetupDoorcontrol>>;
  emailPermissionDomains?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<SetupItem>>;
  lainaamoTerminals?: InputMaybe<Array<SetupLainaamoTerminals>>;
  lockerGroups?: InputMaybe<Array<SetupLockerGroup>>;
  lockerNodes?: InputMaybe<Array<SetupLockerNode>>;
  name: Scalars['String'];
  removeUserByEmail?: InputMaybe<Array<Scalars['String']>>;
  rentingAllowed?: InputMaybe<Scalars['Boolean']>;
  rentingNonRegistered?: InputMaybe<Scalars['Boolean']>;
  showWhoBorrows?: InputMaybe<Scalars['Boolean']>;
  userPermissions?: InputMaybe<Array<SetupUserPermission>>;
  users?: InputMaybe<Array<SetupUser>>;
};

export type SetupLockersystemResponse = {
  __typename?: 'SetupLockersystemResponse';
  lockersystem?: Maybe<Lockersystem>;
};

export type SetupUserPermission = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  companyGateStateChanges: CompanyGateState;
  companyLockersystemStateChanges?: Maybe<CompanyLockersystemChange>;
  doorcontrolChange?: Maybe<Doorcontrol>;
  doorcontrolDoormapLockers: Array<Locker>;
  doorcontrolLockers: Array<DoorcontrolLocker>;
  doorcontrolStateChange?: Maybe<DoorcontrolStateChange>;
  doorcontrolsChange?: Maybe<Doorcontrol>;
  environmentChanged?: Maybe<Environment>;
  lockerGroupLockers: Array<Locker>;
  lockersystemLockerGroups: Array<LockerGroup>;
  lockersystemLockers: Array<Locker>;
  newDoorcontrolEvent: Array<DoorcontrolEvent>;
  newDoorcontrolMsg: Array<DoorcontrolMessage>;
  newLockerHistoryEvent?: Maybe<LockerHistoryEvent>;
  newValidation: Validation;
  nodeConnectionAdded: NodeConnection;
  nodeConnectionRemoved: Scalars['ID'];
};


export type SubscriptionCompanyGateStateChangesArgs = {
  companyId: Scalars['ID'];
};


export type SubscriptionCompanyLockersystemStateChangesArgs = {
  companyId: Scalars['ID'];
};


export type SubscriptionDoorcontrolChangeArgs = {
  deviceNumber: Scalars['ID'];
};


export type SubscriptionDoorcontrolDoormapLockersArgs = {
  deviceNumber: Scalars['ID'];
};


export type SubscriptionDoorcontrolLockersArgs = {
  deviceNumber: Scalars['ID'];
};


export type SubscriptionDoorcontrolStateChangeArgs = {
  deviceNumber: Scalars['ID'];
};


export type SubscriptionLockerGroupLockersArgs = {
  lockerGroupId: Scalars['ID'];
};


export type SubscriptionLockersystemLockerGroupsArgs = {
  lockersystemId: Scalars['ID'];
};


export type SubscriptionLockersystemLockersArgs = {
  lockersystemId: Scalars['ID'];
};


export type SubscriptionNewDoorcontrolEventArgs = {
  deviceNumber?: InputMaybe<Scalars['String']>;
};


export type SubscriptionNewDoorcontrolMsgArgs = {
  deviceNumber?: InputMaybe<Scalars['String']>;
};


export type SubscriptionNewLockerHistoryEventArgs = {
  deviceNumber?: InputMaybe<Scalars['String']>;
};


export type SubscriptionNewValidationArgs = {
  companyId: Scalars['ID'];
};

export type Translation = {
  __typename?: 'Translation';
  id: Scalars['ID'];
  key: Scalars['String'];
  language: Scalars['String'];
  lockersystemId?: Maybe<Scalars['ID']>;
  translation: Scalars['String'];
};

export type TranslationItem = {
  __typename?: 'TranslationItem';
  default: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  language: Scalars['String'];
  translation?: Maybe<Scalars['String']>;
  variables: Array<Scalars['String']>;
};

export type UnblockDoorcontrol = {
  deviceNumber: Scalars['ID'];
};

export type UnblockDoorcontrolResponse = {
  __typename?: 'UnblockDoorcontrolResponse';
  doorcontrol?: Maybe<Doorcontrol>;
};

export type UpdateAccessGroup = {
  accessGroupId: Scalars['ID'];
  allowLockAll?: InputMaybe<Scalars['Boolean']>;
  maxLockedPerCard?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  requireLockerPerm?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateAccessGroupAccess = {
  accessGroupAccessId: Scalars['ID'];
  master?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateAccessGroupAccessResponse = {
  __typename?: 'UpdateAccessGroupAccessResponse';
  accessGroupAccess?: Maybe<AccessGroupAccess>;
};

export type UpdateAccessGroupResponse = {
  __typename?: 'UpdateAccessGroupResponse';
  accessGroup: AccessGroup;
};

export type UpdateAccesslist = {
  accesslistId: Scalars['ID'];
  hidden?: InputMaybe<Scalars['Boolean']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateAccesslistResponse = {
  __typename?: 'UpdateAccesslistResponse';
  accesslist: Accesslist;
};

export type UpdateDelivery = {
  deliveryId: Scalars['ID'];
  pickupBefore?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateDeliveryResponse = {
  __typename?: 'UpdateDeliveryResponse';
  delivery?: Maybe<Delivery>;
};

export type UpdateDoorcontrol = {
  canbridgeUsed?: InputMaybe<Scalars['Boolean']>;
  cardKeysId?: InputMaybe<Scalars['ID']>;
  deviceNumber: Scalars['ID'];
  hide?: InputMaybe<Scalars['Boolean']>;
  rateLimit?: InputMaybe<Scalars['Int']>;
  reportErrors?: InputMaybe<Scalars['Boolean']>;
  reportOfflines?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateDoorcontrolResponse = {
  __typename?: 'UpdateDoorcontrolResponse';
  doorcontrol?: Maybe<Doorcontrol>;
};

export type UpdateDoormap = {
  lockersystemId: Scalars['ID'];
  rows: Array<DoormapRow>;
};

export type UpdateDoormapResponse = {
  __typename?: 'UpdateDoormapResponse';
  lockersystem: Lockersystem;
  messages: Array<SetupFileMessage>;
};

export type UpdateGateNode = {
  allowCloseSales?: InputMaybe<Scalars['Boolean']>;
  allowOpenSales?: InputMaybe<Scalars['Boolean']>;
  areaId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  gateId?: InputMaybe<Scalars['String']>;
  nodeId?: InputMaybe<Scalars['String']>;
};

export type UpdateGateNodeResponse = {
  __typename?: 'UpdateGateNodeResponse';
  gateNode?: Maybe<GateNode>;
};

export type UpdateItem = {
  description?: InputMaybe<Scalars['String']>;
  emailAdminWhenReturned?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Upload']>;
  itemGroupId?: InputMaybe<Scalars['ID']>;
  itemId: Scalars['ID'];
  lockerGroupId?: InputMaybe<Scalars['ID']>;
  lockerId?: InputMaybe<Scalars['ID']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  needsPermit?: InputMaybe<Scalars['Boolean']>;
  rentable?: InputMaybe<Scalars['Boolean']>;
  rentingTime?: InputMaybe<Scalars['Int']>;
};

export type UpdateItemGroup = {
  itemGroupId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateItemGroupResponse = {
  __typename?: 'UpdateItemGroupResponse';
  itemGroup?: Maybe<ItemGroup>;
};

export type UpdateItemResponse = {
  __typename?: 'UpdateItemResponse';
  item?: Maybe<Item>;
};

export type UpdateLainaamoTerminal = {
  adminMenuEnabled?: InputMaybe<Scalars['Boolean']>;
  deviceNumber: Scalars['ID'];
  hide?: InputMaybe<Scalars['Boolean']>;
  lockersystemId?: InputMaybe<Scalars['ID']>;
  reportErrors?: InputMaybe<Scalars['Boolean']>;
  reportOfflines?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateLainaamoTerminalResponse = {
  __typename?: 'UpdateLainaamoTerminalResponse';
  lainaamoTerminal?: Maybe<LainaamoTerminal>;
};

export type UpdateLocker = {
  accessGroupId?: InputMaybe<Scalars['ID']>;
  depth?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  lockerGroupId?: InputMaybe<Scalars['ID']>;
  lockerId: Scalars['ID'];
  terminal?: InputMaybe<Scalars['Boolean']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UpdateLockerGroup = {
  hidden?: InputMaybe<Scalars['Boolean']>;
  lainaamo?: InputMaybe<Scalars['Boolean']>;
  lockerGroupId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateLockerGroupResponse = {
  __typename?: 'UpdateLockerGroupResponse';
  lockerGroup?: Maybe<LockerGroup>;
  lockersystem?: Maybe<Lockersystem>;
};

export type UpdateLockerNode = {
  access?: InputMaybe<Scalars['Boolean']>;
  cluster?: InputMaybe<Scalars['String']>;
  demo?: InputMaybe<Scalars['Boolean']>;
  desfire?: InputMaybe<Scalars['Boolean']>;
  doorNumberLeftDown?: InputMaybe<Scalars['Int']>;
  doorNumberLeftUp?: InputMaybe<Scalars['Int']>;
  doorNumberRightDown?: InputMaybe<Scalars['Int']>;
  doorNumberRightUp?: InputMaybe<Scalars['Int']>;
  hslDesfire?: InputMaybe<Scalars['Boolean']>;
  keepLock?: InputMaybe<Scalars['Boolean']>;
  leftDownLockerNumber?: InputMaybe<Scalars['Int']>;
  leftOpen?: InputMaybe<Scalars['Boolean']>;
  leftUpLockerNumber?: InputMaybe<Scalars['Int']>;
  lockerNodeId: Scalars['ID'];
  lockerUnitId?: InputMaybe<Scalars['ID']>;
  lockerUnitNodeId?: InputMaybe<Scalars['String']>;
  mifare?: InputMaybe<Scalars['Boolean']>;
  mobile?: InputMaybe<Scalars['Boolean']>;
  multiuse?: InputMaybe<Scalars['Boolean']>;
  nodeNumber?: InputMaybe<Scalars['Int']>;
  personal?: InputMaybe<Scalars['Boolean']>;
  rightDownLockerNumber?: InputMaybe<Scalars['Int']>;
  rightOpen?: InputMaybe<Scalars['Boolean']>;
  rightUpLockerNumber?: InputMaybe<Scalars['Int']>;
  segment?: InputMaybe<Scalars['String']>;
  silent?: InputMaybe<Scalars['Boolean']>;
  ultralight?: InputMaybe<Scalars['Boolean']>;
  verticalDoubleLock?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateLockerNodeResponse = {
  __typename?: 'UpdateLockerNodeResponse';
  lockerNode: LockerNode;
};

export type UpdateLockerProgram = {
  deviceNumber: Scalars['String'];
  programId: Scalars['ID'];
};

export type UpdateLockerProgramResponse = {
  __typename?: 'UpdateLockerProgramResponse';
  status?: Maybe<CommandStatus>;
};

export type UpdateLockerResponse = {
  __typename?: 'UpdateLockerResponse';
  locker: Locker;
};

export type UpdateLockerUnit = {
  lockerGroupId: Scalars['ID'];
  lockerNodeIds: Array<Scalars['ID']>;
  lockerUnitId: Scalars['ID'];
};

export type UpdateLockers = {
  lockers: Array<InputMaybe<UpdateLocker>>;
};

export type UpdateLockersResponse = {
  __typename?: 'UpdateLockersResponse';
  lockers: Array<Maybe<Locker>>;
};

export type UpdateLockersystem = {
  companyId?: InputMaybe<Scalars['ID']>;
  deliveryAllowed?: InputMaybe<Scalars['Boolean']>;
  deliveryNonRegistered?: InputMaybe<Scalars['Boolean']>;
  emailPermissionDomains?: InputMaybe<Array<Scalars['String']>>;
  googleUsersAllowed?: InputMaybe<Scalars['Boolean']>;
  lockersystemId: Scalars['ID'];
  maintenanceMode?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  openSystem?: InputMaybe<Scalars['Boolean']>;
  ouluUniUsersAllowed?: InputMaybe<Scalars['Boolean']>;
  passivated?: InputMaybe<Scalars['Boolean']>;
  rentingAllowed?: InputMaybe<Scalars['Boolean']>;
  rentingNonRegistered?: InputMaybe<Scalars['Boolean']>;
  showWhoBorrows?: InputMaybe<Scalars['Boolean']>;
  urlId?: InputMaybe<Scalars['ID']>;
  userPermMods?: InputMaybe<Array<UserLockersystemPermMod>>;
};

export type UpdateLockersystemAccessGroups = {
  lockersystemId: Scalars['ID'];
  newAccessGroups: Array<NewAccessGroup>;
  removeAccessGroups: Array<RemoveAccessGroup>;
  updateAccessGroups: Array<UpdateAccessGroup>;
  updateLocker: Array<UpdateLocker>;
};

export type UpdateLockersystemAccessGroupsResponse = {
  __typename?: 'UpdateLockersystemAccessGroupsResponse';
  lockersystem?: Maybe<Lockersystem>;
};

export type UpdateLockersystemLockerGroups = {
  lockerUnitNodeId: Array<Scalars['ID']>;
  lockersystemId: Scalars['ID'];
  newLockerGroups: Array<CreateLockerGroup>;
  newLockerUnits: Array<UpdateLockerUnit>;
  removedLockerGroupIds: Array<Scalars['ID']>;
  removedLockerUnits: Array<Scalars['ID']>;
  updateLockerNodes: Array<UpdateLockerNode>;
  updateLockers: Array<UpdateLocker>;
  updatedLockerGroups: Array<UpdateLockerGroup>;
};

export type UpdateLockersystemLockerGroupsResponse = {
  __typename?: 'UpdateLockersystemLockerGroupsResponse';
  lockersystem?: Maybe<Lockersystem>;
};

export type UpdateLockersystemPermission = {
  admin?: InputMaybe<Scalars['Boolean']>;
  lockersystemPermissionId: Scalars['ID'];
};

export type UpdateLockersystemPermissionResponse = {
  __typename?: 'UpdateLockersystemPermissionResponse';
  lockersystemUserPermission?: Maybe<LockersystemUserPermission>;
};

export type UpdateLockersystemResponse = {
  __typename?: 'UpdateLockersystemResponse';
  lockersystem?: Maybe<Lockersystem>;
};

export type UpdateRenting = {
  rentingId: Scalars['ID'];
  returnItemBefore?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateRentingResponse = {
  __typename?: 'UpdateRentingResponse';
  renting: Renting;
};

export type UpdateTranslation = {
  key: Scalars['String'];
  language: Scalars['String'];
  lockersystemId?: InputMaybe<Scalars['ID']>;
  translation: Scalars['String'];
};

export type UpdateTranslationResponse = {
  __typename?: 'UpdateTranslationResponse';
  lockersystemId?: Maybe<Scalars['ID']>;
  translation?: Maybe<Translation>;
};

export type UpdateUser = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};

export type UpdateUserResponse = {
  __typename?: 'UpdateUserResponse';
  user?: Maybe<User>;
};

export type UploadDoormap = {
  doormap: Scalars['String'];
  lockersystemId: Scalars['ID'];
};

export type UploadDoormapResponse = {
  __typename?: 'UploadDoormapResponse';
  lockersystem: Lockersystem;
  messages: Array<SetupFileMessage>;
};

export type UploadLockerProgram = {
  description?: InputMaybe<Scalars['String']>;
  payload: Scalars['String'];
};

export type UploadLockerProgramResponse = {
  __typename?: 'UploadLockerProgramResponse';
  lockerProgram?: Maybe<LockerProgram>;
};

export type User = {
  __typename?: 'User';
  accessCards: Array<AccessCard>;
  activeDeliveries: UserActiveDeliveriesConnection;
  activeRentings: UserActiveRentingsConnection;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  external?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  gmail?: Maybe<Scalars['String']>;
  hasAccessTo?: Maybe<Array<Maybe<Lockersystem>>>;
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  lockerPermissions?: Maybe<Array<Maybe<UserLockerPermission>>>;
  lockersystemPermissions: Array<LockersystemUserPermission>;
  lockersystems: UserLockersystemsConnection;
  name?: Maybe<Scalars['String']>;
  resetToken?: Maybe<Scalars['String']>;
  resetUrl?: Maybe<Scalars['String']>;
  ssn: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  userNotloginYet: Scalars['Boolean'];
};


export type UserAccessCardsArgs = {
  accesslistId?: InputMaybe<Scalars['ID']>;
};


export type UserLockerPermissionsArgs = {
  lockersystemId: Scalars['ID'];
};


export type UserLockersystemsArgs = {
  opt?: InputMaybe<UserLockersystemsOpt>;
};

export type UserActiveDeliveriesConnection = {
  __typename?: 'UserActiveDeliveriesConnection';
  deliveries: Array<Delivery>;
};

export type UserActiveRentingsConnection = {
  __typename?: 'UserActiveRentingsConnection';
  activeRentings: Array<Renting>;
  totalCount: Scalars['Int'];
};

export type UserDeliveriesConnection = {
  __typename?: 'UserDeliveriesConnection';
  deliveries: Array<Delivery>;
  totalCount: Scalars['Int'];
};

export type UserLockerPermission = {
  __typename?: 'UserLockerPermission';
  id: Scalars['ID'];
  locker: Locker;
  lockerId: Scalars['ID'];
  user: User;
  userId: Scalars['ID'];
};

export type UserLockersystemPermMod = {
  hasAccess: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type UserLockersystemsConnection = {
  __typename?: 'UserLockersystemsConnection';
  lockersystems: Array<Lockersystem>;
};

export type UserLockersystemsOpt = {
  deliveryAllowed?: InputMaybe<Scalars['Boolean']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  rentingAllowed?: InputMaybe<Scalars['Boolean']>;
};

export type UserSearchOptions = {
  accessToItemId?: InputMaybe<Scalars['ID']>;
  accessToLockerGroupId?: InputMaybe<Scalars['ID']>;
  accessToLockersystemId?: InputMaybe<Scalars['ID']>;
  searchWord?: InputMaybe<Scalars['String']>;
  showNotLoggedIn?: InputMaybe<Scalars['Boolean']>;
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  totalCount?: Maybe<Scalars['Int']>;
  users: Array<User>;
};

export type Validation = {
  __typename?: 'Validation';
  areaId?: Maybe<Scalars['ID']>;
  cardnumber?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  gateId?: Maybe<Scalars['ID']>;
  nodeId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  validationDirection?: Maybe<ValidationDirection>;
  validationErrorCode?: Maybe<Scalars['Int']>;
  validationType?: Maybe<ValidationType>;
};

export enum ValidationDirection {
  In = 'In',
  None = 'None',
  Out = 'Out'
}

export enum ValidationErrorCode {
  CardFailure = 'CardFailure',
  CustomerIsRequired = 'CustomerIsRequired',
  CustomerNotFound = 'CustomerNotFound',
  CustomerNotValidNow = 'CustomerNotValidNow',
  Expired = 'Expired',
  LocationAccessDenided = 'LocationAccessDenided',
  NoCardInReader = 'NoCardInReader',
  NoMultivalidation = 'NoMultivalidation',
  NoPeriodValidationRemaining = 'NoPeriodValidationRemaining',
  NoProductsInCard = 'NoProductsInCard',
  NotCurrentlyValid = 'NotCurrentlyValid',
  NotEnoughValue = 'NotEnoughValue',
  NotYetValid = 'NotYetValid',
  PriceNotFound = 'PriceNotFound',
  ProductIsOnReceipt = 'ProductIsOnReceipt',
  ProductUseNotAllowedByParameters = 'ProductUseNotAllowedByParameters',
  ProductUseTimeLimitation = 'ProductUseTimeLimitation',
  ProductZonesNotValid = 'ProductZonesNotValid',
  Unknow = 'Unknow',
  UnknowProduct = 'UnknowProduct',
  WeekDayAccessDenied = 'WeekDayAccessDenied'
}

export enum ValidationType {
  Backvalidation = 'Backvalidation',
  FreepassCard = 'FreepassCard',
  FreepassRemote = 'FreepassRemote',
  None = 'None',
  Normal = 'Normal',
  NormalOut = 'NormalOut'
}

export type Viewer = {
  __typename?: 'Viewer';
  featureFlags?: Maybe<Array<Maybe<Scalars['String']>>>;
  portalAddress?: Maybe<Scalars['String']>;
  portalOauthClientId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type VisitorCountsQuery = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  datetimePoints?: InputMaybe<Array<Scalars['DateTime']>>;
  nodeIds?: InputMaybe<Array<Scalars['String']>>;
  productId?: InputMaybe<Array<Scalars['String']>>;
};

export type VisitorQueryResult = {
  __typename?: 'VisitorQueryResult';
  dataPoints: Array<DatetimeDataPoint>;
  totalInCount: Scalars['Int'];
  totalOutCount: Scalars['Int'];
};

export type SetupUser = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};
