import { Text, useTheme } from "pusatec-react-native"
import React from "react"
import { View } from "react-native"

export const  HeaderBar = (props: {
    text: string
	rightText?: string
}) => {
	const {colors} = useTheme()
	return (
		<View style={{ flexDirection: "row", backgroundColor: colors.primary, width:800, marginLeft: -10, height: 30, marginBottom: 10 }}>
			<Text text={props.text} style={{ marginLeft: 10, fontSize: 20, color: "white" }} />
			<Text text={props.rightText} style={{ marginLeft: 10, fontSize: 20, color: "white"}} />
		</View>
	)
}