import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportIssueMutationVariables = Types.Exact<{
  feedback: Types.SendFeedback;
}>;


export type ReportIssueMutation = { __typename?: 'Mutation', result: { __typename?: 'SendFeedbackResponse', feedback?: { __typename?: 'Feedback', id?: string | null } | null } };


export const ReportIssueDocument = gql`
    mutation ReportIssue($feedback: SendFeedback!) {
  result: sendFeedback(input: $feedback) {
    feedback {
      id
    }
  }
}
    `;
export type ReportIssueMutationFn = Apollo.MutationFunction<ReportIssueMutation, ReportIssueMutationVariables>;

/**
 * __useReportIssueMutation__
 *
 * To run a mutation, you first call `useReportIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportIssueMutation, { data, loading, error }] = useReportIssueMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useReportIssueMutation(baseOptions?: Apollo.MutationHookOptions<ReportIssueMutation, ReportIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportIssueMutation, ReportIssueMutationVariables>(ReportIssueDocument, options);
      }
export type ReportIssueMutationHookResult = ReturnType<typeof useReportIssueMutation>;
export type ReportIssueMutationResult = Apollo.MutationResult<ReportIssueMutation>;
export type ReportIssueMutationOptions = Apollo.BaseMutationOptions<ReportIssueMutation, ReportIssueMutationVariables>;