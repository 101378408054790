import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type JoinLockersystemMutationVariables = Types.Exact<{
  systemUrlId: Types.Scalars['ID'];
  userId: Types.Scalars['ID'];
}>;


export type JoinLockersystemMutation = { __typename?: 'Mutation', joinUserToLockersystem: { __typename?: 'JoinUserToLockersystemResponse', alreadyJoined: boolean, lockersystem?: { __typename?: 'Lockersystem', id: string, name?: string | null } | null, user?: { __typename?: 'User', lockersystems: { __typename?: 'UserLockersystemsConnection', lockersystems: Array<{ __typename?: 'Lockersystem', id: string, name?: string | null, lockerGroups: Array<{ __typename?: 'LockerGroup', id: string, name: string }> }> } } | null } };


export const JoinLockersystemDocument = gql`
    mutation JoinLockersystem($systemUrlId: ID!, $userId: ID!) {
  joinUserToLockersystem(
    input: {lockersystemUrlId: $systemUrlId, userId: $userId}
  ) {
    lockersystem {
      id
      name
    }
    user {
      lockersystems(opt: {rentingAllowed: true, deliveryAllowed: true}) {
        lockersystems {
          id
          name
          lockerGroups {
            id
            name
          }
        }
      }
    }
    alreadyJoined
  }
}
    `;
export type JoinLockersystemMutationFn = Apollo.MutationFunction<JoinLockersystemMutation, JoinLockersystemMutationVariables>;

/**
 * __useJoinLockersystemMutation__
 *
 * To run a mutation, you first call `useJoinLockersystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinLockersystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinLockersystemMutation, { data, loading, error }] = useJoinLockersystemMutation({
 *   variables: {
 *      systemUrlId: // value for 'systemUrlId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useJoinLockersystemMutation(baseOptions?: Apollo.MutationHookOptions<JoinLockersystemMutation, JoinLockersystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinLockersystemMutation, JoinLockersystemMutationVariables>(JoinLockersystemDocument, options);
      }
export type JoinLockersystemMutationHookResult = ReturnType<typeof useJoinLockersystemMutation>;
export type JoinLockersystemMutationResult = Apollo.MutationResult<JoinLockersystemMutation>;
export type JoinLockersystemMutationOptions = Apollo.BaseMutationOptions<JoinLockersystemMutation, JoinLockersystemMutationVariables>;