import { useNavigation } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import React, { useMemo, useState } from "react"
import { View, StyleProp, ViewStyle, Pressable } from "react-native"
import Base from "../../components/layout/Base"
import { MainStackParamList } from "../../MainNavigation"
import { useRemoveItemFromLockerMutation, } from "../wizards/remove-rentable-item/queries.generated"
import { SearchItemsQuery, useSearchItemsQuery, useUpdateItemMutation, } from "./queries.generated"
import { toString } from "lodash-es"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import { useTranslation } from "react-i18next"
import { ErrorMessage, Text, TouchButton, useTheme, } from "pusatec-react-native"
import { DeleteItemConfirmation } from "./DeleteItemConfirmation"
import { CheckBox, SearchBar } from "react-native-elements"
import { theme } from "../../theme"
import { useDebouncedState } from "pusatec-react-utility"
import { BiEditAlt, BiTrash } from "react-icons/bi"
import { AdminToolbar } from "./admin_toolbar"
import { AiOutlineClose } from "react-icons/ai"
import { HeaderBar } from "../../components/Headerbar"

type Item = NonNullable<SearchItemsQuery["items"]>["items"][0]

interface Props {
    systemId: string
    onRefetch: () => void
    loading: boolean
    style?: StyleProp<ViewStyle>
}

export function ManageItems(props: Props) {
	const { systemId, onRefetch, loading, style } = props
	const navigation = useNavigation<StackNavigationProp<MainStackParamList, "AdminNavigation">>()
	const { colors } = useTheme()
	const { t } = useTranslation()

	const [removeRentableItem, removeRentableItemM] = useRemoveItemFromLockerMutation({
		onCompleted: data => {
			navigation.push("RemoveItemFromLocker", { itemId: data!.result!.item.id })
		}
	})

	const [updateItem] = useUpdateItemMutation()

	const [searchWord, searchWordd, setSearchWord] = useDebouncedState("", 500)

	const { data, refetch } = useSearchItemsQuery({
		variables: {
			opt: {
				lockersystemId: systemId,
				searchWord: searchWordd,
			}
		}
	})

	const [selectedItemId, setSelectedItemId] = useState<string | null>(null)
	const selectedItem = data?.items.items.find(i => i.id === selectedItemId)

	const groups = useMemo(() => {
		if (!data?.items.items) {
			return []
		}

		const groupsMap = new Map<string, Item[]>()

		for (const item of data?.items.items) {
			let groupName

			if (!item.locker?.lockerGroup?.name) {
				groupName = t("Detached items")
			} else if (item.itemGroup?.name) {
				groupName = item.itemGroup.name
			} else {
				groupName = t("Items")
			}

			let group = groupsMap.get(groupName)

			if (!group) {
				group = []
				groupsMap.set(groupName, group)
			}

			group.push(item)
		}

		for (const [, items] of groupsMap) {
			items.sort((a, b) => {
				if (a.activeRenting && !b.activeRenting) {
					return 1
				}

				if (!a.activeRenting && b.activeRenting) {
					return -1
				}

				if (a.activeRenting && b.activeRenting) {
					return a.activeRenting.returnItemBefore < b.activeRenting.returnItemBefore ? -1 : 1
				}

				return a.name && b.name ? a.name.localeCompare(b.name) : 0
			})
		}

		const groups: {
            name: string
            items: Item[]
        }[] = []

		for (const [name, items] of groupsMap) {
			groups.push({
				name,
				items
			})
		}
		groups.sort((a, b) => {
			if (a.name === t("Detached items")) {
				return 1
			} else if (b.name === t("Detached items")) {
				return -1
			} else if (a.name === t("Items")) {
				return 1
			} else if (b.name === t("Items")) {
				return -1
			} else {
				return a.name.localeCompare(b.name)
			}
		})

		return groups
	}, [data?.items.items])

	const [showDeleteItemConfirmation, setShowDeleteItemConfirmation] = useState<boolean>(false)

	return (
		<View style={[{ flex: 1 }, style]}>

			{showDeleteItemConfirmation
				? <DeleteItemConfirmation
					item={selectedItem!}
					onExit={() => setShowDeleteItemConfirmation(false)}
					systemId={systemId}
				/>
				: null}

			<SearchBar
				lightTheme
				inputStyle={theme.Input.inputStyle}
				errorStyle={theme.Input.errorMessageStyle}
				labelStyle={theme.Input.labelStyle}
				containerStyle={theme.Input.containerStyle}
				inputContainerStyle={theme.Input.inputContainerStyle}
				value={searchWord}
				onChangeText={text => setSearchWord(text)}
			/>

			<MainScrollPanel
				style={{ borderWidth: 0, backgroundColor: undefined }}
				onRefresh={onRefetch}
				refreshing={loading}
			>
				{groups.map(({ name, items }) => (
					<View key={name} style={{ marginBottom: 10 }}>
						<HeaderBar text={`${name}`} rightText={`${items.filter(p => !p.activeRenting).length} / ${items.length}`} />
						{items.map(item => {
							const selected = item.id === selectedItemId

							const rentingLate = item.activeRenting?.returnItemBefore ?
								new Date(item.activeRenting?.returnItemBefore) < new Date():
								false

							let borderRightColor = "white"

							if (rentingLate) {
								borderRightColor = "red"
							} else if (item.activeRenting) {
								// TODO permitGiven is broken
								// if (!item.activeRenting.permitGiven && item.needsPermit) {
								// 	borderRightColor = "#e0a502"
								// } else {
								// 	borderRightColor = colors.warning
								// }
								borderRightColor = colors.warning
							} else if (item.locker == null || item.rentable === false) {
								borderRightColor = "#777"
							} else if (item.available) {
								borderRightColor = "#9ddc9d"
							} else {
								borderRightColor = colors.warning
							}

							return <Base
								key={item.id}
								onPress={() => {
									if (selectedItemId === item.id) {
										setSelectedItemId("")

										return
									}

									setSelectedItemId(item.id)
								}}
								selected={selected}
								style={{
									borderRightColor: borderRightColor,
									borderRightWidth: 8,
								}}
							>
								<View style={{ flexDirection: "column" }}>
									<div style={{ display: "flex", flexDirection: "row" }}>
										<div style={{ flexGrow: 1, wordBreak: "break-word" }}>
											<Text text={`${toString(item.name)}`} numberOfLines={6} type="strong" />
											{item.locker
												? <Text style={{  }} text={t("Locker {{name}}", { name: toString(item.locker?.name) })} />
												: null}
										</div>
										<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
											<Pressable onPress={() => {
												setSelectedItemId(item.id)
												if (item.locker) {
													removeRentableItem({
														variables: { itemId: item.id }
													})
												} else {
													setShowDeleteItemConfirmation(true)
												}
											}}>
												{item.locker ? <AiOutlineClose size={30} style={{marginRight: 20, color:"#f54058" }} /> : null}
												{!item.locker ? <BiTrash size={30} style={{marginRight:20, color:"#f54058"}} /> : null}
											</Pressable>
											<Pressable
												onPress={() => {
													navigation.push("EditItem", { itemId: item.id })
												}}
											>
												<BiEditAlt size={30} style={{color:"#4a8fba"}} />
											</Pressable>
										</View>
									</div>
								</View>

								{selected ? <>
									<CheckBox checked={item.rentable === true} title={t("Rentable")} onPress={() => {
										updateItem({
											variables: {
												input: {
													itemId: item.id,
													rentable: !(item.rentable === true)
												}
											}
										})
									}} />
									<CheckBox checked={item.needsPermit === true} title={t("Needs permit")} onPress={() => {
										updateItem({
											variables: {
												input: {
													itemId: item.id,
													needsPermit: !(item.needsPermit === true)
												}
											}
										})
									}} />
									<CheckBox checked={item.emailAdminWhenReturned === true} title={t("Email admin when returned")} onPress={() => {
										updateItem({
											variables: {
												input: {
													itemId: item.id,
													emailAdminWhenReturned: !(item.emailAdminWhenReturned)
												}
											}
										})
									}} />

									{item.activeRenting && <View style={{ marginTop: 10 }}>
										<Text text={t("Current renting")} bold />
										<Text text={`${toString(item.activeRenting?.user?.name)}`} />
										<Text text={`${toString(item.activeRenting?.user?.gmail)}`} />
										{item.activeRenting?.returnItemBefore
											? <Text text={t("Return before {{deadline}}", { deadline: new Date(item.activeRenting?.returnItemBefore).toLocaleString() })} />
											: null}
									</View>}
								</> : null}
							</Base>
						})}
					</View>
				))}
			</MainScrollPanel>

			<AdminToolbar>
				<View>
					{removeRentableItemM.error && <ErrorMessage extra={removeRentableItemM.error} />}
					<>
						{(selectedItem && selectedItem.locker == null)
							? <>
								<TouchButton
									text={t("Place to locker")}
									disabled={selectedItem?.activeRenting != null}
									loading={removeRentableItemM.loading}
									onPress={() => {
										navigation.push("PlaceItemToLocker", { itemId: selectedItem.id })
									}}
								/>
							</> : null}
					</>

					{!selectedItem &&
                <View style={{
                	flexDirection: "row",
                	justifyContent: "flex-end"
                }}>
                	<TouchButton
                		text={t("Add new item")}
                		containerStyle={{ height: 40
                		}}
                		onPress={() => navigation.push("CreateItem", { systemId: systemId })}
                	/>
                </View>}
					<View>
						{selectedItem &&
                    !selectedItem.activeRenting &&
                    selectedItem.locker &&
                    <TouchButton
                    	text={t("Allow renting")}
                    	onPress={() => {
                    		if (!selectedItemId) {
                    			return
                    		}

                    		navigation.push("AllowRenting", {
                    			systemId: systemId,
                    			itemId: selectedItemId
                    		})
                    	}}
                    />}
					</View>
				</View>
			</AdminToolbar>
		</View>
	)
}
