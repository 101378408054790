import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BrowseItemsQueryVariables = Types.Exact<{
  opt?: Types.InputMaybe<Types.SearchItemsOptions>;
}>;


export type BrowseItemsQuery = { __typename?: 'Query', items: { __typename?: 'ItemsConnection', items: Array<{ __typename?: 'Item', id: string, name?: string | null, imageUrl?: string | null, rentingTime?: number | null, available?: boolean | null, locker?: { __typename?: 'Locker', id: string, name: number } | null, itemGroup?: { __typename?: 'ItemGroup', id: string, name: string } | null }> } };


export const BrowseItemsDocument = gql`
    query BrowseItems($opt: SearchItemsOptions) {
  items(opt: $opt) {
    items {
      id
      name
      imageUrl
      rentingTime
      available
      locker {
        id
        name
      }
      itemGroup {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useBrowseItemsQuery__
 *
 * To run a query within a React component, call `useBrowseItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowseItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowseItemsQuery({
 *   variables: {
 *      opt: // value for 'opt'
 *   },
 * });
 */
export function useBrowseItemsQuery(baseOptions?: Apollo.QueryHookOptions<BrowseItemsQuery, BrowseItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseItemsQuery, BrowseItemsQueryVariables>(BrowseItemsDocument, options);
      }
export function useBrowseItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseItemsQuery, BrowseItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseItemsQuery, BrowseItemsQueryVariables>(BrowseItemsDocument, options);
        }
export type BrowseItemsQueryHookResult = ReturnType<typeof useBrowseItemsQuery>;
export type BrowseItemsLazyQueryHookResult = ReturnType<typeof useBrowseItemsLazyQuery>;
export type BrowseItemsQueryResult = Apollo.QueryResult<BrowseItemsQuery, BrowseItemsQueryVariables>;