import React from "react"
import { View } from "react-native"
import Panel from "../../components/layout/Panel"
import { FiSettings } from "react-icons/fi"
import { AiOutlineHome } from "react-icons/ai"
import { useNavigation } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import { MainStackParamList } from "../../MainNavigation"
import { TouchButton } from "pusatec-react-native"

export const AdminToolbar = (props: {
	children?: any
}) => {
	const navigation = useNavigation<StackNavigationProp<MainStackParamList, "AdminNavigation">>()
	return (
		<Panel style={{ marginLeft: 10, flexDirection: 'row' }}>
			<View style={{ flexGrow: 1, flexDirection: 'row' }}>
				<TouchButton
					type="secondary"
					icon={FiSettings}
					onPress={() => {
						navigation.replace('Settings');
					}}
					containerStyle={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						height: 40,
					}}
				/>
				<TouchButton
					type="secondary"
					icon={AiOutlineHome}
					onPress={() => {
						navigation.replace('ActiveItems')
					}}
					containerStyle={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						height: 40,
					}}
				/>
			</View>
			{props.children}
		</Panel>
	)
}