import React from "react"
import { View } from "react-native"
import QRCode from "react-native-qrcode-svg"
import { colors } from "../../theme"
import { Nfc } from "./Nfc"

interface Props {
	data: string
}

export default function DataTransmission(props: Props) {
	return (
		<>
			<Nfc data={props.data} />
			<View style={{ padding: 10, backgroundColor: colors.light, elevation: 5 }}>
				<QRCode value={props.data} />
			</View>
		</>
	)
}
