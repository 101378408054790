import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewRentingQueryVariables = Types.Exact<{
  rentingId: Types.Scalars['ID'];
}>;


export type ViewRentingQuery = { __typename?: 'Query', renting?: { __typename?: 'Renting', id: string, state: Types.RentingState, codeUsedAt?: any | null, returnItemBefore?: any | null, item?: { __typename?: 'Item', name?: string | null, description?: string | null, imageUrl?: string | null, lockersystem?: { __typename?: 'Lockersystem', name?: string | null } | null, lockerGroup?: { __typename?: 'LockerGroup', name: string } | null } | null, locker: { __typename?: 'Locker', id: string, name: number, lockersystem?: { __typename?: 'Lockersystem', id: string, name?: string | null } | null, lockerGroup?: { __typename?: 'LockerGroup', id: string, name: string } | null } } | null };

export type ReturnItemMutationVariables = Types.Exact<{
  rentingId: Types.Scalars['ID'];
}>;


export type ReturnItemMutation = { __typename?: 'Mutation', result: { __typename?: 'ReturnRentedItemResponse', success: boolean, code?: string | null, itemAlreadyRenturned?: boolean | null, renting?: { __typename?: 'Renting', id: string, returnCode?: string | null, returnCodeUsedAt?: any | null, useReturnCodeBefore?: any | null } | null } };


export const ViewRentingDocument = gql`
    query ViewRenting($rentingId: ID!) {
  renting(rentingId: $rentingId) {
    id
    state
    codeUsedAt
    returnItemBefore
    item {
      name
      description
      imageUrl
      lockersystem {
        name
      }
      lockerGroup {
        name
      }
    }
    locker {
      id
      name
      lockersystem {
        id
        name
      }
      lockerGroup {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useViewRentingQuery__
 *
 * To run a query within a React component, call `useViewRentingQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewRentingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewRentingQuery({
 *   variables: {
 *      rentingId: // value for 'rentingId'
 *   },
 * });
 */
export function useViewRentingQuery(baseOptions: Apollo.QueryHookOptions<ViewRentingQuery, ViewRentingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewRentingQuery, ViewRentingQueryVariables>(ViewRentingDocument, options);
      }
export function useViewRentingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewRentingQuery, ViewRentingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewRentingQuery, ViewRentingQueryVariables>(ViewRentingDocument, options);
        }
export type ViewRentingQueryHookResult = ReturnType<typeof useViewRentingQuery>;
export type ViewRentingLazyQueryHookResult = ReturnType<typeof useViewRentingLazyQuery>;
export type ViewRentingQueryResult = Apollo.QueryResult<ViewRentingQuery, ViewRentingQueryVariables>;
export const ReturnItemDocument = gql`
    mutation ReturnItem($rentingId: ID!) {
  result: returnRentedItem(input: {rentingId: $rentingId}) {
    success
    renting {
      id
      returnCode
      returnCodeUsedAt
      useReturnCodeBefore
    }
    code
    itemAlreadyRenturned
  }
}
    `;
export type ReturnItemMutationFn = Apollo.MutationFunction<ReturnItemMutation, ReturnItemMutationVariables>;

/**
 * __useReturnItemMutation__
 *
 * To run a mutation, you first call `useReturnItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnItemMutation, { data, loading, error }] = useReturnItemMutation({
 *   variables: {
 *      rentingId: // value for 'rentingId'
 *   },
 * });
 */
export function useReturnItemMutation(baseOptions?: Apollo.MutationHookOptions<ReturnItemMutation, ReturnItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReturnItemMutation, ReturnItemMutationVariables>(ReturnItemDocument, options);
      }
export type ReturnItemMutationHookResult = ReturnType<typeof useReturnItemMutation>;
export type ReturnItemMutationResult = Apollo.MutationResult<ReturnItemMutation>;
export type ReturnItemMutationOptions = Apollo.BaseMutationOptions<ReturnItemMutation, ReturnItemMutationVariables>;