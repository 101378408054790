import { apolloClient } from "../ApolloClient"
import { parseDoorhubToken } from "./parse-doorhub-token"
import { BuildConfiguration } from "../platform-info/build-configuration"
import Device from "expo-device"
import { Platform } from "react-native"
import { BearerAuthentication } from "./authentication-types"
import { SamlSigninDocument, SamlSigninMutation, SamlSigninMutationVariables } from "./queries.generated"
import { useEffect } from "react"

export function gotoSamlAuth() {
	const returnUrl = window.location.href
	const samlSigninUrl = new URL(BuildConfiguration.DOORHUB_HOST + "/api/saml-login")
	samlSigninUrl.searchParams.append("RelayState", returnUrl)
	window.location.href = samlSigninUrl.toString()
}

export function useSamlReturnDetector(callback: (authCode: string) => void) {
	useEffect(() => {
		const url = new URL(window.location.href)
		const samlLoginAuthCode = url.searchParams.get("samlLoginAuthCode")

		if (samlLoginAuthCode) {
			url.searchParams.delete("samlLoginAuthCode")
			window.history.replaceState({}, document.title, url.pathname + url.search)
			callback(samlLoginAuthCode)
		}
	}, [])
}

export async function signInToDoorhubWithSaml(
	samlAuthCode: string
): Promise<BearerAuthentication> {
	console.log("Authenticating with doorhub with SAML", { samlAuthCode })

	const { data } = await apolloClient.mutate<SamlSigninMutation, SamlSigninMutationVariables>({
		mutation: SamlSigninDocument,
		variables: {
			samlAuthCode: samlAuthCode,
			deviceModel: Device ? Device.modelId + ":" + Device.modelName : "web",
			os: Platform.select({ android: "ANDROID", ios: "IOS", web: "WEB" }) as any,
			versionName: BuildConfiguration.VERSION_NAME,
			versionCode: BuildConfiguration.VERSION_CODE,
			applicationId: BuildConfiguration.APPLICATION_ID,
		},
	})

	return parseDoorhubToken(data!.result!.token!)
}

