import React from "react"
import WizardStep from "../WizardStep"
import { WizardState } from "../use-wizard"
import { useTranslation } from "react-i18next"
import { ID } from "../../../utils/types"
import Loading from "../../../components/Loading"
import LockerAction from "../../../components/LockerAction"
import { ErrorMessage } from "pusatec-react-native"
import { useApolloClient } from "@apollo/client"
import { useCreateItemCodeQuery } from "../../Admin/queries.generated"

interface Props {
    wizardState: WizardState
    itemId: ID
    onCancel(): void
    onComplete(): void
}

export default function PlaceRentableItemStep(props: Props) {
	const { wizardState, itemId, onCancel, onComplete } = props
	const { t } = useTranslation()
	const apolloClient = useApolloClient()

	const { data, loading, error } = useCreateItemCodeQuery({
		variables: { itemId },
		pollInterval: 2000,
	})
	const item = data?.item

	if (loading) return <Loading />
	if (error || !item) return <ErrorMessage extra={error} />

	return (
		<WizardStep
			title={t("Place item to locker")}
			wizardState={wizardState}
			scroll
		>
			<LockerAction
				code={item.creationCode!}
				useCodeBefore={item.useCreationCodeBefore}
				codeUsedAt={item.creationCodeUsedAt}
				onComplete={() => {
					onComplete()
					apolloClient.refetchQueries({
						include: ["AdminView"]
					})
				}}
				onCancel={onCancel}
			/>
		</WizardStep>
	)
}
