import { useEffect } from "react"
import { BackHandler } from "react-native"

export default function useBackHandler(
	onBackPress: () => void,
	depends: any[]
) {
	useEffect(() => {
		const backHandler = BackHandler.addEventListener(
			"hardwareBackPress",
			() => {
				onBackPress()
				return undefined
			}
		)
		return () => {
			backHandler.remove()
		}
	}, depends)
}
