import React from "react"
import WizardProgressMarker from "./WizardProgressMarker"
import { WizardState } from "./use-wizard"
import Panel from "../../components/layout/Panel"
import { Text, useTheme } from "pusatec-react-native"

interface Props {
	title: string
	wizardState: WizardState
	children: any
	scroll: boolean
}

export default function WizardStep(props: Props) {
	const { title, children, wizardState, } = props
	const { colors } = useTheme()

	return (
		<>
			<Panel style={{ marginBottom: 0 }}>
				<WizardProgressMarker
					activeStepIndex={wizardState.activeStepIndex}
					stepCount={wizardState.stepCount}
				/>
				<Text text={title} style={{ textAlign: "center", color: colors.primary, fontWeight: "bold" }} />
			</Panel>
			{children}
		</>
	)
}

WizardStep.defaultProps = {
	scroll: false,
	background: "normal",
}
