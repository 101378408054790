import React, { useState } from "react"
import useWizard from "../wizards/use-wizard"
import SelectUserStep, { UserSelection } from "../wizards/common-steps/SelectUserStep"
import { StackNavigationProp } from "@react-navigation/stack"
import { MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/core"
import { AllowRentingStep } from "../wizards/allow-renting/AllowRentingStep"
import { useLockersystemFeatures } from "../../components/featureParameters"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "AllowRenting">
	route: RouteProp<MainStackParamList, "AllowRenting">
}

export const AllowRentingWizard = (props: Props) => {
	const wizardState = useWizard(2)
	const { systemId, itemId } = props.route.params ?? {}

	const [targetUser, setTargetUser] = useState<UserSelection | null>(null)
	const data = useLockersystemFeatures({systemId: systemId!})
	const emailEnabled = data?.lockersystem?.rentingNonRegistered ?? false

	if (wizardState.activeStepIndex === 0) {
		return <SelectUserStep
			wizardState={wizardState}
			systemId={systemId}
			user={targetUser}
			onChange={setTargetUser}
			onCancel={() => {
				props.navigation.replace("AdminNavigation", {
					systemId: systemId
				})
			}}
			onComplete={wizardState.nextStep}
			emailEnabled={emailEnabled}
		/>
	}
	if (targetUser && wizardState.activeStepIndex === 1) {
		return <AllowRentingStep
			wizardState={wizardState}
			userId={targetUser?.id}
			itemId={itemId}
			navigation={props.navigation}
			systemId={systemId}
			userGmail={targetUser?.email ?? ""}
		/>
	}

	return null
}