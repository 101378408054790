import React from "react"
import MyText from "../MyText"

interface Props {
	data: string
}

export function Nfc(props: Props) {
	return null
}

export default Nfc
