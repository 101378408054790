import { BuildConfiguration } from "./platform-info/build-configuration"
import {
	createHttpLink,
	ApolloClient,
	InMemoryCache,
	ApolloLink,
	from,
	Observable,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import i18n from "../i18n/i18n"
import { ActiveViewerDocument, ActiveViewerQuery } from "../screens/ActiveItems/queries.generated"
import { ChangeLanguageDocument } from "../screens/Admin/queries.generated"
import AsyncStorage from "@react-native-async-storage/async-storage"

export const API_URL = BuildConfiguration.DOORHUB_HOST

let authToken: string | null = null
export function setApolloAuthToken(token: string | null) {
	authToken = token
}

const getToken = async () => {
	if (authToken) {
		return authToken
	}

	return AsyncStorage.getItem("doorhub_token")
}

const httpLink = createHttpLink({
	uri: API_URL + "/graphql",
	credentials: "include"
})

const logLink = new ApolloLink((operation, forward) => {
	return forward(operation).map((res) => {
		return res
	})
})
const authLink = setContext(async (_, prevContext) => {
	const token = await getToken()

	if (!token) {
		console.error("No auth token")
		return {}
	}

	return {
		headers: {
			...prevContext.headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	}
})

const customErrorLink = new ApolloLink((operation, forward) => {
	return new Observable((subscriber) => {
		forward(operation).subscribe({
			next: (res) => {
				let graphqlErrors = res.errors
				if (res.data?.result?.error) {
					const error = res.data.result.error
					graphqlErrors = [error, ...(graphqlErrors ?? [])]
				}
				if (graphqlErrors?.length) {
					console.error("Graphql operation failed", {
						name: operation.operationName,
						variables: operation.variables,
						graphqlErrors,
					})
				}
				subscriber.next({ ...res, errors: graphqlErrors })
			},
			error: (error) => {
				console.error("Graphql operation failed", {
					name: operation.operationName,
					variables: operation.variables,
					error,
				})
				subscriber.error(error)
			},
			complete: () => subscriber.complete(),
		})
	})
})

export const apolloClient = new ApolloClient({
	link: from([
		logLink,
		authLink,
		customErrorLink,
		httpLink
	]),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			errorPolicy: "all",
			fetchPolicy: "cache-and-network",
			nextFetchPolicy: "cache-first",
		},
		query: {
			errorPolicy: "all",
		},
		mutate: {
			errorPolicy: "all",
		},
	},
})

let serverLanguage: string

apolloClient.watchQuery<ActiveViewerQuery>({ query: ActiveViewerDocument }).subscribe({
	next: (result) => {
		const user = result.data?.viewer?.user

		if (!user) {
			return
		}

		const language = user.language || ""
		const languageValid = ["en", "fi"].includes(language)

		if (language && languageValid && language !== serverLanguage) {
			i18n.changeLanguage(language)
			serverLanguage = language
		}

		if (!languageValid) {
			const lang = i18n.language
			apolloClient.mutate({
				mutation: ChangeLanguageDocument,
				variables: {
					input: {
						userId: user.id,
						language: lang
					}
				}
			})
			serverLanguage = lang
		}
	},
	error: (error) => {
		console.error('Error:', error)
	},
})