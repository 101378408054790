import { Button, useTheme } from "pusatec-react-native"
import React, { Fragment, useMemo, useState } from "react"
import { Text, View } from "react-native"
import { IoIosArrowDropdown, IoIosArrowDropright } from "react-icons/io"
import { useRemoveTranslationMutation, useUpdateTranslationMutation } from "./queries.generated"
import { AdminToolbar } from "./admin_toolbar"
import { useTranslation } from "react-i18next"
import { $convertFromTemplate, $createVariableNode, VariableNode, rootNodeToText } from "../../components/RichTextEditor"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { exampleTheme } from "../../components/RichTextEditorTheme"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $getRoot, $getSelection, RangeSelection } from "lexical"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { TranslationItem } from "../../doorhub-types"

const findMissingVariables = (template: string, expectedVariables: string[]): string[] => {
	// Use a regular expression to match all instances of text within {}
	const variablePattern = /\{([^}]+)\}/g;
	const foundVariables: string[] = [];
	let match: RegExpExecArray | null;

	// Extract variables from the template
	while ((match = variablePattern.exec(template)) !== null) {
		foundVariables.push(match[1]);
	}

	// Determine which expected variables are missing
	const missingVariables = expectedVariables.filter((variable) => !foundVariables.includes(variable));

	return missingVariables;
}

const EditorContent = (props: {
	translationItem: TranslationItem;
	onReset: (key: string, language: string) => void;
	onUpdate: (key: string, translation: string, language: string) => void;
}) => {
	const original = props.translationItem.translation || props.translationItem.default
	const [editor] = useLexicalComposerContext()
	const { t } = useTranslation()
	const [serverValue, setServerValue] = useState(original)
	const [modified, setModified] = useState(original);
	const templateVariables = useMemo(() => {
		const variables = findMissingVariables(modified, props.translationItem.variables)
		return variables
	}, [modified])

	const resetAllowed = props.translationItem.default !== modified
	const saveAllowed = modified !== serverValue && templateVariables.length === 0

	return (
		<Fragment>
			<div style={{
				border: "solid 1px rgba(0, 0, 0, 0.3)",
				borderBottom: "none"
			}}>
			</div>
			<div style={{
				border: "solid 1px rgba(0, 0, 0, 0.3)",
				backgroundColor: "white",
				outline: "none",
				padding: 0
			}}>
				<RichTextPlugin
					contentEditable={<ContentEditable style={{
						margin: 0,
						minHeight: 100,
					}} />}
					placeholder={<div></div>}
					ErrorBoundary={LexicalErrorBoundary}
				/>
				<div>
					{templateVariables.map((v) => {
						return (
							<button key={v} style={{ color: "red" }} onClick={() => {
								editor.update(() => {
									const selection = $getSelection() as RangeSelection
									const firstNode = selection.getNodes()[0]

									const parent = firstNode.getParent()

									if (!parent) {
										return
									}

									const varNode = $createVariableNode(v)
									selection.insertNodes([varNode])
								})
							}}>
								{t(v)}
							</button>
						)
					})}
				</div>
			</div>
			<View style={{ flexDirection: 'row' }}>
				<Button text={t("reset")} disabled={!resetAllowed} onPress={() => {
					setModified(props.translationItem.default)
					setServerValue(props.translationItem.default)
					editor.update(() => {
						const root = $getRoot()
						root.clear()
						$convertFromTemplate(props.translationItem.default)
					})
					if (props.translationItem.translation) {
						props.onReset(props.translationItem.key, props.translationItem.language)
					}
				}} />
				<Button text={t("save")} disabled={!saveAllowed} onPress={() => {
					if (templateVariables.length) {
						return
					}

					setServerValue(modified)
					props.onUpdate(props.translationItem.key, modified, props.translationItem.language);
				}} />
			</View>
			<OnChangePlugin onChange={(editorState) => {
				editorState.read(() => {
					let root = $getRoot()
					const finalText = rootNodeToText(root)
					setModified(finalText)
				})
			}} />
		</Fragment>
	)
}

export const TranslationEditor = (props: {
	translationItem: TranslationItem;
	onReset: (key: string, language: string) => void;
	onUpdate: (key: string, translation: string, language: string) => void;
}) => {
	return (
		<LexicalComposer initialConfig={{
			namespace: "EditTranslation",
			onError: err => console.error(err),
			theme: exampleTheme,
			editorState: () => $convertFromTemplate(props.translationItem.translation || props.translationItem.default),
			nodes: [
				VariableNode
			]
		}}>
			<EditorContent translationItem={props.translationItem} onReset={props.onReset} onUpdate={props.onUpdate} />
			<HistoryPlugin />
		</LexicalComposer>
	);
}

const Translations = (props: {
	shownKeys?: string[]
	translations: TranslationItem[]
	onShow: (key: string) => void
	onReset: (key: string, language: string) => void
	onUpdate: (key: string, translation: string, language: string) => void
}) => {
	const { colors } = useTheme();
	const { t } = useTranslation();

	const groups = useMemo(() => {
		const map = new Map<string, TranslationItem[]>()

		for (const translation of props.translations) {
			let group = map.get(translation.key)

			if (!group) {
				group = []
				map.set(translation.key, group)
			}

			group.push(translation)
		}

		return Array.from(map.entries())
	}, [props.shownKeys, props.translations])

	return (
		<View>
			{groups.map(([key, items]) => {
				const shown = props.shownKeys?.includes(key) ?? false

				return (
					<View key={key}>
						<Button
							containerStyle={{ alignItems: 'flex-start', backgroundColor: colors.primary }}
							textStyle={{ color: 'white' }}
							icon={shown ? IoIosArrowDropdown : IoIosArrowDropright}
							text={t(key)}
							onPress={() => props.onShow(key)}
						/>
						{shown && items.map((item) => (
							<View key={item.id}>
								<Text>{t(item.language)}</Text>
								<TranslationEditor
									translationItem={item}
									onReset={props.onReset}
									onUpdate={props.onUpdate}
								/>
							</View>
						))}
					</View>
				)
			})}
		</View>
	);
}

export default function ManageTranslations(props: {
	lockersystemId: string,
	onRefetch: () => void,
	translations: TranslationItem[]
}) {
	const [editTranslation] = useUpdateTranslationMutation();
	const [removeTranslation] = useRemoveTranslationMutation();
	const [shownKeys, setShownKeys] = useState<string[]>([]);

	const updateTranslation = (key: string, translation: string, language: string) => {
		editTranslation({
			variables: {
				input: {
					lockersystemId: props.lockersystemId,
					key,
					translation,
					language,
				},
			},
		}).then(() => {
			props.onRefetch()
		})
	};

	return (
		<View style={{ flexDirection: "column", flex: 1 }}>
			<View style={{ flex: 1 }}>
				<MainScrollPanel>
					<Translations
						shownKeys={shownKeys}
						onShow={key => {
							const exist = shownKeys.includes(key);
							if (exist) {
								setShownKeys(shownKeys.filter(k => k !== key));
							} else {
								setShownKeys([...shownKeys, key]);
							}
						}}
						translations={props.translations}
						onReset={(key, language) => {
							removeTranslation({
								variables: {
									input: {
										key,
										language,
										lockersystemId: props.lockersystemId,
									}
								}
							}).then(() => {
								props.onRefetch()
							})
						}}
						onUpdate={updateTranslation} />
				</MainScrollPanel>
			</View>
			<AdminToolbar />
		</View>
	)
}
