import { Modal, View } from "react-native"

import { TouchButton } from "pusatec-react-native"
import { ItemGroup } from "../../doorhub-types"
import { useDeleteItemGroupMutation,} from "../../components/queries.generated"
import {Notification} from "pusatec-react-native"
import { useTranslation } from "react-i18next"

interface Props {
    itemGroup: string
    name: string
    onExit: () => void
}

export function DeleteItemGroupConfirmation(props: Props) {
	const {itemGroup,  onExit } = props
	const [deleteItemGroup] = useDeleteItemGroupMutation()
	const { t } = useTranslation()

	return (
		<Modal transparent>
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.5)" }}>
				<View style={{ alignItems: "center", justifyContent: "center", borderRadius: 5, margin: 20, padding: 20, backgroundColor: "white", }}>
					<Notification
						type="warning"
						title={t("Delete item group {{name}}?", { name: props.name })}
						text={t("delete_item_group_confirmation_text")}
					/>
					<TouchButton text={t("Cancel")} onPress={() => {
						onExit()
					}} />
					<TouchButton text={t("Delete itemgroup")} onPress={() => {
						deleteItemGroup({
							variables: {
								input: {
									id: itemGroup
								}
							},
							updateQueries: {
								searchItemGroups: (prev, { mutationResult }) => {
									if (!mutationResult.data) {
										return prev
									}

									const deletedItemGroupId = mutationResult.data.deleteItemGroup.itemGroupId
									return {
										itemGroups: {
											...prev.itemGroups,
											itemGroups: prev.itemGroups.itemGroups.filter((itemGroup: ItemGroup) => itemGroup.id !== deletedItemGroupId)
										}
									}
								}
							}
						})
						onExit()
					}} />
				</View>
			</View>
		</Modal>
	)
}
