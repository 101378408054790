import jwt_decode from "jwt-decode"
import { BearerAuthentication } from "./authentication-types"

export function parseDoorhubToken(token: string): BearerAuthentication {
	const decoded = jwt_decode(token) as any
	return {
		type: "bearer",
		token,
		userId: decoded.userId.toString(),
		gmail: decoded.gmail,
		userName: decoded.userName,
		exp: decoded.exp,
	}
}
