import React, { useEffect, useMemo, useRef, useState } from "react"
import { View, StyleProp, ViewStyle, Pressable } from "react-native"
import { AdminViewDocument, AdminViewQuery, useDeclinePermitMutation, useGivePermitMutation, useInterruptRentingMutation, useUpdateRentingMutation } from "./queries.generated"
import { some, toString } from "lodash-es"
import MyText from "../../components/MyText"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import Base from "../../components/layout/Base"
import { useDurationToDeadline } from "../../utils/Duration"
import { useTranslation } from "react-i18next"
import { ActiveViewerDocument } from "../ActiveItems/queries.generated"
import { Button, ErrorMessage, Text, TouchButton, useTheme } from "pusatec-react-native"
import { RentingState } from "../../doorhub-types"
import { LocalDatetime } from "../../components/local-datetime"
import { AiOutlineCheck, AiOutlineClose} from "react-icons/ai"
import { AdminToolbar } from "./admin_toolbar"
import { TinderCard } from "../../components/TinderCard"
import { HeaderBar } from "../../components/Headerbar"
import { useCancelRentingMutation } from "../RentItem/queries.generated"

type Renting = NonNullable<AdminViewQuery["lockersystem"]>["activeRentings"]["rentings"][0]

export function ManageRentings(props: {
    systemId: string
    rentings: Renting[]
    onRefetch: () => void
    loading: boolean
    style?: StyleProp<ViewStyle>,
    interruptRenting: any
    updateRenting: any
    givePermit: any
    declinePermit: any
}) {
	const { systemId, rentings, loading, onRefetch, style } = props
	const { t } = useTranslation()
	const [cancelRenting] = useCancelRentingMutation()

	const rentingGroups = useMemo(() => {
		const groups: { [key: string]: Renting[] } = {}

		for (const renting of rentings) {
			if (renting.state === RentingState.Finished) {
				continue
			}

			let groupName

			if (renting.state === RentingState.NeedsPermit) {
				groupName = t("Permission required")
			} else if (renting.state === RentingState.WaitsPickup) {
				groupName = t("Waits pickup")
			} else if (renting.state === RentingState.WaitsReturn) {
				groupName = t("Waits return")
			} else if (renting.locker?.lockerGroup?.name) {
				groupName = renting.locker.lockerGroup.name
			} else {
				groupName = t("Unassigned group")
			}

			if (!groups[groupName]) {
				groups[groupName] = []
			}

			groups[groupName].push(renting)
		}

		return groups
	}, [rentings])

	const [selectedRentingId, setSelectedRentingId] = useState<string>("")

	const selectedRenting = useMemo(() => {
		return rentings.find(p => p.id === selectedRentingId)
	}, [rentings, selectedRentingId])

	const [interruptRenting, interruptRentingM] = useInterruptRentingMutation()

	return <View style={[{ flex: 1 }, style]} >
		<MainScrollPanel
			style={{ borderWidth: 0, backgroundColor: undefined }}
			onRefresh={onRefetch}
			refreshing={loading}
		>
			{Object.entries(rentingGroups).map(([key, rentings]) => (
				<View key={key} style={{ marginBottom: 10 }}>
					<HeaderBar text={key} />
					{rentings.map(renting =>
						<ManageRentingRow
							key={renting.id}
							renting={renting}
							selected={renting.id === selectedRentingId}
							onSelect={() => setSelectedRentingId(renting.id)}
							givePermit={props.givePermit}
							declinePermit={props.declinePermit}
							updateRenting={props.updateRenting}

						/>
					)}
				</View>
			))}
		</MainScrollPanel>

		<AdminToolbar>
			<View style={{ flexGrow: 1, flexDirection: "row" }}>

				{interruptRentingM.error && <ErrorMessage extra={interruptRentingM.error} />}
				{selectedRenting && selectedRenting.state !== RentingState.WaitsPickup && selectedRenting.state !== RentingState.NeedsPermit && <TouchButton
					text={t("Interrupt renting")}
					loading={interruptRentingM.loading}
					onPress={() => {
						interruptRenting({
							variables: {
								rentingId: selectedRenting.id
							},
							refetchQueries: [
								{
									query: ActiveViewerDocument,
								},
								{
									query: AdminViewDocument,
									variables: { systemId }
								}
							]
						}).then(_ => {
							setSelectedRentingId("")
						})
					}}
				/>}
				{selectedRenting && selectedRenting.state === RentingState.WaitsPickup && <TouchButton
					text={t("Cancel renting")}
					loading={interruptRentingM.loading}
					onPress={() => {
						cancelRenting({
							variables: {
								rentingId: selectedRenting.id
							},
							refetchQueries: [
								{
									query: ActiveViewerDocument,
								},
								{
									query: AdminViewDocument,
									variables: { systemId }
								}
							]
						}).then(_ => {
							setSelectedRentingId("")
						})
					}}
				/>}
			</View>
		</AdminToolbar>
	</View>
}

export function ManageRentingRow(props: {
    renting: Renting
    selected?: boolean
    givePermit: any
    onSelect?: () => void
    declinePermit: any
    updateRenting: any

}) {
	const { renting, selected, onSelect } = props
	const { t } = useTranslation()
	const [returnBefore, setReturnBefore] = useState<string | null>(null)

	const givePermitSwipe = () => {
		if(!renting.id) {
			return
		}
		props.givePermit(renting.id)
	}

	const declinePermitSwipe = () => {
		if(!renting.id) {
			return
		}
		props.declinePermit(renting.id)
	}
	const updateRenting = () => {
		if(!renting.id) {
			return
		}

		props.updateRenting({
			variables: {
				input: {
					rentingId: renting.id,
					returnItemBefore: returnBefore ? returnBefore : null
				}
			}
		})
	}

	useEffect(() => {
		if (!renting.returnItemBefore) {
			return
		}

		setReturnBefore(renting.returnItemBefore)
	}, [renting.returnItemBefore, setReturnBefore])

	const rentTimeLeft = useDurationToDeadline(
		renting.returnItemBefore ? new Date(renting.returnItemBefore) : undefined,
	)

	const overtimeState = rentTimeLeft.overtime && rentTimeLeft.duration ? (
		<MyText
			type="error"
			text={t("{{time}} overtime!", {
				time: rentTimeLeft.duration.format(t),
			})}
		/>
	) : null

	const rentingLate = renting?.returnItemBefore ?
		new Date(renting?.returnItemBefore) < new Date() :
		false

	const needsPermit = renting.state === RentingState.NeedsPermit
	const WaitsPickup = renting.state === RentingState.WaitsPickup
	return <TinderCard onSwipeLeft={declinePermitSwipe} onSwipeRight={givePermitSwipe} disabled={!needsPermit} seleted={selected}>
		<Base
			key={renting.id}
			onPress={onSelect}
			selected={selected}
			style={{
				borderRightColor: rentingLate ? "red" : "white",
				borderRightWidth: 8,
				backgroundColor: WaitsPickup ? "#CCFFCC" : "white",
			}}
		>
			<View style={{flexDirection:"column"}}  >

				<Text text={`${toString(renting.user?.name)}`} bold />
				<MyText text={`${toString(renting.user?.gmail)}`} bold />
				<View style={{ flexDirection: "row", flexWrap: "wrap" }}>

					<Text text={`${toString(renting.item?.name)}`} style={{ flexGrow: 1 }}  />

					{needsPermit &&

            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            	<Pressable onPress={() => {
            		if(!renting.id) {
            			return
            		}
            		props.declinePermit(renting.id)
            	}}>
            		<AiOutlineClose size={30} style={{marginRight:20,color: "#f54058"}} />
            	</Pressable>
            	<Pressable onPress={() => {
            		if(!renting.id) {
            			return
            		}
            		props.givePermit(renting.id)
            	}}>
            		<AiOutlineCheck size={30} style={{color:"#55c955"}} />
            	</Pressable>
            </View>}
				</View>
				{renting.locker &&
            <Text text={t("Locker {{name}}", { name: toString(renting.locker?.name) })} />}

				{selected ? <>
					{renting?.returnItemBefore
						? <Text text={t("Return before {{deadline}}")} />
						: null}

					{returnBefore ?
						<LocalDatetime
							style={{
								width: "200px",
								height: "25px"
							}}
							min={new Date()}
							datetime={returnBefore}
							onChange={d => setReturnBefore(d)}
						/>
						: null}

					{renting?.returnItemBefore != returnBefore &&
                <Button text="Update"
                	style={{ width: "100px" }}
                	onPress={() => {
                		updateRenting()
                	}} />}
					{}

					{overtimeState}
				</> : null}

			</View>
		</Base>
	</TinderCard>
}
