import React from "react"
import SelectLockerStep from "../wizards/common-steps/SelectLockerStep"
import { MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/native"
import Panel from "../../components/layout/Panel"

export const SelectLockerStepExample = (props: {
    route: RouteProp<MainStackParamList, "SelectLockerStepExample">
}) => {
	const systemId = props.route?.params?.systemId

	if (!systemId) {
		return (
			<Panel style={{ marginBottom: 0 }}>
                System id is not set
			</Panel>
		)
	}

	return (
		<SelectLockerStep
			lockersystemId={systemId}
			locker={null}
			onChange={() => {}}
			onComplete={() => {}}
			wizardState={{} as any}
		/>
	)
}