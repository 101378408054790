import React, { useState, useEffect } from "react"
import { ScrollView, View, RefreshControl, Platform, ViewStyle, StyleProp, ImageBackground } from "react-native"
import { colors } from "../../theme"

interface Props {
	onRefresh?(): void
	refreshing?: boolean
	children: any
	style?: StyleProp<ViewStyle>
}

export default function MainScrollPanel(props: Props) {
	const { onRefresh, refreshing, children, style } = props

	const [showRefreshing, setShowRefreshing] = useState<boolean>(false)

	useEffect(() => {
		setTimeout(() => setShowRefreshing(true), 300)
	}, [])

	return (
		<ImageBackground
			source={require("../../../assets/bg-texture4.png")}
			resizeMode="repeat"
			style={[{
				flex: 1,
				borderWidth: 1,
				borderColor: "rgba(0,0,0,0.1)",
			}, style]}
		>
			<ScrollView
				style={{ flex: 1, }}
				refreshControl={
					onRefresh ? (
						<RefreshControl
							colors={[colors.accent]}
							refreshing={refreshing && showRefreshing ? true : false}
							onRefresh={onRefresh || null}
						/>
					) : undefined
				}
			>
				<View
					style={{
						height: Platform.OS === "web" ? 0 : undefined
					}}
				>
					<View style={{
						padding: 10,
						paddingBottom: 80,
					}}>
						{children}

					</View>
				</View>
			</ScrollView>
		</ImageBackground>
	)
}
