import { $createParagraphNode, $createTextNode, $getRoot, $getSelection, $isRangeSelection, $setSelection, DecoratorNode, EditorState, ElementNode, FORMAT_TEXT_COMMAND, LexicalNode, NodeKey, ParagraphNode, RangeSelection, RootNode, SELECTION_CHANGE_COMMAND, TextNode, } from "lexical"
import { Fragment, ReactNode, useCallback, useState } from "react"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { RichTextPlugin, } from "@lexical/react/LexicalRichTextPlugin"
import { BiBold, BiItalic, BiUnderline, } from "react-icons/bi"
import "./RichTextEditor.css"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import {
	TRANSFORMERS,
} from "@lexical/markdown"
import { $createHeadingNode } from "@lexical/rich-text"
import { exampleTheme } from "./RichTextEditorTheme"
import { parseRichHtml, rootNodeToRichHtml } from "../utils/richtext"
import { richTextToLexical } from "../utils/richtext"
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin"
import { $wrapNodes } from "@lexical/selection"
import { HeadingNode, } from "@lexical/rich-text"
import { RiFontSize, RiH1, RiH2 } from "react-icons/ri"
import { Button } from "pusatec-react-native"
import { TreeView } from "@lexical/react/LexicalTreeView"
import { devConfig } from "../../dev-config"
import { useTranslation } from "react-i18next"

const URL_MATCHER = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
const EMAIL_MATCHER = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

const MATCHERS = [
	(text) => {
		const match = URL_MATCHER.exec(text)
		return (
			match && {
				index: match.index,
				length: match[0].length,
				text: match[0],
				url: match[0]
			}
		)
	},
	(text) => {
		const match = EMAIL_MATCHER.exec(text)
		return (
			match && {
				index: match.index,
				length: match[0].length,
				text: match[0],
				url: `mailto:${match[0]}`
			}
		)
	}
]

const TreeViewPlugin = () => {
	const [editor] = useLexicalComposerContext()
	return (
		<TreeView
			treeTypeButtonClassName='lol'
			viewClassName="tree-view-output"
			timeTravelPanelClassName="debug-timetravel-panel"
			timeTravelButtonClassName="debug-timetravel-button"
			timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
			timeTravelPanelButtonClassName="debug-timetravel-panel-button"
			editor={editor}
		/>
	)
}

const ToolbarButton = (props: {
	active: boolean
	icon: any
	onPress: () => void
}) => {
	return (
		<Button icon={props.icon}
			containerStyle={{
				width: "35px",
				height: "35px",
				margin: "3px",
				backgroundColor: props.active ? "#cccccc" : "white"
			}} onPress={props.onPress} />
	)
}

const ToolbarPlugin = () => {
	const [editor] = useLexicalComposerContext()

	let boldActive = false
	let italicActive = false
	let underlineActive = false
	let h1 = false
	let h2 = false
	let p = false
	editor.getEditorState().read(() => {
		const selection = $getSelection() as RangeSelection
		if (!selection) {
			return
		}

		if (!selection.getNodes) {
			return
		}

		boldActive = selection.hasFormat("bold")
		italicActive = selection.hasFormat("italic")
		underlineActive = selection.hasFormat("underline")

		const firstNode = selection.getNodes()[0]

		if (firstNode instanceof HeadingNode) {
			if (firstNode.getTag() === "h1") {
				h1 = true
				return
			}
			if (firstNode.getTag() === "h2") {
				h2 = true
				return
			}
		} else if (firstNode instanceof ParagraphNode) {
			p = true
			return
		}

		let parent = firstNode.getParent()

		if (parent instanceof AutoLinkNode) {
			parent = parent.getParent()
		}

		if (parent instanceof HeadingNode) {
			if (parent.getTag() === "h1") {
				h1 = true
			}
			if (parent.getTag() === "h2") {
				h2 = true
			}
		} else if (parent instanceof ParagraphNode) {
			p = true
		}
	})

	const font = useCallback((type: "h1" | "h2" | "p") => {
		editor.update(() => {
			const root = $getRoot()
			const selection = $getSelection() as RangeSelection

			let lexicalNode: any

			if (type === "h1") {
				lexicalNode = $createHeadingNode("h1")
			} else if (type === "h2") {
				lexicalNode = $createHeadingNode("h2")
			} else if (type === "p") {
				lexicalNode = $createParagraphNode()
			}

			if (selection) {
				const firstNode = selection.getNodes()[0]
				if (firstNode instanceof TextNode) {
					if ($isRangeSelection(selection)) {
						$wrapNodes(selection, () => lexicalNode)
					}
				} else {
					selection.insertNodes([lexicalNode])
				}
			} else {
				root.append(lexicalNode)
			}
		})
	}, [editor])

	const containerStyle2 = {
		display: "flex",
		alignItems: "center"
	}

	return (
		<div style={containerStyle2} >
			<ToolbarButton icon={RiFontSize} active={p} onPress={() => font("p")} />
			<ToolbarButton icon={RiH1} active={h1} onPress={() => font("h1")} />
			<ToolbarButton icon={RiH2} active={h2} onPress={() => font("h2")} />
			<ToolbarButton icon={BiBold} active={boldActive} onPress={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")} />
			<ToolbarButton icon={BiItalic} active={italicActive} onPress={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")} />
			<ToolbarButton icon={BiUnderline} active={underlineActive} onPress={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")} />
		</div>
	)
}

export function createRichHtmlImport(
	transformers: Array<Transformer>,
): (markdownString: string, node?: ElementNode) => void {
	return (richHtml, node) => {
		const root = node || $getRoot()

		if (!richHtml) {
			root.append($createParagraphNode())
			return
		}

		const richHtmlObj = parseRichHtml(richHtml)
		const nodes = richTextToLexical(richHtmlObj)

		if (nodes.length === 0) {
			root.append($createParagraphNode())
			return
		}

		for (const n of nodes) {
			if (n.type === "p") {
				const p = $createParagraphNode()
				for (const child of n.children) {
					const textNode = $createTextNode()
					textNode.setTextContent(child.text)

					if (child.bold) {
						textNode.setFormat("bold")
					}

					if (child.italic) {
						textNode.setFormat("italic")
					}

					if (child.underline) {
						textNode.setFormat("underline")
					}
					p.append(textNode)
				}
				root.append(p)
			}

			if (n.type === "h1" || n.type === "h2") {
				const p = $createHeadingNode(n.type)
				for (const child of n.children) {
					const textNode = $createTextNode()
					textNode.setTextContent(child.text)

					if (child.bold) {
						textNode.setFormat("bold")
					}

					if (child.italic) {
						textNode.setFormat("italic")
					}

					if (child.underline) {
						textNode.setFormat("underline")
					}
					p.append(textNode)
				}
				root.append(p)
			}
		}
	}
}

const $convertFromRichHtml = (
	text: string,
	transformers: any,
	node?: ElementNode,
): void => {
	const importRichHtml = createRichHtmlImport(transformers)
	return importRichHtml(text, node)
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
	console.error(error)
}

const Inner = (props: {
	richHtml: string
	lastMarkdown: string
	lastMarkdownChanged: () => void
	onChange: (markdown: string) => void
}) => {
	const onChange = useCallback((editorState: EditorState) => {
		editorState.read(() => {
			let root = $getRoot()
			const finalText = rootNodeToRichHtml(root)
			props.onChange(finalText)
		})
	}, [])

	return (
		<LexicalComposer initialConfig={{
			namespace: "MyEditor",
			onError,
			theme: exampleTheme,
			editorState: () => $convertFromRichHtml(props.richHtml, TRANSFORMERS),
			nodes: [
				AutoLinkNode,
				LinkNode,
				HeadingNode
			]
		}}>
			<div style={{
				border: "solid 1px rgba(0, 0, 0, 0.3)",
				borderBottom: "none"
			}}>
				<ToolbarPlugin />
			</div>
			<div style={{
				border: "solid 1px rgba(0, 0, 0, 0.3)",
				outline: "none",
				padding: 0
			}}>
				<RichTextPlugin
					contentEditable={<ContentEditable style={{
						margin: 0,
						minHeight: 100,
					}} />}
					placeholder={<div></div>}
					ErrorBoundary={LexicalErrorBoundary}
				/>
			</div>
			<AutoLinkPlugin matchers={MATCHERS} />
			<OnChangePlugin onChange={onChange} />
			{devConfig.RICH_TEXT_EDITOR_TREE_VIEW ? <TreeViewPlugin /> : <Fragment />}
			<AutoFocusPlugin />
		</LexicalComposer>
	)
}

export const RichTextEditor = (props: {
	markdown: string
	onChange: (newMarkdown: string) => void
}) => {
	const [lastMarkdown, setLastMarkdow] = useState("")

	return (
		<Inner
			richHtml={props.markdown}
			lastMarkdown={lastMarkdown}
			lastMarkdownChanged={() => {
				setLastMarkdow(props.markdown)
			}}
			onChange={props.onChange}
		/>
	)
}

type TemplatePart = { type: "text" | "tag"; text: string };

export const parseTemplate = (template: string): TemplatePart[] => {
	// Regular expression to match the placeholders
	const regex = /{([^\}]+)}/g;
	let result: TemplatePart[] = [];
	let lastIndex = 0;

	// Match all placeholders
	let match;
	while ((match = regex.exec(template)) !== null) {
		// Add the text before the placeholder as 'text' type if it exists
		if (match.index > lastIndex) {
			result.push({ type: "text", text: template.slice(lastIndex, match.index) });
		}

		// Add the placeholder as 'tag' type
		result.push({ type: "tag", text: match[0] });

		// Keep track of the index where the last match ended
		lastIndex = match.index + match[0].length;
	}

	// Add any text after the last placeholder as 'text' type
	if (lastIndex < template.length) {
		result.push({ type: "text", text: template.slice(lastIndex) });
	}

	return result;
}

export const $convertFromTemplate = (text: string): void => {
	const root = $getRoot()

	if (!text) {
		root.append($createParagraphNode())
		return
	}

	const parts = parseTemplate(text)

	if (parts.length === 0) {
		root.append($createParagraphNode())
		return
	}

	const p = $createParagraphNode()

	for (const n of parts) {
		if (n.type === "text") {
			const textNode = $createTextNode()
			textNode.setTextContent(n.text)
			p.append(textNode)
		}

		if (n.type === "tag") {
			const varNode = $createVariableNode(n.text)
			p.append(varNode)
		}
	}

	root.append(p)
}

const VariableName = (props: {
	variableName: string
}) => {
	const { t } = useTranslation()

	return (
		<Fragment>
			{t(props.variableName)}
		</Fragment>
	)
}

export class VariableNode extends DecoratorNode<ReactNode> {
	private variableName: string;

	static getType(): string {
		return 'template-variable';
	}

	static clone(node: VariableNode): VariableNode {
		return new VariableNode(node.variableName, node.__key);
	}

	constructor(variable: string, key?: NodeKey) {
		super(key);
		this.variableName = variable;
	}

	public getTextContent(): string {
		return this.variableName;
	}

	public isKeyboardSelectable(): boolean {
		return false;
	}

	createDOM(): HTMLElement {
		const el = document.createElement("span");
		el.style.border = "solid 1px rgba(0, 0, 0, 0.3)"
		el.style.paddingLeft = "2px"
		el.style.paddingRight = "2px"
		el.style.color = "red"
		return el
	}

	updateDOM(): false {
		return false;
	}

	decorate(): ReactNode {
		return <VariableName variableName={this.variableName} />;
	}
}

export const $createVariableNode = (text: string): DecoratorNode<ReactNode> => {
	return new VariableNode(text.replace("{", "").replace("}", ""))
}

export const rootNodeToText = (root: RootNode): string => {
	let finalText = ""

	for (const [inx, child] of root.getChildren().entries()) {
		if (child instanceof ParagraphNode) {
			for (const child2 of child.getChildren()) {
				if (child2 instanceof TextNode) {
					finalText += child2.getTextContent()
				}

				if (child2 instanceof VariableNode) {
					finalText += `{${child2.getTextContent()}}`
				}
			}

			if (inx < root.getChildren().length - 1) {
				finalText += "\n"
			}
		}		
	}
	return finalText
}