import React from "react"
import { Text } from "pusatec-react-native"
import { colors, theme } from "../theme"
import { TextStyle } from "react-native"

export type MyTextType = "h1" | "h2" | "normal" | "error" | "small" | "label" | "strong"

interface Props {
	text: string | null | undefined
	type: MyTextType
	bold?: boolean
	style?: TextStyle
}

function MyText(props: Props) {
	let { type, bold, text } = props

	let style = {
		color: "black",
		fontSize: 16,
		margin: 2,
		textAlign: "left" as "left" | "center",
		fontFamily: "Nunito, Mada-Regular, sans-serif",
		fontWeight: bold ? "bold" as "bold" : undefined
	}

	if (type === "error") {
		style.color = colors.error
	}

	if (type === "h1") {
		style.fontSize = 25
	}

	if (type === "h2") {
		style.fontSize = 21
	}

	if (type === "small") {
		style.fontSize = 12
	}

	if (type === "label") {
		style = Object.assign(style, theme.Input.labelStyle)
	}

	if (type === "strong") {
		text = text?.toUpperCase()
		style.fontWeight = "bold"
		style.fontFamily = "Libre Franklin"
		style.color = "#333"
	}

	if (bold) style.fontFamily = "Nunito, Mada-Medium, sans-serif"
	if (props.style) style = Object.assign(style, props.style)

	return <Text style={style} text={text} />
}

MyText.defaultProps = { type: "normal" }

export default MyText
