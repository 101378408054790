import React from "react"
import { WizardState } from "../use-wizard"
import WizardStep from "../WizardStep"
import { useTranslation } from "react-i18next"
import { SelectLockerQuery, useSelectLockerQuery } from "./queries.generated"
import MainScrollPanel from "../../../components/layout/MainScrollPanel"
import Base from "../../../components/layout/Base"
import { View } from "react-native"
import LockerButton from "../../../components/form-inputs/LockerButton"
import Panel from "../../../components/layout/Panel"
import { ErrorMessage, TouchButton, Text } from "pusatec-react-native"
import { BackIcon } from "../../../components/BackIcon"
import { LockerUnit } from "../../../doorhub-types"

type LockerNode = NonNullable<SelectLockerQuery["lockersystem"]>["lockerGroups"][number]["lockerUnits"][number]["lockerNodes"][number]
const unitWidth = 200
const maxHeight = 500
const defaultHeight = 45
const defaultWidth = 70
export interface LockerSelection {
	id: string
	name: number
	groupId: string
}

interface Props {
	wizardState: WizardState
	locker: LockerSelection | null
	lockersystemId: string
	onChange(locker: LockerSelection): void
	onComplete(locker: LockerSelection): void
	onCancel?(): void
	itemWasJustCreated?: boolean
}

export default function SelectLockerStep(props: Props) {
	const {
		locker,
		onChange,
		onComplete,
		onCancel,
		lockersystemId,
		wizardState,
	} = props

	const { t } = useTranslation()

	const { error, loading, data, refetch } = useSelectLockerQuery({
		variables: { lockersystemId },
	})
	const lockerGroups = data?.lockersystem?.lockerGroups

	return (
		<WizardStep title={t("")} wizardState={wizardState}>
			<div style={{
				fontFamily: "Nunito, Mada-Regular, sans-serif",
				fontSize: 25,
				textAlign: "center"
			}}>
				{t("Select locker")}
			</div>
			<MainScrollPanel onRefresh={refetch} refreshing={loading}>
				{error && <ErrorMessage extra={error} />}
				{lockerGroups?.map(group => {
					if (!group || !group.lockers || group.lockers.length == 0) {
						return null
					}

					return (
						<View key={group.id} style={{ margin: 10 }} >
							<Text text={group.name} />
							{group?.lockers && (
								<Base>
									<View
										style={{
											flexDirection: "row",
											overflow: "auto",
										}}
									>
										{group?.lockerUnits?.map((lockerUnit) => (
											<View key={lockerUnit.id} style={{ padding: 5 }}>
												<LockerNodemap
													lockerUnit={lockerUnit}
													lockerGroupid={group.id}
													selected={locker?.name}
													onChange={onChange}													
												/>
											</View>
										))}
									</View>
									<View style={{
										flexDirection: "row",
										flexWrap: "wrap",
										justifyContent: "center",
									}}>							
										{group.lockerUnits.length === 0 && group?.lockers?.map((_locker) => (											
											<LockerButton
												key={_locker!.id}
												lockerNumber={_locker!.name!}
												terminal={_locker!.terminal!}
												onSelect={() =>
													onChange({
														groupId: group.id,
														id: _locker!.id,
														name: _locker!.name!,
													})}
												available={_locker!.item === null && _locker!.delivery === null}
												selected={_locker!.name === locker?.name}
												width={defaultWidth}
												height={defaultHeight}
											/>
										))}
									</View>
									
								</Base>
							)}
						</View>
					)
				})}
			</MainScrollPanel>
			<Panel style={{ flexDirection: "row", justifyContent: "space-between" }}>
				{onCancel && (
					<TouchButton
						type="secondary"
						text={t("Go back")}
						icon={BackIcon}
						onPress={onCancel}
					/>
				)}
				<TouchButton
					text={t("Next")}
					type="primary"
					disabled={!locker}
					onPress={onComplete.bind(null, locker!)}
				/>
			</Panel>
		</WizardStep>
	)
}

const LockerNodemap = (props: {
		selected?: number
		lockerGroupid : string
		onChange(locker: LockerSelection): void
		lockerUnit : LockerUnit	
}) => {
	props.lockerUnit.lockerNodes

	let totalHeight = 0

	for (const node of props.lockerUnit.lockerNodes) {
		if (node.leftUpLocker) {
			totalHeight += node.leftUpLocker.height || 0
		}

		if (node.leftDownLocker) {
			totalHeight += node.leftDownLocker.height || 0
		}
	}

	return (
		<View style={{ height: maxHeight, marginRight: "5px" }}>
			{props.lockerUnit.lockerNodes.map(lockerNode => {
				let nodeHeight = 0

				if (lockerNode.leftUpLocker) {
					nodeHeight += lockerNode.leftUpLocker.height || 0
				}

				if (lockerNode.leftDownLocker) {
					nodeHeight += lockerNode.leftDownLocker.height || 0
				}

				const leftUpLocker = lockerNode.leftUpLocker
				const rightUpLocker = lockerNode.rightUpLocker
				const leftDownLocker = lockerNode.leftDownLocker
				const rightDownLocker = lockerNode.rightDownLocker

				return (
					<View>
						<View style={{ display: "flex", flexDirection: "row" }}>
							{leftUpLocker && <LockerButton
								lockerNumber={leftUpLocker.name}
								terminal={leftUpLocker.terminal ?? false}
								onSelect={() => props.onChange({
									groupId: props.lockerGroupid,
									id: leftUpLocker.id,
									name: leftUpLocker.name!,
								})}
								available={leftUpLocker.item === null && leftUpLocker.delivery === null}
								selected={leftUpLocker.name === props.selected}
								width={unitWidth / 2}
								height={leftUpLocker.height ? leftUpLocker.height / totalHeight * maxHeight : defaultHeight}

							/>}
							{rightUpLocker && <LockerButton
								lockerNumber={rightUpLocker.name}
								terminal={rightUpLocker.terminal ?? false}
								onSelect={() => props.onChange({
									groupId: props.lockerGroupid,
									id: rightUpLocker.id,
									name: rightUpLocker.name!,
								})}
								available={rightUpLocker.item === null && rightUpLocker.delivery === null}
								selected={rightUpLocker.name === props.selected}
								width={unitWidth / 2}
								height={rightUpLocker.height ? rightUpLocker.height / totalHeight * maxHeight : defaultHeight}

							/>}
						</View>
						<View style={{ display: "flex", flexDirection: "row" }}>
							{leftDownLocker && <LockerButton
								lockerNumber={leftDownLocker.name}
								terminal={leftDownLocker.terminal ?? false}
								onSelect={() => props.onChange({
									groupId: props.lockerGroupid,
									id: leftDownLocker.id,
									name: leftDownLocker.name!,
								})}
								available={leftDownLocker.item === null && leftDownLocker.delivery === null}
								selected={leftDownLocker.name === props.selected}
								width={unitWidth / 2}
								height={leftDownLocker.height ? leftDownLocker.height / totalHeight * maxHeight : defaultHeight}

							/>}
							{rightDownLocker && <LockerButton
								lockerNumber={rightDownLocker.name}
								terminal={rightDownLocker.terminal ?? false}
								onSelect={() => props.onChange({
									groupId: props.lockerGroupid,
									id: rightDownLocker.id,
									name: rightDownLocker.name!,
								})}
								available={rightDownLocker.item === null && rightDownLocker.delivery === null}
								selected={rightDownLocker.name === props.selected}
								width={unitWidth / 2}
								height={rightDownLocker.height ? rightDownLocker.height / totalHeight * maxHeight : defaultHeight}

							/>}
						</View>
					</View>
				)
			})}
		</View>
	)
}