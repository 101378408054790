import React from "react"
import { createStackNavigator, } from "@react-navigation/stack"
import ActiveItemsScreen from "./screens/ActiveItems/ActiveItemsScreen"
import BrowseSystemsScreen from "./screens/BrowseSystems/BrowseSystems"
import ViewRentingScreen from "./screens/ViewRenting/ViewRenting"
import RentItemScreen from "./screens/RentItem/Index"
import ViewItemScreen from "./screens/ViewItem/ViewItem"
import SettingsScreen from "./screens/Settings/SettingsScreen"
import ReturnRentingScreen from "./screens/ReturnRenting/Index"
import PickupDeliveryScreen from "./screens/PickupDelivery/Index"
import ViewDeliveryScreen from "./screens/ViewDelivery/ViewDelivery"
import BrowseItemsScreen from "./screens/BrowseItems/BrowseItems"
import ReportIssueScreen, {
	IssueSituation,
} from "./screens/ReportIssue/ReportIssue"
import { RemoveItemFromLockerScreen } from "./screens/Admin/RemoveItemFromLocker"
import { CreateDeliveryWizard } from "./screens/wizards/create-delivery/CreateDelivery"
import { AdminNavigation } from "./screens/Admin/AdminNavigation"
import { JoinLockersystemScreen } from "./screens/Settings/JoinLockersystemScreen"
import { NavigationContainer, useNavigation } from "@react-navigation/native"
import { TouchButton } from "pusatec-react-native"
import { PlaceItemToLocker } from "./screens/wizards/place-item-to-locker/PlaceItemToLocker"
import { InterruptDeliveryScreen } from "./screens/Admin/InterruptDeliveryScreen"
import { CreateItemScreen } from "./screens/Admin/CreateItemScreen"
import { EditItemScreen } from "./screens/Admin/EditItemScreen"
import { AllowRentingWizard } from "./screens/Admin/AllowRentingWizard"
import { ViewLockerScreen } from "./screens/Admin/ViewLockerScreen"
import { SelectLockerStepExample } from "./screens/examples/SelectLockerStep"
import { ManageRentingsExample } from "./screens/examples/ManageRentings"
import {AiOutlineHome } from "react-icons/ai"

export type MainStackParamList = {
	ActiveItems: undefined
	JoinLockersystem: undefined

	BrowseSystems: undefined
	BrowseItems: { systemId: string }
	ViewItem: { itemId: string }
	RentItem: { rentingId: string }

	ViewRenting: { rentingId: string }
	ReturnRenting: { rentingId: string }

	ViewDelivery: { deliveryId: string }
	PickupDelivery: { deliveryId: string }

	CreateItem: { systemId: string }
	EditItem: { itemId: string }
	PlaceItemToLocker: { itemId: string, itemWasJustCreated?: boolean }
	RemoveItemFromLocker: { itemId: string }
	CreateDelivery: { systemId?: string }
	InterruptDelivery: { deliveryId: string }
	AllowRenting: { systemId: string, itemId: string }
	ViewLocker: { lockerId: string }
	SelectLockerStepExample: { systemId: string }
	ManageRentingsExample: { systemId: string }

	AdminNavigation: {
		systemId: string
		pageIndex?: number
	}

	Settings: undefined
	ReportIssue: {
		situation: IssueSituation
		lockerId?: string
		deliveryId?: string
		rentingId?: string
		lockersystemId?: string
		lockerGroupId?: string
	}
}

const MainStack = createStackNavigator<MainStackParamList>()

export function MainNavigation() {
	return (
		<NavigationContainer
			linking={{
				prefixes: ["http://localhost:19006", "https://pt-doorhub-dev.web.app/"],
			}}

			documentTitle={{
				enabled: true,
				formatter: (options, route) => `${options?.title ?? route?.name} | locker.fi`
			}}
		>
			<MainStack.Navigator
				initialRouteName="ActiveItems"
				screenOptions={{
					header: () => null,
					cardStyle: {
						backgroundColor: "#FAFAFA",
					},
				}}
			>
				<MainStack.Screen name="ActiveItems" component={ActiveItemsScreen} />
				<>
					<MainStack.Screen
						name="BrowseSystems"
						component={BrowseSystemsScreen}
					/>
					<MainStack.Screen
						name="BrowseItems"
						component={BrowseItemsScreen}
					/>
					<MainStack.Screen name="ViewItem" component={ViewItemScreen} />
					<MainStack.Screen name="RentItem" component={RentItemScreen} />
				</>
				<>
					<MainStack.Screen
						name="ViewRenting"
						component={ViewRentingScreen}
					/>
					<MainStack.Screen
						name="ReturnRenting"
						component={ReturnRentingScreen}
					/>
				</>
				<>
					<MainStack.Screen
						name="ViewDelivery"
						component={ViewDeliveryScreen}
					/>
					<MainStack.Screen
						name="PickupDelivery"
						component={PickupDeliveryScreen}
					/>
				</>
				<>
					<MainStack.Screen
						name="CreateItem"
						component={CreateItemScreen}
					/>
					<MainStack.Screen
						name="RemoveItemFromLocker"
						component={RemoveItemFromLockerScreen}
					/>
					<MainStack.Screen
						name="CreateDelivery"
						component={CreateDeliveryWizard}
					/>
					<MainStack.Screen
						name="AllowRenting"
						component={AllowRentingWizard}
					/>
					<MainStack.Screen
						name="InterruptDelivery"
						component={InterruptDeliveryScreen}
					/>
					<MainStack.Screen
						name="PlaceItemToLocker"
						component={PlaceItemToLocker}
					/>
					<MainStack.Screen
						name="JoinLockersystem"
						component={JoinLockersystemScreen}
					/>
					<MainStack.Screen
						name="EditItem"
						component={EditItemScreen}
					/>
					<MainStack.Screen
						name="ViewLocker"
						component={ViewLockerScreen}
					/>
					<MainStack.Screen
						name="SelectLockerStepExample"
						component={SelectLockerStepExample}
					/>
					<MainStack.Screen
						name="ManageRentingsExample"
						component={ManageRentingsExample}
					/>
				</>
				<MainStack.Screen name="Settings" component={SettingsScreen} />
				<MainStack.Screen name="AdminNavigation" component={AdminNavigation} />
				<MainStack.Screen name="ReportIssue" component={ReportIssueScreen} />
			</MainStack.Navigator>
		</NavigationContainer >
	)
}

export function goBack(navigation: any) {
	if (navigation.canGoBack()) {
		navigation.goBack()
	} else {
		navigation.replace("ActiveItems")
	}
}

export function goToRoot(navigation: any) {
	navigation.reset({
		routes: [
			{ name: "ActiveItems" },
		],

	})
}

export function BackButton(props: { align?: "flex-start" | "flex-end" }) {
	const navigation = useNavigation()

	return <TouchButton
		type="secondary"
		icon={AiOutlineHome}
		onPress={() => goBack(navigation)}
		containerStyle={{
			alignSelf: props.align ?? "flex-start"
		}} text={""}	/>
}
