import React, { useEffect, useContext, useMemo } from "react"
import { ActiveViewerQuery, useActiveViewerQuery } from "./queries.generated"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import { Image, View } from "react-native"
import RentingLink from "./RentingLink"
import DeliveryLink from "./DeliveryLink"
import { GettingStartedInfo } from "./GettingStartedInfo"
import { useTranslation } from "react-i18next"
import Panel from "../../components/layout/Panel"
import { NotificationContext } from "../../services/cloud-messaging/notification-context"
import {StackNavigationProp } from "@react-navigation/stack"
import { MainStackParamList } from "../../MainNavigation"
import { ErrorMessage, Text, TouchButton } from "pusatec-react-native"
import { RentingState } from "../../doorhub-types"
import { FiSettings } from "react-icons/fi"
import { HeaderBar } from "../../components/Headerbar"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "ActiveItems">
}

type Renting = NonNullable<NonNullable<ActiveViewerQuery["viewer"]>["user"]>["activeRentings"]["activeRentings"][0]

export default function ActiveItemsScreen(props: Props) {
	const { navigation } = props

	const { notification } = useContext(NotificationContext)
	const { t } = useTranslation()

	const { data, loading, error, refetch } = useActiveViewerQuery({
		fetchPolicy: "cache-and-network"
	})

	const lockersystems = data?.viewer?.user?.lockersystems.lockersystems
	const rentings = data?.viewer?.user?.activeRentings.activeRentings ?? []

	const rentingGroups = useMemo(() => {
		const groups: { [key: string]: Renting[] } = {}

		for (const renting of rentings) {
			let group = groups[renting.state]

			if (!group) {
				group = []
				groups[renting.state] = group
			}

			group.push(renting)
		}

		return groups
	}, [rentings])

	const myDeliveries = useMemo(() => {
		if (!data?.viewer?.user?.activeDeliveries) {
			return []
		}

		return data?.viewer?.user?.activeDeliveries.deliveries.filter(
			p => p.userDeliveredId === data.viewer?.user?.id
		)
	}, [data?.viewer?.user?.activeDeliveries])

	const otherDeliveries = useMemo(() => {
		if (!data?.viewer?.user?.activeDeliveries) {
			return []
		}

		return data?.viewer?.user?.activeDeliveries.deliveries.filter(
			p => p.userDeliveredId !== data.viewer?.user?.id
		)
	}, [data?.viewer?.user?.activeDeliveries])

	useEffect(() => {
		if (notification) refetch()
	}, [notification?.messageId])

	const notInAnySystem = lockersystems && lockersystems.length === 0
	const activeItemsExist = rentings.length > 0 || otherDeliveries.length > 0 || myDeliveries.length > 0

	return (
		<>
			<MainScrollPanel refreshing={loading} onRefresh={refetch}>

				{!loading && !activeItemsExist
					? <>
						{notInAnySystem
							? <GettingStartedInfo />
							: (
								<>
									<Text
										style={{ textAlign: "center", margin: 20, fontSize: 30 }}
										text={t("No rentings or deliveries")}
									/>
									<Image
										style={{
											width: "100%",
											height: 200,
											margin: 20,
											alignSelf: "center",
											opacity: 0.2,
										}}
										resizeMode="contain"
										source={require("../../../assets/icons/679902-ecommerce/svg/075-package-2.svg")}
									/>
								</>
							)}
					</> : null}

				{error && <ErrorMessage extra={error} />}

				{Object.entries(rentingGroups).map(([state, rentings]) => (
					<View key={state}>
						<HeaderBar text={t(state)} />
						{rentings.map((renting) => (
							<RentingLink
								key={renting.id}
								renting={renting}
								onPress={() => {
									if (renting.state === RentingState.WaitsPickup) {
										navigation.push("RentItem", {
											rentingId: renting.id
										})

										return
									}

									if (renting.state === RentingState.WaitsReturn) {
										navigation.push("ReturnRenting", {
											rentingId: renting.id
										})

										return
									}

									navigation.push("ViewRenting", {
										rentingId: renting.id,
									})
								}}
							/>
						))}
					</View>
				))}

				{myDeliveries.length > 0 &&(
					<View style={{ marginTop: 20 }}>
						<HeaderBar text={t("My deliveries")} />
						{myDeliveries.map(delivery => (
							<DeliveryLink
								key={delivery.id}
								delivery={delivery}
								onPress={() =>
									navigation.push("ViewDelivery", {
										deliveryId: delivery.id,
									})}
							/>
						))}
					</View>
				)}

				{otherDeliveries.length > 0 && (
					<View style={{ marginTop: 20 }}>
						<HeaderBar text={t("Incoming deliveries")} />
						{otherDeliveries.map((delivery) => (
							<DeliveryLink
								key={delivery.id}
								delivery={delivery}
								onPress={() =>
									navigation.push("ViewDelivery", {
										deliveryId: delivery.id,
									})}
							/>
						))}
					</View>
				)}

			</MainScrollPanel>
			<Panel style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", alignContent: "flex-end" }}>
			   <TouchButton
					icon={FiSettings}
					onPress={() => {
						navigation.push("Settings")
					} } text={""}			   />

			  <View style={{ flexGrow: 1 }}>
				
					{notInAnySystem === false && (
						<>
							<TouchButton
								text={t("New delivery")}
								type="primary"
								onPress={() => {
									navigation.push("CreateDelivery", {
										systemId: (lockersystems && lockersystems.length === 1)
											? lockersystems[0].id
											: undefined,
									})
								}}
							/>
							<TouchButton
								text={t("Rent new item")}
								type="primary"
								onPress={() => {
									if (lockersystems && lockersystems.length === 1) {
										navigation.push("BrowseItems", { systemId: lockersystems[0].id })
									} else {
										navigation.push("BrowseSystems")
									}
								}}
							/>
						</>
					)}
				</View>				
			</Panel>
		</>
	)
}