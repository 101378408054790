import React from "react"
import { View } from "react-native"
import MyText from "../MyText"
import DataTransmission from "./DataTransmission"
import { useDurationToDeadline } from "../../utils/Duration"
import { useTranslation } from "react-i18next"
import { Text } from "pusatec-react-native"

interface Props {
	code: string
	useCodeBefore: string | null
	onCodeExpired?(): void
	onDebugConfirmed?(): void
}

export default function ConfirmLockerAction(props: Props) {
	const { code, useCodeBefore, onCodeExpired, onDebugConfirmed } = props

	const { t } = useTranslation()

	const timeToUseCode = useDurationToDeadline(new Date(useCodeBefore ?? 0), 1000)
	if (useCodeBefore && timeToUseCode.overtime && onCodeExpired) onCodeExpired()

	return (
		<View
			style={{
				flex: 1,
				alignItems: "center",
				margin: 10,
			}}
		>
			<Text
				text={t("Input code into the lockers terminal")}
				style={{ textAlign: "center" }}
			/>

			<View
				style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Text text={code} style={{ fontSize: 60, marginBottom: 10 }} />
				<DataTransmission data={code} />
				{useCodeBefore
					? <Text
						text={timeToUseCode.duration.format(t)}
						style={{ marginTop: 10 }}
					/>
					: null}
			</View>
		</View>
	)
}
