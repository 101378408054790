import { gql } from "@apollo/client"
import { useDebouncedState } from "pusatec-react-utility"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import {  Text } from "pusatec-react-native"
import { Button, SearchBar } from "react-native-elements"
import { theme } from "../theme"
import { useCreateItemGroupMutation, useSearchItemGroupsQuery } from "./queries.generated"
import Base from "./layout/Base"

export const ItemGroupList = (props: {
    lockersystemId: string;
    selectedItemGroupId?: string | null;
    searchWord?: string;
    onItemGroupidChanged: (itemGroupId: string) => void;
}) => {
	const { t } = useTranslation()

	const { data, refetch } = useSearchItemGroupsQuery({
		variables: {
			opt: {
				lockersystemId: props.lockersystemId,
				searchWord: props.searchWord
			}
		}
	})

	return (
		<View>
			{data?.itemGroups.itemGroups.map(it => {
				return (
					<Base key={it.id} selected={props.selectedItemGroupId == it?.id} onPress={() => {
						props.onItemGroupidChanged(it?.id)
					}}>
						<Text key={it?.id} text={it?.name} bold />
					</Base>
				)
			})}
			{data?.itemGroups.itemGroups.length == 0 && (
				<Text text={t("No matching item groups")} />
			)}
		</View>
	)
}

export const ItemGroupSearch = (props: {
    lockersystemId: string;
    selectedItemGroupId: string;
    onItemGroupidChanged: (itemGroupId: string) => void;
}) => {
	const { t } = useTranslation()

	const [itemGroupSearch, itemGroupSearchd, setItemGroupSearch] = useDebouncedState("", 400)

	const [createItemGroup] = useCreateItemGroupMutation()

	return (
		<View>
			<SearchBar
				lightTheme
				inputStyle={theme.Input.inputStyle}
				errorStyle={theme.Input.errorMessageStyle}
				labelStyle={theme.Input.labelStyle}
				containerStyle={theme.Input.containerStyle}
				inputContainerStyle={theme.Input.inputContainerStyle}
				value={itemGroupSearch}
				onChangeText={text => setItemGroupSearch(text)}
			/>
			<ItemGroupList
				lockersystemId={props.lockersystemId}
				searchWord={itemGroupSearchd}
				selectedItemGroupId={props.selectedItemGroupId}
				onItemGroupidChanged={props.onItemGroupidChanged}
			/>
			{itemGroupSearch &&
            <Button
            	title={t("Createitem group")}
            	onPress={() => {
            		createItemGroup({
            			variables: {
            				input: {
            					lockersystemId: props.lockersystemId,
            					name: itemGroupSearch,
            				}
            			},
            			updateQueries: {
            				SearchItemGroups: (prev, { mutationResult }) => {
            					if (!mutationResult.data) return prev
            					const newItemGroup = mutationResult.data.createItemGroup.itemGroup
            					if (!newItemGroup) return prev
            					return Object.assign({}, prev, {
            						itemGroups: {
            							itemGroups: [...prev.itemGroups.itemGroups, newItemGroup],
            							__typename: "ItemGroupList",
            						}
            					})
            				}
            			}
            		}).then(res => {
            			// refetch()
            			setItemGroupSearch("")
            		})
            	}} />}
		</View>
	)
}