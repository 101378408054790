import { apolloClient } from "../ApolloClient"
import { parseDoorhubToken } from "./parse-doorhub-token"
import { BuildConfiguration } from "../platform-info/build-configuration"
import Device from "expo-device"
import { Platform } from "react-native"
import { BearerAuthentication } from "./authentication-types"
import { GoogleSigninDocument, GoogleSigninMutation, GoogleSigninMutationVariables } from "./queries.generated"

export async function signInToDoorhubWithGoogle(
	googleIdToken: string
): Promise<BearerAuthentication> {
	console.log("Authenticating with doorhub with Google", { googleIdToken })

	try {
		const { data, errors } = await apolloClient.mutate<GoogleSigninMutation, GoogleSigninMutationVariables>({
			mutation: GoogleSigninDocument,
			variables: {
				idToken: googleIdToken,
				deviceModel: Device ? Device.modelId + ":" + Device.modelName : "web",
				os: Platform.select({ android: "ANDROID", ios: "IOS", web: "WEB" }) as any,
				versionName: BuildConfiguration.VERSION_NAME,
				versionCode: BuildConfiguration.VERSION_CODE,
				applicationId: BuildConfiguration.APPLICATION_ID,
			},
		})

		if (errors && errors.length > 0) {
			console.warn({ errors })
			throw errors[0]
		}
		return parseDoorhubToken(data!.result!.token!)
	} catch (error) {
		if (error.code === "APPLICATION_VERSION_TOO_OLD") {
			throw new Error(
				"Your application version is too old. Update to the newest version with Google Play store."
			)
		}
		console.warn(`Signin failed for unexpected reason: ${error.message}`, { error })
		throw new Error(`Signin failed for unexpected reason: ${error.message}`)
	}
}

