import { ParagraphNode, RootNode, TextNode as LexicalTextNode } from "lexical"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { HeadingNode,} from "@lexical/rich-text"

export type TextComponent = {
    type: "text"
    text: string
    children: RichComponent[]
}

type BComponent = {
    type: "b"
    children: RichComponent[]
}

type UComponent = {
    type: "u"
    children: RichComponent[]
}

type IComponent = {
    type: "i"
    children: RichComponent[]
}

type PComponent = {
    type: "p"
    children: RichComponent[]
}

type H1Component = {
    type: "h1"
    children: RichComponent[]
}

type H2Component = {
    type: "h2"
    children: RichComponent[]
}

type RootComponent = {
    type: "root"
    children: RichComponent[]
}

type LinkComponent = {
    type: "a"
    text: string
    children: RichComponent[]
}

export type RichComponent = TextComponent | BComponent | UComponent | IComponent | PComponent | RootComponent | LinkComponent | H1Component | H2Component

type TextNode = {
    type: "text"
    text: string
    bold: boolean
    underline: boolean
    italic: boolean

}

type TextContainer = {
    type: "p" | "h1" | "h2"
    children: TextNode[]
}

export const richTextToLexical = (component: RichComponent): TextContainer[] => {
	const containers: TextContainer[] = []
	const stack = [{i: 0, component}]

	const last = () => {
		if (stack.length === 0) {
			throw new Error("stack is empty")
		}
		return stack[stack.length - 1]
	}

	let boldActivate = 0
	let underlineActivate = 0
	let italicActivate = 0

	let activateContainer: TextContainer = {
		type: "p",
		children: [],
	}

	while (stack.length > 0) {
		let item = last()

		const child = item.component.children[item.i++]

		if (child) {
			if (child.type === "p" || child.type === "h1" || child.type === "h2") {
				activateContainer = {
					type: child.type,
					children: [],
				}
			}

			if (child.type === "b") {
				boldActivate += 1
			}

			if (child.type === "u") {
				underlineActivate += 1
			}

			if (child.type === "i") {
				italicActivate += 1
			}

			if (child.type === "text") {
				const text: TextNode = {
					type: "text",
					text: child.text,
					bold: boldActivate > 0,
					underline: underlineActivate > 0,
					italic: italicActivate > 0,
				}

                activateContainer!.children.push(text)

                continue
			}

			stack.push({i: 0, component: child})
		} else {
			if (item.component.type === "p" || item.component.type === "h1" || item.component.type === "h2") {
				containers.push(activateContainer)
			}

			if (item.component.type === "b") {
				boldActivate -= 1
			}

			if (item.component.type === "u") {
				underlineActivate -= 1
			}

			if (item.component.type === "i") {
				italicActivate -= 1
			}

			stack.pop()
		}
	}

	return containers
}

const parseInner = (tokens: string[]): RichComponent[] => {
	const result: RichComponent[] = []
	let token = tokens.shift()
	let text = ""
	let parsingBold = false

	while (token) {
		if (token === "<b>") {
			parsingBold = true
			const children = parseInner(tokens)
			result.push({
				type: "b",
				children,
			})
		} else if (token === "</b>" && parsingBold) {
			return result
		} else if (token === "<u>") {
			const children = parseInner(tokens)
			result.push({
				type: "u",
				children,
			})
		} else if (token === "</u>") {
			return result
		}

		text += token + " "

		token = tokens.shift()
	}

	return result
}

export const parseRichHtml = (html: string): RichComponent => {
	let stack: RichComponent[] = [{
		type: "root",
		children: [],
	}]

	const last = () => {
		if (stack.length === 0) {
			throw new Error("stack is empty")
		}
		return stack[stack.length - 1]
	}

	const push = (type: RichComponent["type"]) => {
		const newc: any = {
			type,
			children: [],
		}
		last().children.push(newc)
		stack.push(newc)
	}
	let bb = ""
	let parsingKeyword = false

	for (const c of html) {
		if (c === ">") {
			bb = ""
			continue
		}

		if (c === "<") {
			if (bb) {
				last().children.push({
					type: "text",
					text: bb,
					children: [],
				})
				bb = ""
			}

			parsingKeyword = true
			continue
		}

		bb += c

		if (parsingKeyword) {
			if (c === "/") {
				if (stack.length === 1) {
					break
				} else {
					stack.pop()
				}
			} else if (bb === "h1" || bb === "h2" || bb === "p" || bb === "b" || bb === "u" || bb === "i" || bb === "a") {
				push(bb)
			} else {
				continue
			}

			parsingKeyword = false
			bb = ""
		}
	}

	if (bb) {
		last().children.push({
			type: "p",
			children: [
				{
					type: "text",
					text: bb,
					children: [],
				}
			]
		})
	}

	return stack.pop()!
}

const parseTextNode = (textNode: LexicalTextNode): string => {
	let finalText = ""
	if (textNode.hasFormat("bold")) {
		finalText += "<b>"
	}
	if (textNode.hasFormat("italic")) {
		finalText += "<i>"
	}
	if (textNode.hasFormat("underline")) {
		finalText += "<u>"
	}
	finalText += textNode.getTextContent()
	if (textNode.hasFormat("underline")) {
		finalText += "</u>"
	}
	if (textNode.hasFormat("italic")) {
		finalText += "</i>"
	}
	if (textNode.hasFormat("bold")) {
		finalText += "</b>"
	}
	return finalText
}

export const rootNodeToRichHtml = (root: RootNode): string => {
	let finalText = ""

	for (const child of root.getChildren()) {
		if (child instanceof ParagraphNode) {
			finalText += "<p>"
			for (const child2 of child.getChildren()) {
				if (child2 instanceof AutoLinkNode) {
					finalText += "<a>"

					for (const child3 of child2.getChildren()) {
						if (child3 instanceof LexicalTextNode) {
							finalText += parseTextNode(child3)
						}
					}

					finalText += "</a>"
				}
				if (child2 instanceof LexicalTextNode) {
					finalText += parseTextNode(child2)
				}
			}

			finalText += "</p>"
		}

		if (child instanceof HeadingNode) {
			if (child.getTag() === "h1") {
				finalText += "<h1>"
			}
			if (child.getTag() === "h2") {
				finalText += "<h2>"
			}

			for (const child2 of child.getChildren()) {
				if (child2 instanceof AutoLinkNode) {
					finalText += "<a>"

					for (const child3 of child2.getChildren()) {
						if (child3 instanceof LexicalTextNode) {
							finalText += parseTextNode(child3)
						}
					}

					finalText += "</a>"
				}
				if (child2 instanceof LexicalTextNode) {
					finalText += parseTextNode(child2)
				}
			}

			if (child.getTag() === "h1") {
				finalText += "</h1>"
			}
			if (child.getTag() === "h2") {
				finalText += "</h2>"
			}
		}
	}

	if (finalText === "<p></p>") {
		return ""
	}

	return finalText
}
