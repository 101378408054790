import * as Types from '../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RentingReturnCodeQueryVariables = Types.Exact<{
  rentingId: Types.Scalars['ID'];
}>;


export type RentingReturnCodeQuery = { __typename?: 'Query', renting?: { __typename?: 'Renting', id: string, returnCode?: string | null, returnCodeUsedAt?: any | null, useReturnCodeBefore?: any | null } | null };


export const RentingReturnCodeDocument = gql`
    query RentingReturnCode($rentingId: ID!) {
  renting(rentingId: $rentingId) {
    id
    returnCode
    returnCodeUsedAt
    useReturnCodeBefore
  }
}
    `;

/**
 * __useRentingReturnCodeQuery__
 *
 * To run a query within a React component, call `useRentingReturnCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentingReturnCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentingReturnCodeQuery({
 *   variables: {
 *      rentingId: // value for 'rentingId'
 *   },
 * });
 */
export function useRentingReturnCodeQuery(baseOptions: Apollo.QueryHookOptions<RentingReturnCodeQuery, RentingReturnCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RentingReturnCodeQuery, RentingReturnCodeQueryVariables>(RentingReturnCodeDocument, options);
      }
export function useRentingReturnCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RentingReturnCodeQuery, RentingReturnCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RentingReturnCodeQuery, RentingReturnCodeQueryVariables>(RentingReturnCodeDocument, options);
        }
export type RentingReturnCodeQueryHookResult = ReturnType<typeof useRentingReturnCodeQuery>;
export type RentingReturnCodeLazyQueryHookResult = ReturnType<typeof useRentingReturnCodeLazyQuery>;
export type RentingReturnCodeQueryResult = Apollo.QueryResult<RentingReturnCodeQuery, RentingReturnCodeQueryVariables>;