import React from "react"
import { useTranslation } from "react-i18next"
import Base from "../../components/layout/Base"
import { View, Image } from "react-native"
import { Text, useTheme } from "pusatec-react-native"
import { toString } from "lodash"

interface Props {
	item: {
		id: string
		name?: string | null
		rentingTime?: number | null
		imageUrl?: string | null
		locker?: any
	}
	available: boolean
	onPress(): void
}

export default function ItemLink(props: Props) {
	const { item, available, onPress } = props
	const { t } = useTranslation()

	console.info(item)

	const { colors } = useTheme()

	return (
		<Base
			key={item.id}
			onPress={onPress}
			style={{
				borderRightColor:
					item.locker == null
						? "#777"
						: available
							? "#9ddc9d"
							: colors.warning,
				borderRightWidth: 8
			}}
		>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<Text text={item.name?.toUpperCase()} bold />
					{item.locker && item.locker.name
						? <Text text={t("Locker {{name}}", { name: toString(item.locker?.name) })} />
						: null}
				</View>
				{item.imageUrl && (
					<Image
						style={{
							height: 80,
							width: 80,
							resizeMode: "contain",
						}}
						source={{
							uri: item.imageUrl,
						}}
					/>
				)}
			</View>
		</ Base>
	)
}
