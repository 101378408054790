import * as React from "react"
import { StackNavigationProp } from "@react-navigation/stack"
import { BackButton, MainStackParamList } from "../../MainNavigation"
import { AuthenticationContext } from "../../services/authentication/authentication-context"
import { useTranslation } from "react-i18next"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import { View, Platform, TouchableHighlight,Image } from "react-native"
import Logo from "../../components/Logo"
import VersionInfo from "../../components/VersionInfo"
import MyText from "../../components/MyText"
import Panel from "../../components/layout/Panel"
import { useActiveViewerQuery } from "../ActiveItems/queries.generated"
import { Button, ErrorMessage, TouchButton } from "pusatec-react-native"
import Icon from "react-native-vector-icons/MaterialCommunityIcons"
import { useChangeLanguageMutation } from "../Admin/queries.generated"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "Settings">
}

export default function SettingsScreen(props: Props) {
	const { navigation } = props
	const { authentication, signOut } = React.useContext(AuthenticationContext)
	const { t } = useTranslation()

	const { data, loading, error} = useActiveViewerQuery()
	const [changeLanguage] = useChangeLanguageMutation()

	const adminSystems = data?.viewer?.user?.adminSystems.adminSystems
	const isAdminSomewhere = adminSystems && adminSystems.length > 0

	return (
		<>
			<MainScrollPanel refreshing={loading}>
				<View style={{ flexDirection: "row",  flexWrap: "wrap", }}>
					<TouchableHighlight onPress={() => {
						changeLanguage({
							variables: {
								input: {
									userId: data?.viewer?.user?.id!,
									language: "fi"
								}
							}
						})
					}}>
						<View>
							<Image
                        		style={{
                        			width: 60,
                        			height: 30,
                        	}}
								resizeMode="contain"
                        		source={require("../../../assets/finland.png")}
							/>	
						</View>
					</TouchableHighlight>
					<TouchableHighlight onPress={() => {
						changeLanguage({
							variables: {
								input: {
									userId: data?.viewer?.user?.id!,
									language: "en"
								}
							}
						})
					}}>
						<View >
							<Image
								style={{
									width: 60,
									height: 30,
								}}
								resizeMode="contain"
								source={require("../../../assets/england.png")}
							/>
						</View>
					</TouchableHighlight>
				</View>		
				<View
					style={{
						justifyContent: "center",
						alignItems: "center",
						marginTop: 40,
					}}
				>
					<Logo />
					<VersionInfo />

					{error && <ErrorMessage extra={error} />}

					{data?.viewer?.user && (
						<>
							<MyText
								style={{ marginTop: 20, textAlign: "center" }}
								text={t("Signed in as {{user}}", {
									user:
										data.viewer?.user?.email || data.viewer?.user?.name
								})}
							/>
						</>
					)}
				</View>
			</MainScrollPanel>

			<Panel style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", alignContent: "flex-end" }}>
				{Platform.OS !== "android" && (
					<BackButton align="flex-end" />
				)}
				<View style={{ flexGrow: 1 }}>
					{isAdminSomewhere ? <TouchButton containerStyle={{ width: 260 }}
						text={t("Administrator mode")}
						onPress={() => {
							navigation.push("AdminNavigation", { systemId: adminSystems![0].id })
						}}
					/> : null}
					<TouchButton
						text={t("Join new lockersystem")}
						onPress={() => {
							navigation.push("JoinLockersystem")
						}}
					/>

					<TouchButton
						text={t("Report issue")}
						onPress={async () => {
							navigation.push("ReportIssue", {
								situation: "General",
							})
						}}
					/>
					<TouchButton
						text={t("Sign out")}
						type="primary"
						icon={({ color, size }) => <Icon name="logout" color={color} size={size} />}
						onPress={async () => {
							signOut()
						}}
					/>
				</View>
			</Panel>
		</>
	)
}
