import * as React from "react"
import { View, ViewStyle } from "react-native"

interface Props {
	children: any
	style?: ViewStyle
}

export default function Panel(props: Props) {
	let { style, children } = props
	if (!style) style = {}

	return <View style={{ padding: 10, backgroundColor: "#F8F8F8", ...style }}>{children}</View>
}
