import React from "react"
import { Image, View } from "react-native"
import { useDurationToDeadline } from "../../utils/Duration"
import Base from "../../components/layout/Base"
import { useTranslation } from "react-i18next"
import { ActiveViewerQuery } from "./queries.generated"
import { Text, useTheme } from "pusatec-react-native"
import { FormatRichHtml } from "../../components/FormatRichHtml"

interface Props {
	delivery: NonNullable<NonNullable<ActiveViewerQuery["viewer"]>["user"]>["activeDeliveries"]["deliveries"][0]
	onPress(): void
}

export default function DeliveryLink(props: Props) {
	const { delivery, onPress } = props
	const { t } = useTranslation()
	const { colors } = useTheme()
	
	const pickupTimeLeft = useDurationToDeadline(new Date(delivery.pickupBefore ?? 0))

	const timeState = !delivery.pickupBefore ? null : pickupTimeLeft.overtime ? (
		<Text
			bold
			style={{ color: colors.error }}
			text={t("Pickup time has run out")}
		/>
	) : (
		<Text
			text={t("{{time}} left to pickup", {
				time: pickupTimeLeft.duration.format(t),
			})}
		/>
	)

	return (
		<Base onPress={onPress} style={{ padding: 20 }}>
			<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
				<Image
					style={{
						width: 48,
						height: 48,
						alignSelf: "center",
						marginRight: 20,
					}}
					resizeMode="contain"
					source={require("../../../assets/icons/679902-ecommerce/svg/086-trolley.svg")}
				/>
				<View style={{ flex: 1, justifyContent: "space-between" }}>
					<Text text={t("Sent by {{user}}", { user: delivery.userWhoDelivered?.name }).toUpperCase()} bold />
					<Text text = {t("user email {{email}} ", { email: delivery?.userWhoDelivered?.gmail })} />
					{delivery.description ? <FormatRichHtml html={delivery.description} /> : null}
					{timeState}
				</View>
			</View>
		</Base>
	)
}