interface UseNotificationsInput {
	onNotification?(notification: any): void
	onNotificationOpened?(notification: any): void
}

interface UseNotificationsOutput {
	initialNotification: {
		loading: boolean
		error?: Error
		notification: null
	}
}

export function useNotifications({
	onNotification,
	onNotificationOpened,
}: UseNotificationsInput): UseNotificationsOutput {
	return {
		initialNotification: {
			loading: false,
			notification: null,
		},
	}
}
