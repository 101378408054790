import React from "react"
import { View } from "react-native"
import { AppearanceProvider } from "react-native-appearance"
import { MainNavigation } from "./MainNavigation"
import { AuthenticationProvider } from "./services/authentication/authentication-provider"
import { NotificationsProvider } from "./services/cloud-messaging/NotificationContextProvider"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "./services/ApolloClient"
import { ThemeProvider } from "pusatec-react-native"
import { initializeI18n } from "./i18n/i18n"
initializeI18n()

export default function App() {
	/*
	const [fontsReady, fontsError] = useFonts(fonts)
	useEffect(() => {
		if (fontsError) console.error('Font loading error: ', fontsError)
	}, [fontsError])
	*/

	return (
		<ThemeProvider theme={{
			colors: {
				primary: "#4B6587",
			},
			fonts: {
				normal: {
					fontSize: 16,
					fontFamily: "Nunito, Mada-Regular, sans-serif",
				}
			},
			components: {
				TouchButton: {
					defaultStyles: {
						container: {
							alignSelf: "flex-end"
						}
					},
					secondaryStyles: {
						container: {
							backgroundColor: "white",
						}
					}
				}
			}
		}}>
			<View
				style={{
					flex: 1,
					width: "100%",
					backgroundColor: "#d8e1eb",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<View
					style={{
						flex: 1,
						width: "100%",
						height: "100%",
						maxWidth: 600,
						maxHeight: 1000,
						shadowColor: "#00000011",
						shadowOffset: {
							height: 0,
							width: 0,
						},
						shadowRadius: 10,
						borderRadius: 5,
						overflow: "hidden",
						backgroundColor: "#FAFAFA",
					}}
				>
					<AppearanceProvider>
						<ApolloProvider client={apolloClient}>
							<AuthenticationProvider
								signedInContent={
									<NotificationsProvider>
										<MainNavigation />
									</NotificationsProvider>
								}
							/>
						</ApolloProvider>
					</AppearanceProvider>
				</View>
			</View>
		</ThemeProvider >
	)
}
