declare var BUILD_ENVIRONMENT: "staging" | "production" | undefined
declare var COMMIT_HASH: string | undefined

import { IBuildConfiguration } from "./types"
import packageJson from "../../../package.json"
import { devConfig } from "../../../dev-config"

let BuildConfiguration: IBuildConfiguration

if (__DEV__) {
	BuildConfiguration = {
		ENV: "development",
		VERSION_NAME: packageJson.version,
		VERSION_CODE: packageJson.versionCode,
		APPLICATION_ID: "monitoimikaappi-web",
		DOORHUB_HOST: "https://doorhub.dev.pusatec.fi",
		...devConfig,
	}
} else if (BUILD_ENVIRONMENT) {
	console.info("Found build configuration", BUILD_ENVIRONMENT)

	BuildConfiguration = {
		ENV: BUILD_ENVIRONMENT,
		VERSION_NAME: packageJson.version,
		VERSION_CODE: packageJson.versionCode,
		VERSION_HASH: COMMIT_HASH,
		APPLICATION_ID: "monitoimikaappi-web",
		DOORHUB_HOST: BUILD_ENVIRONMENT === "production"
			? "https://doorhub.pusatec.fi"
			: "https://doorhub.dev.pusatec.fi",
	}
} else {
	console.error("Invalid environment configuration")
	throw Error("Invalid environment configuration")
}

console.info("Using build configuration", BuildConfiguration)

export { BuildConfiguration }
