import React, { CSSProperties } from "react"

export const formatDate = (inputDate: Date | string) => {
	if (!inputDate) {
		return ""
	}

	if (typeof inputDate === "string") {
		inputDate = new Date(inputDate)
	}

	const time = inputDate.getTime()

	if (isNaN(time)) {
		return ""
	}

	const localISOString = new Date(time - (inputDate.getTimezoneOffset() * 60000)).toISOString()
	return localISOString.slice(0, 19)
}

export const LocalDatetime = (props: {
    style?: CSSProperties
    datetime: string
    min: Date
    onChange: (d: string) => void
}) => {
	return (
		<input type="datetime-local"
			style={props.style}
			required
			min={formatDate(props.min)}
			value={formatDate(props.datetime)}
			onChange={e => {
				const date = new Date(e.target.value)

				if (isNaN(date.getTime())) {
					props.onChange(props.datetime)

					return
				}

				props.onChange(date.toISOString())
			}}
		/>
	)
}