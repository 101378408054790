import { createContext } from "react"
import { FirebaseMessagingTypes } from "@react-native-firebase/messaging"

interface NotificationContextI {
	notification: FirebaseMessagingTypes.RemoteMessage | null
	clearNotification: () => void
}

export const NotificationContext = createContext<NotificationContextI>({
	notification: null,
	clearNotification: () => { },
})
