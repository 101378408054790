import * as React from "react"
import Base from "./layout/Base"
import { Image, StyleProp, ViewStyle } from "react-native"
import MyText from "./MyText"

interface Props {
	style?: StyleProp<ViewStyle>
}

export default function (props: Props) {
	const { style } = props

	return (
		<Base style={[{ padding: 20, alignItems: "center" }, style]}>
			<Image
				style={{
					width: 200,
					height: 30,
					alignSelf: "center",
					margin: 10,
					marginBottom: 0,
				}}
				resizeMode="contain"
				source={require("../../assets/pusatec-205x55.png")}
			/>
			<MyText
				text="locker.fi"
				style={{
					fontSize: 60,
					fontFamily: "Libre Franklin",
					fontWeight: "200"
				}}
			/>
			{/*
			<MyText
				style={{
					textAlign: 'center',
					fontSize: 28,
					marginTop: 0,
					marginBottom: 20,
				}}
				text="monitoimikaappi"
			/> */}
		</Base>
	)
}
