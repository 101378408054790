import React, { useRef } from "react"
import { WizardState } from "../use-wizard"
import WizardStep from "../WizardStep"
import { useTranslation } from "react-i18next"
import MainScrollPanel from "../../../components/layout/MainScrollPanel"
import Base from "../../../components/layout/Base"
import Panel from "../../../components/layout/Panel"
import { Form, TouchButton } from "pusatec-react-native"
import { BackIcon } from "../../../components/BackIcon"
import { DateTime } from "luxon"
import { Text } from "react-native"
import { RichTextEditor } from "../../../components/RichTextEditor"

export interface DeliveryInfo {
	description: string
	pickupBefore?: Date
}

interface Props {
	wizardState: WizardState
	initialValues?: DeliveryInfo
	onSubmit: (values: DeliveryInfo) => void
	onCancel?: () => void
}

export default function CreateDeliveryStep(props: Props) {
	const { initialValues, onSubmit, onCancel, wizardState } = props
	const { t } = useTranslation()
	const MAX_TEXT_LENGTH = 240

	console.info(
		initialValues?.pickupBefore
			? DateTime.fromJSDate(initialValues.pickupBefore).toFormat("yyyy-MM-dd'T'HH:mm")
			: null
	)

	return (
		<WizardStep
			title={t("")}
			wizardState={wizardState}
			scroll>
			<div style={{
				fontFamily: 'Nunito, Mada-Regular, sans-serif',
				fontSize: 25,
				textAlign: 'center'}}>
				{t("Delivery information")}
			</div>
					
			<Form<{ description: string, pickupBefore?: string }>
				onSubmit={(values) => {
					onSubmit({
						description: values.description,
						pickupBefore: values.pickupBefore ? new Date(values.pickupBefore) : undefined,
					})
				}}
				initialValues={{
					description: initialValues?.description,
					pickupBefore: initialValues?.pickupBefore
						? DateTime.fromJSDate(initialValues.pickupBefore).toFormat("yyyy-MM-dd'T'HH:mm")
						: null,
				}}
				style={{ flex: 1 }}
			>
				<MainScrollPanel>
					<Base style={{ paddingTop: 30 }}>
						<text>Description</text>
						<Form.CustomField name="description" required
							validate={value => value.length > MAX_TEXT_LENGTH}
							render={({ handleFieldValueChange, formContext, }) => {
								const value = formContext.fields.description?.value ?? formContext.initialValues?.description ?? false

								if (value.length > MAX_TEXT_LENGTH) {
									return (

										<div>
											<Text style={{ color: "red" }}>
												{t("Description is too long")}
											</Text>
											<RichTextEditor
												markdown={value}
												onChange={descriptionText => {
													handleFieldValueChange(descriptionText)
												}}
											/>

										</div>
									)
								}

								return (
									<div>
										<RichTextEditor
											markdown={value}
											onChange={descriptionText => {
												handleFieldValueChange(descriptionText)
											}}
										/>
									</div>
								)
							}} />
						<Form.DatetimeField
							label={t("Last pickup time")}
							name="pickupBefore"
							required={true}
							validate={value => {
								if (value && new Date(value) < new Date()) {
									return t("Must be in the future")
								}
							}}
						/>
					</Base>
				</MainScrollPanel>
				<Panel style={{ flexDirection: "row", justifyContent: "space-between" }}>
					{onCancel && (
						<TouchButton
							type="secondary"
							text={t("Go back")}
							icon={BackIcon}
							onPress={onCancel}
						/>
					)}
					<Form.Submit type="TouchButton" text={t("Next")} />
				</Panel>
			</Form>
		</WizardStep>
	)
}