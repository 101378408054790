import React, { useEffect } from "react"
import UserLink from "./UserLink"
import WizardStep from "../WizardStep"
import { WizardState } from "../use-wizard"
import { useTranslation } from "react-i18next"
import { CheckBox, SearchBar } from "react-native-elements"
import MainScrollPanel from "../../../components/layout/MainScrollPanel"
import Panel from "../../../components/layout/Panel"
import { useDebouncedState } from "pusatec-react-utility"
import { useSelectUserQuery } from "./queries.generated"
import { theme } from "../../../theme"
import { ErrorMessage, Text, TouchButton } from "pusatec-react-native"
import { BackIcon } from "../../../components/BackIcon"

export interface UserSelection {
	id?: string
	name?: string
	email?: string
}

interface Props {
	wizardState: WizardState
	systemId: string
	user?: UserSelection | null
	onChange(user: UserSelection | null): void
	onComplete(user: UserSelection): void
	onCancel(): void
	emailEnabled: boolean
}

export default function SelectUserStep(props: Props) {
	const { systemId, user, onComplete, onChange, onCancel, wizardState } = props

	const { t } = useTranslation()
	const [
		searchString,
		debouncedSearchString,
		setSearchString,
	] = useDebouncedState(user ? (user.email || user.name) : "", 300)

	const searchStringIncomplete = !searchString || searchString?.length < 3

	const { data, loading, error, refetch } = useSelectUserQuery({
		skip: searchStringIncomplete,
		variables: {
			systemId: systemId,
			searchWord: debouncedSearchString,
		},
	})
	const users = data?.users?.users?.filter((_user, i) => _user != null && i < 10)
	const userNotFound = searchString && users?.length === 0

	useEffect(() => {
		if (userNotFound === false && user && users && users.find(u => u?.id === user?.id) == null) {
			onChange(null)
		}
	}, [users, user?.id])
	let selected = !user
	const emailEnabled = props.emailEnabled
	const email = searchString ?? ""
	const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const isvalid  = re.test(email);
	const valid = isvalid ? "valid" : "invalid"
	const backgroundColor = isvalid ? "#99FF99" : "#FFCCCC"
	if (searchStringIncomplete) {
		selected = false
	}

	return (
		<WizardStep title={t("")} wizardState={wizardState}>
			<div style={{
				fontFamily: "Nunito, Mada-Regular, sans-serif",
				fontSize: 25,
				textAlign: "center"
			}}>
				{t("Select receiver")}
			</div>
			<SearchBar
				lightTheme
				inputStyle={theme.Input.inputStyle}
				errorStyle={theme.Input.errorMessageStyle}
				labelStyle={theme.Input.labelStyle}
				containerStyle={theme.Input.containerStyle}
				inputContainerStyle={theme.Input.inputContainerStyle}

				searchIcon={
					undefined /*
					<Image
						style={{ width: 24, height: 24 }}
						source={require('../../../../../assets/search-icon.png')}
					/> */
				}
				clearIcon={undefined}
				cancelIcon={undefined}
				onChangeText={setSearchString}
				value={searchString}
			/>
			<MainScrollPanel onRefresh={refetch} refreshing={loading}>
				{error && <ErrorMessage extra={error} />}
				{userNotFound && emailEnabled
					? <CheckBox containerStyle={{backgroundColor: backgroundColor }}
						title={t("User not found, Send to this email address {{email}} is {{valid}}", { email, valid })}
					 checked={!selected && isvalid} onPress={() => onChange(
							{
								email: searchString ?? undefined,
							}
						)}
						disabled={!isvalid}
					/>
					: null }
				{searchStringIncomplete
					? <>
						<Text text={t("Input receiver's name or email address")} style={{ textAlign: "center" }} />
					</>
					: users?.map((_user) => {
						if (_user?.gmail == null) return null

						return <UserLink
							key={_user!.id}
							user={_user}
							selected={user?.id === _user!.id}
							onPress={() => {
								onChange({
									id: _user!.id,
									email: _user!.gmail ?? undefined,
									name: _user!.name ?? undefined,
								})
							}}
						/>
					})}
			</MainScrollPanel>
			<Panel style={{ flexDirection: "row", justifyContent: "space-between" }}>
				<TouchButton
					type="secondary"
					text={t("Go back")}
					icon={BackIcon}
					onPress={onCancel}
				/>
				<TouchButton
					type="primary"
					disabled={!user}
					text={t("Next")}
					onPress={onComplete.bind(null, user!)}
				/>
			</Panel>
		</WizardStep>
	)
}

