import React from "react"
import { ActivityIndicator } from "react-native"
import { colors } from "../theme"

export default function Loading() {
	return (
		<ActivityIndicator
			style={{
				alignSelf: "center",
				margin: 20,
				padding: 4,
				backgroundColor: colors.light,
				borderRadius: 50,
				borderColor: "rgba(0,0,0,0.1)",
				borderWidth: 1,
			}}
			size="small"
			color={colors.accent}
		/>
	)
}
