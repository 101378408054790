import { useApolloClient } from "@apollo/client"
import { RouteProp } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import { ErrorMessage, Form, Text, TouchButton } from "pusatec-react-native"
import React from "react"
import { goBack, MainStackParamList } from "../../MainNavigation"
import Base from "../../components/layout/Base"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import Panel from "../../components/layout/Panel"
import Duration from "../../utils/Duration"
import { useTranslation } from "react-i18next"
import { useCreateItemMutation } from "./queries.generated"
import { View } from "react-native"
import { BackIcon } from "../../components/BackIcon"
import { ItemGroupSearch } from "../../components/ItemGroupSearch"
import { RichTextEditor } from "../../components/RichTextEditor"

interface Props {
    navigation: StackNavigationProp<MainStackParamList, "CreateItem">
    route: RouteProp<MainStackParamList, "CreateItem">
}

export function CreateItemScreen(props: Props) {
	const { navigation, route } = props
	const { params: { systemId } } = route
	const { t } = useTranslation()
	const apolloClient = useApolloClient()
	const [selectedItemGroupId, setSelectedItemGroupId] = React.useState<string>("")

	const [createItem, createItemM] = useCreateItemMutation({
		onCompleted: (data) => {
			navigation.replace("PlaceItemToLocker", {
				itemId: data.result.item!.id,
				itemWasJustCreated: true,

			})

			apolloClient.refetchQueries({
				include: ["AdminView"],
			})
		}
	})

	return (
		<Form
			onSubmit={values => {
				createItem({
					variables: {
						input: {
							lockersystemId: systemId,
							name: values.name,
							description: values.description,
							itemGroupId: selectedItemGroupId,
							rentingMaxTime: (values.rentingMaxTime / 1000),
						}
					}
				})
			}}
			style={{ flex: 1 }}
		>
			<MainScrollPanel>
				<Base>
					<Text type="h2" text={t("Create new item")} style={{ marginBottom: 20 }} />
					<Form.TextField autoFocus name="name" label={t("Name")} required />

					<Form.DurationField
						name="rentingMaxTime"
						label={t("Renting time")}
						required
						validate={value => {
							const duration = new Duration(value ?? 0)
							if (duration.getAsUnit("minutes") < 5)
								return t("Renting time is too short")
						}}
					/>
					<Text text={t("Description")} style={{ marginTop: 20 }} />
					<Form.CustomField name="description" render={({ handleFieldValueChange, formContext }) => {
						const value = formContext.fields.description?.value ?? formContext.initialValues?.description ?? false

						return (
							<RichTextEditor
								markdown={value}
								onChange={ descriptionText => {
									handleFieldValueChange(descriptionText)
								}}
							/>
						)
					}} />
					<Text text={t("Item groups")} style={{ marginTop: 20 }} />
					<ItemGroupSearch
						lockersystemId={systemId}
						selectedItemGroupId={selectedItemGroupId}
						onItemGroupidChanged={id => {
							if (id === selectedItemGroupId) {
								setSelectedItemGroupId("")

								return
							}

							setSelectedItemGroupId(id)
						}}
					/>
				</Base>
			</MainScrollPanel>
			<Panel>
				{createItemM.error && <ErrorMessage extra={createItemM.error} />}
				<View style={{ flexDirection: "row", justifyContent: "space-between", }}>
					<TouchButton
						text={t("Cancel")}
						icon={BackIcon}
						onPress={() => {
							goBack(navigation)
						}}
					/>
					<Form.Submit
						type="TouchButton"
						text={t("Create item")}
						loading={createItemM.loading}
					/>
				</View>
			</Panel>
		</Form>
	)
}
