import * as Types from '../../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveItemFromLockerMutationVariables = Types.Exact<{
  itemId: Types.Scalars['ID'];
}>;


export type RemoveItemFromLockerMutation = { __typename?: 'Mutation', result: { __typename?: 'RemoveItemFromLockerResponse', item: { __typename?: 'Item', id: string, available?: boolean | null } } };

export type SelectItemQueryVariables = Types.Exact<{
  groupId: Types.Scalars['ID'];
}>;


export type SelectItemQuery = { __typename?: 'Query', items: { __typename?: 'LockerGroupItemsConnection', items: Array<{ __typename?: 'Item', id: string, name?: string | null, available?: boolean | null, locker?: { __typename?: 'Locker', id: string, name: number } | null }> } };


export const RemoveItemFromLockerDocument = gql`
    mutation RemoveItemFromLocker($itemId: ID!) {
  result: removeItemFromLocker(input: {itemId: $itemId}) {
    item {
      id
      available
    }
  }
}
    `;
export type RemoveItemFromLockerMutationFn = Apollo.MutationFunction<RemoveItemFromLockerMutation, RemoveItemFromLockerMutationVariables>;

/**
 * __useRemoveItemFromLockerMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromLockerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromLockerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromLockerMutation, { data, loading, error }] = useRemoveItemFromLockerMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRemoveItemFromLockerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveItemFromLockerMutation, RemoveItemFromLockerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveItemFromLockerMutation, RemoveItemFromLockerMutationVariables>(RemoveItemFromLockerDocument, options);
      }
export type RemoveItemFromLockerMutationHookResult = ReturnType<typeof useRemoveItemFromLockerMutation>;
export type RemoveItemFromLockerMutationResult = Apollo.MutationResult<RemoveItemFromLockerMutation>;
export type RemoveItemFromLockerMutationOptions = Apollo.BaseMutationOptions<RemoveItemFromLockerMutation, RemoveItemFromLockerMutationVariables>;
export const SelectItemDocument = gql`
    query SelectItem($groupId: ID!) {
  items: lockerGroupItems(lockerGroupId: $groupId) {
    items {
      id
      name
      available
      locker {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSelectItemQuery__
 *
 * To run a query within a React component, call `useSelectItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectItemQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSelectItemQuery(baseOptions: Apollo.QueryHookOptions<SelectItemQuery, SelectItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectItemQuery, SelectItemQueryVariables>(SelectItemDocument, options);
      }
export function useSelectItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectItemQuery, SelectItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectItemQuery, SelectItemQueryVariables>(SelectItemDocument, options);
        }
export type SelectItemQueryHookResult = ReturnType<typeof useSelectItemQuery>;
export type SelectItemLazyQueryHookResult = ReturnType<typeof useSelectItemLazyQuery>;
export type SelectItemQueryResult = Apollo.QueryResult<SelectItemQuery, SelectItemQueryVariables>;