import { RouteProp } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import { ErrorMessage, Form, Text, TouchButton } from "pusatec-react-native"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { CheckBox } from "react-native-elements"
import { BackIcon } from "../../components/BackIcon"
import { ItemGroupSearch } from "../../components/ItemGroupSearch"
import Base from "../../components/layout/Base"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import Panel from "../../components/layout/Panel"
import Loading from "../../components/Loading"
import { goBack, MainStackParamList } from "../../MainNavigation"
import Duration from "../../utils/Duration"
import { useEditItemScreenQuery, useUpdateItemMutation } from "./queries.generated"
import { RichTextEditor } from "../../components/RichTextEditor"

interface Props {
    navigation: StackNavigationProp<MainStackParamList, "EditItem">
    route: RouteProp<MainStackParamList, "EditItem">
}

export const EditItemScreen = (props: Props) => {
	const { t } = useTranslation()
	const { data } = useEditItemScreenQuery({
		variables: {
			itemId: props.route.params.itemId
		}
	})

	const [updateItem, { error, loading }] = useUpdateItemMutation()

	const item = useMemo(() => {
		if (!data?.item) {
			return null
		}

		const rentingTime = data?.item?.rentingTime || 0

		return {
			...data.item,
			rentingTime: rentingTime * 1000
		}
	}, [data?.item])

	if (!item) {
		return <Loading />
	}

	return (
		<Form style={{ flex: 1 }} initialValues={item}
			onSubmit={values => {
				updateItem({
					variables: {
						input: {
							itemId: item.id,
							name: values.name,
							description: values.description,
							itemGroupId: values.itemGroupId ? values.itemGroupId : null,
							rentingTime: values.rentingTime / 1000,
							rentable: values.rentable,
							needsPermit: values.needsPermit,
						}
					}
				}).then(res => {
					goBack(props.navigation)
				})
			}}>
			<MainScrollPanel>
				<Base>
					<Text type="h2" text={t("Edit item")} style={{ marginBottom: 20 }} />
					<Form.TextField name="name" label={t("Name")} required  />
					<Text text={t("Description")} />
					<Form.CustomField name="description"  render={({ handleFieldValueChange, formContext }) => {
						const value = formContext.fields.description?.value ?? formContext.initialValues?.description ?? false

						return (
							<RichTextEditor
								markdown={value}
								onChange={ descriptionText => {
									handleFieldValueChange(descriptionText)
								}}
							/>
						)
					}} />

					<Form.DurationField
						name="rentingTime"
						label={t("Renting time")}
						required
						validate={value => {
							const duration = new Duration(value ?? 0)
							if (duration.getAsUnit("minutes") < 5)
								return t("Renting time is too short")
						}}
					/>
					<Form.CustomField name="rentable" render={({ handleFieldValueChange, formContext }) => {
						const value = formContext.fields.rentable?.value ?? formContext.initialValues?.rentable ?? false

						return (
							<CheckBox
								checked={value}
								title={t("Rentable")}
								onPress={() => handleFieldValueChange(!value)} />
						)
					}} />

					<Form.CustomField name="needsPermit" render={({ handleFieldValueChange, formContext }) => {
						const value = formContext.fields.needsPermit?.value ?? formContext.initialValues?.needsPermit ?? false

						return (
							<CheckBox
								checked={value}
								title={t("Needs permit")}
								onPress={() => handleFieldValueChange(!value)} />
						)
					}} />

					<Text text={t("Itemgroup")} />
					<Form.CustomField name="itemGroupId" render={({ formContext, handleFieldValueChange }) => {
						const itemGroupId = formContext.fields.itemGroupId?.value ?? formContext.initialValues?.itemGroupId

						return (
							<ItemGroupSearch
								lockersystemId={item.locker?.lockersystemId!}
								selectedItemGroupId={itemGroupId}
								onItemGroupidChanged={id => {
									if (id === itemGroupId) {
										handleFieldValueChange("")

										return
									}

									handleFieldValueChange(id)
								}}
							/>
						)
					}} />

				</Base>
			</MainScrollPanel>
			<Panel>
				{error && <ErrorMessage extra={error} />}
				<View style={{ flexDirection: "row", justifyContent: "space-between", }}>
					<TouchButton
						text={t("Cancel")}
						icon={BackIcon}
						onPress={() => {
							goBack(props.navigation)
						}}
					/>
					<Form.Submit
						type="TouchButton"
						text={t("Update item")}
						loading={loading}
					/>
				</View>
			</Panel>
		</Form>
	)
}