import React from "react"
import { StackNavigationProp } from "@react-navigation/stack"
import { goBack, goToRoot, MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/native"
import Loading from "../../components/Loading"
import LockerAction from "../../components/LockerAction"
import { useRentingReturnCodeQuery } from "./queries.generated"
import { ErrorMessage } from "pusatec-react-native"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "ReturnRenting">
	route: RouteProp<MainStackParamList, "ReturnRenting">
}

export default function ReturnRentingScreen(props: Props) {
	const { route, navigation } = props
	const { rentingId } = route.params

	const { loading, error, data } = useRentingReturnCodeQuery({
		variables: { rentingId },
		pollInterval: 2000,
	})
	const renting = data?.renting

	if (loading) return <Loading />
	if (error || !renting) return <ErrorMessage extra={error} />

	return (
		<LockerAction
			code={renting.returnCode!}
			useCodeBefore={renting.useReturnCodeBefore ?? null}
			codeUsedAt={renting.returnCodeUsedAt ?? null}
			onReportIssue={() => {
				navigation.push("ReportIssue", {
					situation: "RentingReturn",
					rentingId,
				})
			}}
			onComplete={() => goToRoot(navigation)}
			onCancel={() => goBack(navigation)}
		/>
	)
}
