import * as Types from '../../../doorhub-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDeliveryMutationVariables = Types.Exact<{
  input: Types.DeliverItemToUser;
}>;


export type CreateDeliveryMutation = { __typename?: 'Mutation', result: { __typename?: 'DeliverItemToUserResponse', code: string, delivery: { __typename?: 'Delivery', id: string, description?: string | null, pickupBefore?: any | null }, locker: { __typename?: 'Locker', delivery?: { __typename?: 'Delivery', id: string } | null } } };

export type DeliveryCodeQueryVariables = Types.Exact<{
  deliveryId: Types.Scalars['ID'];
}>;


export type DeliveryCodeQuery = { __typename?: 'Query', delivery?: { __typename?: 'Delivery', id: string, deliveryCode?: string | null, deliveryCodeUsedAt?: any | null, useDeliveryCodeBefore?: any | null } | null };


export const CreateDeliveryDocument = gql`
    mutation CreateDelivery($input: DeliverItemToUser!) {
  result: deliverItemToUser(input: $input) {
    code
    delivery {
      id
      description
      pickupBefore
    }
    locker {
      delivery {
        id
      }
    }
  }
}
    `;
export type CreateDeliveryMutationFn = Apollo.MutationFunction<CreateDeliveryMutation, CreateDeliveryMutationVariables>;

/**
 * __useCreateDeliveryMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryMutation, { data, loading, error }] = useCreateDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeliveryMutation, CreateDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeliveryMutation, CreateDeliveryMutationVariables>(CreateDeliveryDocument, options);
      }
export type CreateDeliveryMutationHookResult = ReturnType<typeof useCreateDeliveryMutation>;
export type CreateDeliveryMutationResult = Apollo.MutationResult<CreateDeliveryMutation>;
export type CreateDeliveryMutationOptions = Apollo.BaseMutationOptions<CreateDeliveryMutation, CreateDeliveryMutationVariables>;
export const DeliveryCodeDocument = gql`
    query DeliveryCode($deliveryId: ID!) {
  delivery(deliveryId: $deliveryId) {
    id
    deliveryCode
    deliveryCodeUsedAt
    useDeliveryCodeBefore
  }
}
    `;

/**
 * __useDeliveryCodeQuery__
 *
 * To run a query within a React component, call `useDeliveryCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryCodeQuery({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useDeliveryCodeQuery(baseOptions: Apollo.QueryHookOptions<DeliveryCodeQuery, DeliveryCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryCodeQuery, DeliveryCodeQueryVariables>(DeliveryCodeDocument, options);
      }
export function useDeliveryCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryCodeQuery, DeliveryCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryCodeQuery, DeliveryCodeQueryVariables>(DeliveryCodeDocument, options);
        }
export type DeliveryCodeQueryHookResult = ReturnType<typeof useDeliveryCodeQuery>;
export type DeliveryCodeLazyQueryHookResult = ReturnType<typeof useDeliveryCodeLazyQuery>;
export type DeliveryCodeQueryResult = Apollo.QueryResult<DeliveryCodeQuery, DeliveryCodeQueryVariables>;