import { ErrorMessage, Notification, TouchButton, TouchButtonRow, useTheme } from "pusatec-react-native"
import React from "react"
import { useTranslation } from "react-i18next"
import { View, Modal } from "react-native"
import { BackIcon } from "../../components/BackIcon"
import { AdminViewDocument, useDeleteItemMutation } from "./queries.generated"

interface Props {
    systemId: string
    item: {
        id: string
        name?: string | null
    }
    onExit: () => void
}

export function DeleteItemConfirmation(props: Props) {
	const { item, systemId, onExit } = props
	const { colors } = useTheme()
	const { t } = useTranslation()

	const [deleteItem, deleteItemM] = useDeleteItemMutation({
		refetchQueries: [{
			query: AdminViewDocument,
			variables: { systemId: systemId, }
		}]
	})

	return (
		<Modal transparent>
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.5)" }}>
				<View style={{ alignItems: "center", justifyContent: "center", borderRadius: 5, margin: 20, padding: 20, backgroundColor: "white", }}>
					<Notification
						type="warning"
						title={t("Delete item {{name}}?", { name: item.name })}
						text={t("delete_item_confirmation_text")}
					/>

					{deleteItemM.error && <ErrorMessage extra={deleteItemM.error} />}

					<TouchButtonRow>
						<TouchButton
							text={t("Cancel")}
							icon={BackIcon}
							onPress={onExit}
						/>

						<TouchButton
							text={t("Delete item")}
							loading={deleteItemM.loading}
							containerStyle={{ backgroundColor: colors.warning }}
							onPress={async () => {
								await deleteItem({
									variables: {
										itemId: item.id,
									}
								})
								onExit()
							}} />
					</TouchButtonRow>
				</View>
			</View>
		</Modal>
	)
}
