import React from "react"
import { View } from "react-native"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import { MainStackParamList, goBack } from "../../MainNavigation"
import { StackNavigationProp } from "@react-navigation/stack"
import { RouteProp, useNavigation } from "@react-navigation/core"
import { useGetLockerQuery } from "./queries.generated"
import { Form, Text, TouchButton } from "pusatec-react-native"
import Base from "../../components/layout/Base"
import { useTranslation } from "react-i18next"
import Panel from "../../components/layout/Panel"
import Loading from "../../components/Loading"
import { transform } from "lodash"
import { FormatRichHtml } from "../../components/FormatRichHtml"
import { parseRichHtml } from "../../utils/richtext"

interface Props {
    navigation: StackNavigationProp<MainStackParamList, "ViewLocker">
    route: RouteProp<MainStackParamList, "ViewLocker">
}

const Content = (props: {
    lockerId: string
}) => {
	const { data, loading } = useGetLockerQuery({
		variables: {
			lockerId: props.lockerId
		}
	})

	const { t } = useTranslation()
	const item = data?.locker?.item
	const delivery = data?.locker?.delivery

	if (loading) {
		return (
			<Loading />
		)
	}

	if (item) {
		return (
			<View>
				<View style={{
					marginBottom: 10
				}}>
					<View style={{ marginBottom: "10px" }}>
						<Text text={t("Item")} type="strong" />
						<Text text={`${item?.name}`} />
					</View>
					{item?.description &&
                    <View>
                    	<Text text={t("Description")} type="strong" />
                    	<FormatRichHtml html={item?.description} />
                    </View>}
				</View>
				<View>
					{item.activeRenting &&
                    <View>
                    	<Text text={t("Current renting")} type="strong" />
                    	<Text text={item.activeRenting.user?.name} />
                    	{item.activeRenting?.returnItemBefore
                    		? <Text text={t("Return before {{deadline}}", { deadline: new Date(item.activeRenting?.returnItemBefore).toLocaleString() })} />
                    		: null}
                    </View>}
				</View>
			</View>
		)
	}

	if (delivery) {
		return (
			<View>
				<View style={{ marginBottom: "10px" }}>
					<Text text={t("Delivery")} type="strong" />
					<FormatRichHtml html={delivery?.description} max={200} />
				</View>
				{delivery.pickupBefore &&
                    <Text text={t("Pickup before {{pickupBefore}}", {
                    	pickupBefore: new Date(delivery.pickupBefore).toLocaleString()
                    })} />}
			</View>
		)
	}

	return (
		<View>
			<Text text="There is nothing in that locker." />
		</View>
	)
}

export const ViewLockerScreen = (props: Props) => {
	const { lockerId } = props.route.params

	const { t } = useTranslation()

	const navigation = useNavigation<StackNavigationProp<MainStackParamList, "AdminNavigation">>()

	return (
		<>
			<MainScrollPanel>
				<View>
					<Base>
						<Content
							lockerId={lockerId}
						/>
					</Base>
				</View>
			</MainScrollPanel>
			<Panel>
				<View style={{ justifyContent: "flex-start" }}>
					<TouchButton
						text={t("Go back")}
						onPress={() => {
							goBack(navigation)
						}}
					/>
				</View>
			</Panel>
		</>
	)
}