import React from "react"
import { StackNavigationProp } from "@react-navigation/stack"
import { BackButton, goToRoot, MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/native"
import { FeedbackType } from "../../doorhub-types"
import Panel from "../../components/layout/Panel"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import Base from "../../components/layout/Base"
import { useTranslation } from "react-i18next"
import { useReportIssueMutation } from "./queries.generated"
import { useActiveViewerQuery } from "../ActiveItems/queries.generated"
import Loading from "../../components/Loading"
import { ErrorMessage, Form, Notification, Text, TouchButton } from "pusatec-react-native"

export type IssueType = FeedbackType
export type IssueSituation =
	| "RentingStart"
	| "RentingDuring"
	| "RentingReturn"
	| "DeliveryPickup"
	| "DeliveryDeliver"
	| "General"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "ReportIssue">
	route: RouteProp<MainStackParamList, "ReportIssue">
}

export interface FormValues {
	type: IssueType | null
	lockersystemId: string | null
	message: string
}

export default function ReportIssueScreen(props: Props) {
	const { route, navigation } = props
	const { situation, deliveryId, lockerGroupId, lockerId, lockersystemId, rentingId, } = route.params
	const { t } = useTranslation()

	const activeViewerQ = useActiveViewerQuery()
	const user = activeViewerQ.data?.viewer?.user
	const lockersystems = user?.lockersystems.lockersystems

	const [reportIssue, reportIssueM] = useReportIssueMutation()

	const issueTypes = Object.keys(FeedbackType).filter((key) =>
		key.startsWith(situation)
	) as IssueType[]

	if (reportIssueM.error) return <ErrorMessage extra={reportIssueM.error} />

	if (activeViewerQ.loading) return <Loading />
	if (activeViewerQ.error) return <ErrorMessage extra={activeViewerQ.error} />

	return (
		<>
			<MainScrollPanel>
				<Base>
					<Text text={t("Report issue")} type="h3" style={{ marginBottom: 20 }} />
					{reportIssueM.data?.result
						? <>
							<Notification
								type="info"
								title={t("Issue has been reported")}
								text={t("The lockersystem admin will contact you if necessary")}
							/>
						</>
						: <Form
							onSubmit={(values) => {
								reportIssue({
									variables: {
										feedback: {
											eventTime: new Date().toISOString(),
											deliveryId: deliveryId,
											lockerGroupId: lockerGroupId,
											lockerId: lockerId,
											rentingId: rentingId,
											lockersystemId: values.lockersystemId ?? lockersystemId,
											message: values.message,
											type: values.issueType,
											userId: user!.id,
										},
									},
								})
							}}>

							{(!deliveryId && !rentingId)
								? <Form.SelectField name="lockersystemId" label={t("Lockersystem")} required
									options={lockersystems?.map(system => ({
										label: system.name || "",
										value: system.id,
									})) || []} />
								: null}

							<Form.SelectField name="issueType" label={t("Issue type")} required
								options={issueTypes.map(type => ({
									label: t(type),
									value: type,
								}))} />

							<Form.TextField
								name="message"
								label={t("More information about the issue")}
								multiline
							/>

							<Form.Submit
								text={t("Send")}
								type="TouchButton"
								loading={reportIssueM.loading}
								style={{ alignSelf: "flex-start", minWidth: 160 }}
							/>
						</Form>}

				</Base>
			</MainScrollPanel>
			<Panel>
				{reportIssueM.data && reportIssueM.data.result ? (
					<TouchButton
						type="primary"
						text={t("Return to the main screen")}
						onPress={() => goToRoot(navigation)}
					/>
				) : (
					<BackButton />
				)}
			</Panel>
		</>
	)
}
