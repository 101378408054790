import React, { useState, useContext } from "react"
import { API_URL } from "../../services/ApolloClient"
import { BuildConfiguration } from "../../services/platform-info/build-configuration"
import { AuthenticationContext } from "../../services/authentication/authentication-context"
import { useActiveViewerQuery } from "../../screens/ActiveItems/queries.generated"
import { ErrorMessage, TouchButton } from "pusatec-react-native"

interface Props {
	code: string
	onConfirm(): void
}

export default function DebugConfirming(props: Props) {
	const { code, onConfirm } = props

	const [error, setError] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)

	const { refetch } = useActiveViewerQuery()

	const { authentication } = useContext(AuthenticationContext)

	if (
		BuildConfiguration.ENV === "development" ||
		BuildConfiguration.ENV === "staging"
	) {
		return (
			<>
				{error && <ErrorMessage message="" extra={error} />}
				<TouchButton
					type="secondary"
					text="Confirm usage (debug)"
					loading={loading}
					onPress={async () => {
						if (loading) return

						setLoading(true)
						try {
							let response = await fetch(API_URL + "/api/v1/actioncode/" + code, {
								mode: "cors",
								headers: authentication.type == "bearer" ? {
									authorization: `Bearer ${authentication.token}`,
								} : {},
								credentials: "include",
							})
							if (!response.ok) throw Error("Invalid response")

							response = await fetch(
								API_URL + "/api/v1/actioncode/" + code + "/ack",
								{
									method: "POST",
									headers: authentication.type == "bearer" ? {
										authorization: `Bearer ${authentication.token}`,
									} : {},
									credentials: "include",
								}
							)
							const json = await response.json()
							if (!response.ok) {
								throw Error(JSON.stringify(json))
							}

							refetch()
							setError(null)
							setLoading(false)
							onConfirm()
						} catch (error) {
							console.log("error", error)
							setError(error)
							setLoading(false)
						}
					}}
				/>
			</>
		)
	}
	return null
}
