export const en = {
	// Common non domain
	Cancel: "Cancel",
	Next: "Next",
	"Go back": "Go back",
	Send: "Send",
	Select: "Select",
	Edit: "Edit",
	Set: "Set",
	Settings: "Settings",
	"Not set": "Not set",
	Name: "Name",
	Description: "Description",
	"Network Error": "Network Error",
	"Return to the main screen": "Return to the main screen",
	"An error has occured": "An error has occured",
	"You have been signed out": "You have been signed out",

	days: "days",
	hours: "hours",
	minutes: "minutes",
	seconds: "seconds",

	// Common terms
	Delivery: "Delivery",
	Renting: "Borrowing",
	Locker: "Locker",
	"Pickup at": "Pickup at",
	"Return to": "Return to",
	"Sent on {{time}}": "Sent on {{time}}",
	"In locker {{locker}}": "In locker {{locker}}",
	"Renting time": "Max borrow duration",
	"Sent by {{sender}}": "Sent by {{sender}}",
	"Pickup now": "Pickup now",
	"Rent now": "Borrow now",
	"Return now": "Return now",
	"Select locker": "Select locker",
	"Last pickup time": "Last pickup time",
	"Set duration": "Set duration",

	// Info
	"Welcome!": "Welcome!",
	"Not in any system info":
		"Your account has not been added to any lockersystem. Join a lockersystem by inputting the registration code displayed near the locker to the field below.",
	"Signin screen info text": "Welcome to Pusatec's locker.fi smart lockersystem service, that gives you an option to borrow, deliver, or store items by using your mobile phones browser.",

	// Active items screen
	Rentings: "Borrowings",
	"Incoming deliveries": "Incoming deliveries",
	"No rentings or deliveries": "No borrowings or deliveries",
	"New delivery": "Deliver item",
	"Rent new item": "Borrow item",

	"{{time}} left to pickup": "{{time}} left to pickup",
	"Pickup time has run out":
		"Pickup time has run out",
	"Out of time": "Out of time",
	"pick_time_run_out_warning": "Pickup time for this delivery has run out. To resolve the situation, you can contact the lockersystem administrator.",
	"{{time}} renting time left": "{{time}} borrowing time left",
	"{{time}} overtime! Return as soon as possible!":
		"{{time}} overtime! Return as soon as possible.",
	"WaitsPickup": "Waiting for pickup",
	"Active": "Active rentings",
	"NeedsPermit": "Waiting for permission",
	"inuse": "In use",
	"others": "Others",

	// Settings
	"Add rentable item": "Add borrowable item",
	"Remove rentable item": "Remove borrowable item",
	"Signed in as {{user}}": "Signed in as {{user}}",
	"Sign out": "Sign out",
	"Sign in": "Sign in",

	// View Item
	"Item is not available": "Item is not available",
	"Item is currently borrowed {{releaseTime}}": "Item is currently borrowed. Should be free at {{releaseTime}} at the latest.",
	"Rentable item at": "Borrowable item at",

	// Other
	"Rentable items": "Borrowable items",
	"Select environment": "Select environment",

	// Wizards
	"Delivery information": "Delivery information",
	"Place delivery into locker": "Place delivery into locker",
	"Place item to locker": "Place item to locker",
	"Select receiver": "Select receiver",
	"Rentable item details": "Borrowable item details",
	"Remove item from locker": "Remove item from locker",
	"Select item to be removed": "Select item to be removed",
	"item_was_created_and_can_be_placed_to_locker": "Item was created. You can now place it to a locker.",
	"Give renting permission": "Anna lainausoikeus",
	// Validation errors
	"Must be in the future": "Must be in the future",
	"The item must have a name": "The item must have a name",
	"Renting time is too short": "Max borrow duration is too short",

	// Issues
	"Report issue": "Report issue",
	"Issue report sent": "Issue report sent",
	"Issue type": "Issue type",
	"More information about the issue": "More information about the issue",

	// Issue types:
	DeliveryPickupItemMissingFromLocker: "Item is missing from the locker",
	DeliveryPickupDoorDidNotOpen: "The door didn't open",
	DeliveryPickupDoorAcidentClosed:
		"Door accidentally closed before taking the item",
	DeliveryPickupWrongItem: "Item is a wrong one",
	DeliveryPickupFaltyItem: "Item is faulty",
	DeliveryPickupOtherError: "Other issue",

	DeliveryDeliverDoorDidNotOpen: "The door didn't open",
	DeliveryDeliverDoorAcidentClosed:
		"Door accidentally closed before taking the item",
	DeliveryDeliverOtherError: "Other issue",

	RentingStartItemMissingFromLocker: "Item is missing from the locker",
	RentingStartDoorDidNotOpen: "The door didn't open",
	RentingStartDoorAcidentClosed:
		"Door accidentally closed before taking the item",
	RentingStartWrongItem: "Item is a wrong one",
	RentingStartFaltyItem: "Item is faulty",
	RentingStartOtherError: "Other issue",

	RentingDuringItemLost: "Item was lost",
	RentingDuringItemBroke: "Item broke",
	RentingDuringItemNeedService: "Item needs maintanance",
	RentingDuringWrongItem: "Item is a wrong one",
	RentingDuringFaltyItem: "Item is faulty",
	RentingDuringOtherError: "Other issue",

	RentingReturnDoorDidNotOpen: "The door didn't open",
	RentingReturnDoorAcidentClosed:
		"Door accidentally closed before taking the item",
	RentingReturnOtherError: "Other issue",

	GeneralOtherError: "Other issue",

	// Code input
	"Input code into the lockers terminal":
		"Input code into the lockers terminal",
	"Code has been used": "Code has been used",
	"Remember to close the locker door": "Remember to close the locker door",
	"The code has expired": "The code has expired",

	// Admin view
	"delete_item_confirmation_text": "Are you sure you want to delete this item? This operation is permanent and the item will be lost forever.",
	"delete_item_group_confirmation_text": "Are you sure you want to delete this item group? This operation is permanent and the item group will be lost forever.",
	
	// Translation keys
	"fi": "Finnish",
	"en": "English",
	"allow_renting": "Allow borrowing",
	"allow_renting_subject": "Allow borrowing email subject",
	"renting_permission_request": "Renting request",
	"renting_permission_request_subject": "Lainaus pyyntö sähköposti kuvaus",
	"rented_item_returned": "Borrowed item returned",
	"rented_item_returned_subject": "Borrowed item returned email subject",
	"delivery": "Delivery",
	"delivery_subject": "Delivery email subject",
	"delivery_interrupted": "Delivery interrupted",
	"delivery_interrupted_subject": "Delivery interrupted email subject",
	"delivery_picked_up": "Delivery picked up",
	"delivery_picked_up_subject": "Delivery picked up email subject",

	// Translation variables
	"user_name": "User name",
	"item_name": "Item name",
	"locker_number": "Locker number",
	"lockersystem_name": "Lockersystem name",
	"locker_group_name": "Locker group name",
	"delivery_description": "Delivery description",
	"pickup_before": "Pickup before",
	"pickup_code": "Pickup code",
	"user_delivered_name": "User delivered name",
	"user_delivered_email": "User delivered email"
}
